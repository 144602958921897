import { FC } from "react";
import Text from "_shared/components/Text";
import { Nullable } from "_shared/utils";
import { DiscreteRangeInput, Field, Label } from "_shared/components/Field";
import {
  WARWICK_EDINBURGH_STATEMENTS,
  WARWICK_EDINBURGH_STEPS,
  IPatientEvent,
} from "_shared/models/Patient";

interface WarwickEdinburghQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const WarwickEdinburghQuestionnaire: FC<
  WarwickEdinburghQuestionnaireProps
> = ({ event, onChange }) => {
  const { warwickEdinburgh } = event;

  return (
    <>
      <hr />
      <Text variant="h2" id="warwick-edinburgh">
        Warwick-Edinburgh Mental Wellbeing
      </Text>
      <Field wide>
        <p>
          Below are some statements about feelings and thoughts. Please describe
          your experience of each over the last 2 weeks.
        </p>
      </Field>
      <Field wide>
        <Label strong>I've...</Label>
        {WARWICK_EDINBURGH_STATEMENTS.map((statement, i) => {
          return (
            <DiscreteRangeInput
              key={statement.long.replace("I've ", "")}
              label={statement.long.replace("I've ", "")}
              steps={WARWICK_EDINBURGH_STEPS}
              value={warwickEdinburgh ? warwickEdinburgh[i] : undefined}
              onChange={(value) =>
                onChange({
                  warwickEdinburgh: { ...warwickEdinburgh, [i]: value },
                })
              }
            />
          );
        })}
      </Field>
    </>
  );
};
