import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { FC, useEffect, useState } from "react";
import Button from "./Button";
import Text from "./Text";

interface UsePaginationIn<T> {
  items: ReadonlyArray<T>;
  pageSize: number;
}

interface UsePaginationOut<T> {
  canNext: boolean;
  canPrevious: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  page: ReadonlyArray<T>;
  pageIndex: number;
  pageSize: number;
  totalItems: number;
}

export function usePagination<T>({
  items,
  pageSize,
}: UsePaginationIn<T>): UsePaginationOut<T> {
  const [pageIndex, setPageIndex] = useState<number>(0);
  const lastPageIndex = Math.floor((items.length - 1) / pageSize);
  const canNext = pageIndex < lastPageIndex;
  const canPrevious = pageIndex > 0;
  const onNext = () => canNext && setPageIndex(pageIndex + 1);
  const onPrevious = () => canPrevious && setPageIndex(pageIndex - 1);
  const page = items.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
  const totalItems = items.length;

  // Reset to first page when total number of items changes
  useEffect(() => {
    setPageIndex(0);
  }, [totalItems]);

  return {
    canNext,
    canPrevious,
    onNext,
    onPrevious,
    page,
    pageIndex,
    pageSize,
    totalItems,
  };
}

interface PaginationProps {
  canNext: boolean;
  canPrevious: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  pageIndex: number;
  pageSize: number;
  totalItems: number;
}

export const Pagination: FC<PaginationProps> = ({
  canNext,
  canPrevious,
  onNext,
  onPrevious,
  pageIndex,
  pageSize,
  totalItems,
}) => {
  const firstVisibleItem = pageIndex * pageSize + 1;
  const lastVisibleItem = Math.min(pageIndex * pageSize + pageSize, totalItems);
  return (
    <div className="flex items-center gap-1">
      <Tippy content="Previous">
        <Button secondary onClick={onPrevious} disabled={!canPrevious}>
          <FontAwesomeIcon fixedWidth icon={faChevronLeft} />
        </Button>
      </Tippy>
      {/* min-width stops arrow buttons from jumping around as numbers change */}
      <Text variant="smallLight" className="min-w-[9em] text-center">
        {firstVisibleItem} - {lastVisibleItem} of {totalItems}
      </Text>
      <Tippy content="Next">
        <Button secondary onClick={onNext} disabled={!canNext}>
          <FontAwesomeIcon fixedWidth icon={faChevronRight} />
        </Button>
      </Tippy>
    </div>
  );
};
