import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, ReactNode, useRef, useState } from "react";
import Button from "./Button";
import Tippy from "@tippyjs/react";
import {
  faClipboard,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";

interface CopyableProps {
  children: string;
  tooltip?: (isCopied: boolean) => ReactNode;
}

const Copyable: FC<CopyableProps> = ({
  children: text,
  tooltip = (isCopied) => (isCopied ? "Copied" : "Copy"),
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const textRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    if (!textRef.current) return;
    textRef.current.select();
    document.execCommand("copy");
    setIsCopied(true);
  };
  const handleBlur = () => {
    setIsCopied(false);
  };

  return (
    <div
      className="flex w-96 items-stretch justify-between rounded-md border border-gray-46"
      onClick={handleClick}
    >
      <input
        ref={textRef}
        className="flex-1 bg-transparent pl-1 font-mono text-sm"
        value={text}
        onBlur={handleBlur}
        readOnly
      />
      <Tippy
        content={<span>{tooltip(isCopied)}</span>}
        trigger="mouseenter"
        hideOnClick={false}
      >
        <Button secondary>
          {isCopied ? (
            <FontAwesomeIcon icon={faClipboardCheck} />
          ) : (
            <FontAwesomeIcon icon={faClipboard} />
          )}
        </Button>
      </Tippy>
    </div>
  );
};

export default Copyable;
