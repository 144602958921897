export interface IPatient {
  readonly nhsNumber?: string;
  readonly firstName?: string;
  readonly surname?: string;
  readonly knownAs?: string;
  readonly dateOfBirth?: string; // YYYY-MM-DD
  readonly dateOfDeath?: string; // YYYY-MM-DD
  readonly gender?: string;
  readonly ethnicity?: string;
  readonly ethnicityDetails?: string;
  readonly address?: string;
  readonly postcode?: string;
  readonly lat?: number;
  readonly lng?: number;
  readonly events?: Record<string, IPatientEvent>;
  readonly goals?: Record<string, IPatientGoal>;
  readonly referralDate?: string; // YYYY-MM-DD
  readonly referrerType?: string;
  readonly referrerName?: string;
  readonly referralReasons?: Set<string>;
  readonly referralSupport?: string;
  readonly referralNotes?: string;
  readonly reminder?: string; // YYYY-MM-DD
  readonly hopes?: string;
  readonly barriers?: string;
  readonly support?: string;
  readonly assignee?: string; // UID
  readonly phone?: string;
  readonly email?: string;
  readonly flags?: Set<string>;
  readonly _edits?: Map<string, IPatientEdit>;
  readonly isArchived?: boolean;
}

export interface IPatientEdit {
  readonly _time: Date;
  readonly _uid: string;
  readonly [fieldPath: string]: unknown; // firstName, goals.someUniqueId.aim
}

export interface IPatientEvent {
  readonly date: string; // YYYY-MM-DD
  readonly duration?: number;
  readonly notes?: string;
  readonly tags?: Set<string>;
  readonly type?: string;
  readonly height?: number;
  readonly weight?: number;
  readonly employment?: string;
  readonly housing?: string;
  readonly livesWith?: string;
  readonly alcohol?: string;
  readonly smoking?: string;
  readonly warwickEdinburgh?: Record<number, number | undefined>;
  readonly ons4?: Record<number, number | undefined>;
  readonly mycaw?: MycawRecord;
  readonly loneliness?: Record<number, number | undefined>;
  readonly kidscreen?: Record<number, number | undefined>;
}

export const GOAL_MILESTONES = [
  "created",
  "attended",
  "abandoned",
  "doneAt",
] as const;
export type GOAL_MILESTONE_ID = (typeof GOAL_MILESTONES)[number];

export type IPatientGoal = {
  [k in GOAL_MILESTONE_ID]?: string; // "YYYY-MM-DD"
} & {
  readonly aim?: string;
  readonly tags?: Set<string>;
  readonly serviceIds?: Set<string>;
  readonly achievement?: string;
};

export type MycawRecord = {
  readonly respondent?: string;
  readonly location?: string;
  readonly concern1?: string;
  readonly concern2?: string;
  readonly concern1Rating?: number;
  readonly concern2Rating?: number;
  readonly wellbeingRating?: number;
  readonly otherThings?: string;
  readonly mostImportant?: string;
};

export const GENDERS = [
  "Female",
  "Male",
  "Gender Fluid",
  "Non-Binary",
  "Transgender",
  "Prefer not to say",
];

export const ETHNICITIES = {
  White: [
    "British (English/Welsh/Scottish/Northern Irish)",
    "Irish",
    "Gypsy or Irish Traveller",
    "Other (White)",
  ],
  "Mixed/Multiple": [
    "White & Black Caribbean",
    "White & Black African",
    "White & Asian",
    "Other (Mixed/Multiple)",
  ],
  "Asian/Asian British": [
    "Indian",
    "Pakistani",
    "Bangladeshi",
    "Chinese",
    "Other (Asian)",
  ],
  "Black/African/Caribbean/Black British": [
    "African",
    "Caribbean",
    "Other (Black/African/Caribbean)",
  ],
  Other: ["Arab", "Other", "Prefer not to say"],
};

//Order is vital! These are stored in the database by index
export const WARWICK_EDINBURGH_STATEMENTS = [
  {
    short: "optimistic",
    long: "I've been feeling optimistic about the future",
  },
  { short: "useful", long: "I've been feeling useful" },
  { short: "relaxed", long: "I've been feeling relaxed" },
  {
    short: "interested in others",
    long: "I've been feeling interested in other people",
  },
  { short: "good about myself", long: "I've been feeling good about myself" },
  { short: "close to others", long: "I've been feeling close to other people" },
  { short: "confident", long: "I've been feeling confident" },
  { short: "loved", long: "I've been feeling loved" },
  { short: "cheerful", long: "I've been feeling cheerful" },
  { short: "had energy to spare", long: "I've had energy to spare" },
  {
    short: "been dealing with problems well",
    long: "I've been dealing with problems well",
  },
  { short: "been thinking clearly", long: "I've been thinking clearly" },
  {
    short: "been able to make up my own mind",
    long: "I've been able to make up my own mind about things",
  },
  {
    short: "been interested in new things",
    long: "I've been interested in new things",
  },
];
export const WARWICK_EDINBURGH_STEPS = [
  "None of the time",
  "Rarely",
  "Some of the time",
  "Often",
  "All of the time",
];
export const WARWICK_EDINBURGH_MAX =
  WARWICK_EDINBURGH_STATEMENTS.length * WARWICK_EDINBURGH_STEPS.length;

//Order is vital! These are stored in the database by index
export const ONS4_STATEMENTS = [
  {
    short: "Satisfied",
    long: "Overall, how satisfied are you with your life nowadays?",
  },
  {
    short: "Worthwhile",
    long: "Overall, to what extent do you feel that the things you do in your life are worthwhile?",
  },
  { short: "Happy", long: "Overall, how happy did you feel yesterday?" },
  {
    short: "Anxious (lower is better)",
    long: "Overall, on a scale where 0 is 'not at all anxious' and 10 is 'completely anxious', how anxious did you feel yesterday?",
  },
];
export const ONS4_STEPS = [
  "0 (not at all)",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10 (completely)",
];

export const LONELINESS_STATEMENTS = [
  "I am content with my friendships and relationships",
  "I have enough people I feel comfortable asking for help at any time",
  "My relationships are as satisfying as I would want them to be",
];
export const LONELINESS_STEPS = [
  "Strongly agree",
  "Agree",
  "Neutral",
  "Disagree",
  "Strongly disagree",
  "Don't know",
];

//Order is vital! These are stored in the database by index
export const KIDSCREEN_STEPS = {
  FREQUENCY: ["never", "seldom", "quite often", "very often", "always"],
  DEGREE: ["not at all", "slightly", "moderately", "very", "extremely"],
  HEALTH: ["poor", "fair", "good", "very good", "excellent"],
};
export const KIDSCREEN_QUESTIONS = {
  "Have you felt fit and well?": KIDSCREEN_STEPS.DEGREE,
  "Have you felt full of energy?": KIDSCREEN_STEPS.FREQUENCY,
  "Have you felt sad?": KIDSCREEN_STEPS.FREQUENCY,
  "Have you felt lonely?": KIDSCREEN_STEPS.FREQUENCY,
  "Have you had enough time for yourself?": KIDSCREEN_STEPS.FREQUENCY,
  "Have you been able to do the things that you want to do in your free time?":
    KIDSCREEN_STEPS.FREQUENCY,
  "Have your parent(s) treated you fairly?": KIDSCREEN_STEPS.FREQUENCY,
  "Have you had fun with your friends?": KIDSCREEN_STEPS.FREQUENCY,
  "Have you got on well at school?": KIDSCREEN_STEPS.DEGREE,
  "Have you been able to pay attention?": KIDSCREEN_STEPS.FREQUENCY,
  "How would you say your health is?": KIDSCREEN_STEPS.HEALTH,
};

export const MAX_FLAGS = 3;

export const calculateBmi = (height: number, weight: number) => {
  return Math.round(weight / (height * height));
};
export const toFeetAndInches = (metres: number) => {
  const decimalFeet = metres * 3.2808;
  const feet = Math.floor(decimalFeet);
  const inches = Math.round((decimalFeet - feet) * 12);
  return `${feet} ft ${inches} in`;
};
export const toStoneAndPounds = (kilograms: number) => {
  const decimalStone = kilograms * 0.1574;
  const stone = Math.floor(decimalStone);
  const pounds = Math.round((decimalStone - stone) * 14);
  return `${stone} st ${pounds} lb`;
};
