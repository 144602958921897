import { FC, useContext, useRef, useState } from "react";
import * as ReactDOM from "react-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ConfirmButton } from "_shared/components/Button";
import { Field, Label, Input } from "_shared/components/Field";
import TagSelect, {
  useDemographicsServiceFilters,
} from "_shared/components/TagSelect";
import "_shared/css/Goals.css";
import { useParams } from "react-router-dom";
import { GroupContext } from "../Group";
import { Link } from "react-router-dom";
import Accordion, {
  AccordionContext,
  AccordionGroup,
} from "_shared/components/Accordion";
import { useId, usePrinter } from "_shared/hooks";
import { AddMilestone, Milestone } from "./Milestone";
import {
  faMinusCircle,
  faPlus,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { faHandsHelping as farHandsHelping } from "@fortawesome/pro-regular-svg-icons";
import {
  GOAL_MILESTONES,
  GOAL_MILESTONE_ID,
  IPatientGoal,
} from "_shared/models/Patient";
import { Nullable } from "_shared/utils";
import ChooseServiceModal from "_shared/components/ChooseServiceModal";
import Text from "_shared/components/Text";

interface GoalsProps {
  onCreate: (event: { created: string }) => void;
  onUpdate: (id: string, update: Nullable<Partial<IPatientGoal>>) => void;
  onDelete: (id: string) => void;
  goals: Record<string, IPatientGoal>;
  isOuterFormValid?: boolean;
}

const Goals: FC<GoalsProps> = (props) => {
  const { onCreate, onUpdate, onDelete, isOuterFormValid = true } = props;
  const goals = Object.entries(props.goals)
    .map(([id, goal]) => {
      return { id, ...goal };
    })
    .sort((a, b) => {
      if (!a.created) return 1;
      if (!b.created) return -1;
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    });

  const group = useContext(GroupContext);
  const { isPrinting, print, printable } = usePrinter();

  const onCreatePressed = () => {
    onCreate({ created: new Date().toLocaleDateString("en-CA") });
  };

  if (isPrinting)
    return ReactDOM.createPortal(
      <>
        <Text variant="h2">Goals</Text>
        {group && (
          <div className="goals__install-app">
            <div>
              <p>
                Find out more about recommended services on the Help @ Hand app:
              </p>
              <p>
                <a href="https://www.hand.community">www.hand.community</a>
              </p>
              <p>
                {group.name}
                <br />
                {group.address}
              </p>
            </div>
            <img
              className="goals__qr"
              src="/assets/app-qr-code.svg"
              alt="Scan this QR code to download Help @ Hand"
            />
          </div>
        )}
        {goals.map((goal) => (
          <div key={goal.id}>
            <Text variant="h3">{goal.aim}</Text>
            <Goal goal={goal} />
          </div>
        ))}
      </>,
      printable
    );

  return (
    <div className="goals">
      <img src="/assets/app-qr-code.svg" alt="" style={{ display: "none" }} />{" "}
      {/* Preload image for printing */}
      <Text variant="h2" id="goals">
        Goals
        <Button secondary className="goals__print" onClick={print}>
          <FontAwesomeIcon icon={faPrint} /> Print
        </Button>
      </Text>
      <AccordionGroup>
        {goals.map((goal) => {
          const { id, aim } = goal;
          return (
            <Accordion
              key={id}
              disabled={!isOuterFormValid}
              summary={
                <AccordionContext.Consumer>
                  {({ isOpen, toggle }) => (
                    <div className="goals__summary">
                      <Input
                        aria-label="Aim"
                        className="goals__aim"
                        isSilent
                        placeholder="Type a goal..."
                        autoFocus={!aim}
                        onChange={(e) => onUpdate(id, { aim: e.target.value })}
                        onKeyPress={(e) => {
                          if (e.key !== "Enter") return;
                          if (!isOpen) toggle();
                          e.currentTarget.blur();
                        }}
                        value={aim || ""}
                      />
                      <ConfirmButton
                        secondary
                        kind="danger"
                        onConfirm={() => onDelete(id)}
                        tooltip={(isConfirming) =>
                          isConfirming
                            ? "Really delete this entire goal?"
                            : "Delete goal"
                        }
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </ConfirmButton>
                    </div>
                  )}
                </AccordionContext.Consumer>
              }
            >
              <Goal goal={goal} onUpdate={(update) => onUpdate(id, update)} />
            </Accordion>
          );
        })}
      </AccordionGroup>
      <div className="goals__new">
        <Button onClick={onCreatePressed}>
          <FontAwesomeIcon icon={faPlus} /> Add goal
        </Button>
      </div>
    </div>
  );
};

export default Goals;

interface GoalProps {
  goal: IPatientGoal;
  onUpdate?: (update: Nullable<Partial<IPatientGoal>>) => void;
}

const Goal: FC<GoalProps> = ({ goal, onUpdate }) => {
  const { tags, serviceIds } = goal;
  const hasService = serviceIds && serviceIds.size > 0;
  const definedMilestones = new Set(
    GOAL_MILESTONES.filter(
      (key) => key in goal && typeof goal[key] === "string"
    )
  );
  const isDone = definedMilestones.has("doneAt");
  const isAbandoned = definedMilestones.has("abandoned");
  const milestones: Partial<Record<GOAL_MILESTONE_ID, string>> = {};
  for (const milestone of Array.from(definedMilestones)) {
    milestones[milestone] = goal[milestone];
  }

  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const group = useContext(GroupContext);
  const servicesById = group?.services ?? {};
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const suggestServiceRef = useRef<HTMLButtonElement | null>(null);
  const ariaId = useId();
  const [demographicsFilterOptionGroups] = useDemographicsServiceFilters();

  const toggleService = (id: string) => {
    const update = new Set(serviceIds);
    if (update.has(id)) update.delete(id);
    else update.add(id);
    onUpdate?.({ serviceIds: update });
  };
  const onModalClosed = () => {
    suggestServiceRef.current?.focus();
    setIsServiceModalOpen(false);
  };
  const setMilestone = (milestone: GOAL_MILESTONE_ID, date: string | null) => {
    onUpdate?.({
      [milestone]: date,
    });
  };

  const classes = ["goal"];

  return (
    <div className={classes.join(" ")}>
      {isServiceModalOpen && (
        <ChooseServiceModal
          onChoose={(id) => onUpdate?.({ serviceIds: new Set([id]) })}
          onClose={onModalClosed}
        />
      )}
      <Field wide>
        <Label strong id={`${ariaId}_tags`}>
          Tags
        </Label>
        <TagSelect
          aria-labelledby={`${ariaId}_tags`}
          value={tags}
          onChange={(tags) => onUpdate?.({ tags })}
          optionGroups={demographicsFilterOptionGroups}
        />
      </Field>
      <Field wide>
        <Label strong>Service</Label>
        {hasService ? (
          <ul className="goal__services">
            {Array.from(serviceIds)
              .map((id) => {
                return { id, name: "[deleted service]", ...servicesById[id] };
              })
              .filter((service) => service) //If service no longer exists, hide it
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(({ id, name, ...service }) => {
                return (
                  <li key={id} className="goal__service">
                    <Link to={`/groups/${groupId}/services/${id}`}>{name}</Link>
                    <ConfirmButton
                      secondary
                      kind="danger"
                      onConfirm={() => toggleService(id)}
                      tooltip={(isConfirming) =>
                        isConfirming
                          ? "Really remove service?"
                          : "Remove service"
                      }
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </ConfirmButton>
                  </li>
                );
              })}
          </ul>
        ) : (
          <Button
            ref={suggestServiceRef}
            secondary
            onClick={() => setIsServiceModalOpen(true)}
          >
            <FontAwesomeIcon icon={farHandsHelping} /> Suggest service
          </Button>
        )}
      </Field>
      <Field wide>
        <Label strong>Milestones</Label>
        <div className="goal__milestones">
          {Object.entries(milestones)
            .filter(([_, date]) => typeof date === "string")
            .sort(([_, aDate], [__, bDate]) => {
              if (!aDate) return 1;
              if (!bDate) return -1;
              return new Date(aDate).getTime() - new Date(bDate).getTime();
            })
            .map(([milestone, date], i) => {
              return (
                <Milestone
                  key={milestone}
                  milestone={milestone as GOAL_MILESTONE_ID}
                  date={date}
                  index={i}
                  count={definedMilestones.size}
                  onChange={(date) =>
                    setMilestone(milestone as GOAL_MILESTONE_ID, date)
                  }
                />
              );
            })}
          {!isAbandoned && !isDone && (
            <AddMilestone
              filter={(milestone) => {
                if (milestone === "attended" && !hasService) return false;
                return !definedMilestones.has(milestone);
              }}
              onCreate={(milestone) => {
                setMilestone(milestone, new Date().toLocaleDateString("en-CA"));
              }}
            />
          )}
        </div>
      </Field>
      {isDone && (
        <Field wide>
          <Label strong htmlFor={`${ariaId}_achievement`}>
            Describe the achievement
          </Label>
          <Input
            className="goal__input"
            value={goal.achievement ?? ""}
            onChange={(e) => onUpdate?.({ achievement: e.target.value })}
            id={`${ariaId}_achievement`}
          />
        </Field>
      )}
    </div>
  );
};
