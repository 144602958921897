import { FC, useState } from "react";
import {
  WARWICK_EDINBURGH_STATEMENTS,
  WARWICK_EDINBURGH_STEPS,
  IPatientEvent,
} from "_shared/models/Patient";
import Text from "_shared/components/Text";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "_shared/chart";
import { CheckboxField } from "_shared/components/Field";

interface WarwickEdinburghChartProps {
  events: Array<IPatientEvent>;
}

const allStatementIndices = new Set<number>(
  Object.keys(WARWICK_EDINBURGH_STATEMENTS).map(Number)
);
const statementLabels = WARWICK_EDINBURGH_STATEMENTS.map(
  (statement) => statement.short
);

export const WarwickEdinburghChart: FC<WarwickEdinburghChartProps> = ({
  events,
}) => {
  const [selectedStatementIndices, setSelectedStatementIndices] =
    useState<Set<number>>(allStatementIndices);

  const warwickEdinburghData: Array<{
    id: number;
    x: Date;
    y: number | undefined;
  }> = [];
  const data: [{ x: Date; y: number | undefined }[]] = [[]];
  const backgroundColor: Array<string> = [];

  events.forEach((event) => {
    selectedStatementIndices.forEach((i) => {
      if (!event.warwickEdinburgh) return;
      warwickEdinburghData.push({
        id: i,
        x: new Date(event.date),
        y: event.warwickEdinburgh[i],
      });
    });
  });

  selectedStatementIndices.forEach((label, i) => {
    data.push(
      warwickEdinburghData
        .filter((data) => data.id === i)
        .map((statement) => {
          return { x: statement.x, y: statement.y };
        })
    );
    backgroundColor.push(CHART_COLORS[i % CHART_COLORS.length]);
  });

  return (
    <>
      <Text variant="h3">Breakdown</Text>
      <Line
        options={{
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: "week",
                tooltipFormat: "Do MMMM YYYY",
              },
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              suggestedMin: 0,
              suggestedMax: 4,
              ticks: {
                stepSize: 1.0,
                callback: (value) => {
                  return WARWICK_EDINBURGH_STEPS[parseInt(value as string)];
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          datasets: statementLabels.map((statement, i) => {
            return {
              label: i.toString(),
              borderColor: backgroundColor[i],
              pointBackgroundColor: backgroundColor[i],
              backgroundColor: "transparent",
              data: data[i + 1],
            };
          }),
        }}
      />
      <CheckboxField
        label={<Text variant="smallLight">I've been feeling...</Text>}
        value={
          new Set(Array.from(selectedStatementIndices).map((i) => i.toString()))
        }
        options={WARWICK_EDINBURGH_STATEMENTS.filter((statement) =>
          statement.long.includes("been feeling")
        ).map((statement, i) => {
          return {
            value: i.toString(),
            label: (
              <div className="flex items-center gap-2">
                <div
                  className="aspect-square w-2 rounded-full"
                  style={{
                    backgroundColor: CHART_COLORS[i % CHART_COLORS.length],
                  }}
                />
                <Text variant="smallLight">{statement.short}</Text>
              </div>
            ),
          };
        })}
        onChange={(value: Set<string>) => {
          setSelectedStatementIndices(
            new Set(Array.from(value).map((i) => Number(i)))
          );
        }}
      />
      <CheckboxField
        label={<Text variant="smallLight">I've...</Text>}
        value={
          new Set(Array.from(selectedStatementIndices).map((i) => i.toString()))
        }
        options={WARWICK_EDINBURGH_STATEMENTS.filter(
          (statement) => !statement.long.includes("been feeling")
        ).map((statement, i) => {
          i =
            i +
            WARWICK_EDINBURGH_STATEMENTS.findIndex(
              (statement) => !statement.long.includes("been feeling")
            );
          return {
            value: i.toString(),
            label: (
              <div className="flex items-center gap-2">
                <div
                  className="aspect-square w-2 rounded-full"
                  style={{
                    backgroundColor: CHART_COLORS[i % CHART_COLORS.length],
                  }}
                />
                <Text variant="smallLight">{statement.short}</Text>
              </div>
            ),
          };
        })}
        onChange={(value: Set<string>) => {
          setSelectedStatementIndices(
            new Set(Array.from(value).map((i) => Number(i)))
          );
        }}
      />
    </>
  );
};
