import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { nanoid } from "nanoid";
import {
  ClipboardEvent,
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { UserContext } from "./Auth";
import Button from "./Button";
import { Label, TextField } from "./Field";
import Text from "./Text";
import "_shared/css/Users.css";

interface UsersProps {
  onAddEmails: (emailsByUid: ReadonlyArray<string>) => Promise<void>;
  onRemoveEmail: (email: string) => Promise<void>;
  userProfiles?: {
    [uid: string]: {
      email: string;
    };
  };
}

const Users: FC<UsersProps> = ({
  userProfiles = {},
  onAddEmails,
  onRemoveEmail,
}) => {
  const user = useContext(UserContext) || {};
  const [draftEmails, setDraftEmails] = useState<Record<string, string>>({});
  const [isEveryEmailValid, setIsEveryEmailValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const draftEmailsCount = Object.keys(draftEmails).length;
  const isAdding = draftEmailsCount > 0;
  const addEmailsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!addEmailsRef.current) return;
    const inputs =
      addEmailsRef.current.querySelectorAll<HTMLInputElement>(
        "input[type=email]"
      );
    setIsEveryEmailValid(
      Array.from(inputs).every((input) => input.checkValidity())
    );
  }, [addEmailsRef, draftEmails, setIsEveryEmailValid]);

  const onAddPressed = () => {
    setDraftEmails({
      [nanoid()]: "",
    });
  };
  const onDraftEmailChanged = (key: string, email: string) => {
    setDraftEmails({
      ...draftEmails,
      [key]: email,
    });
  };
  const onDraftEmailRemoved = (key: string) => {
    const update = { ...draftEmails };
    delete update[key];
    setDraftEmails(update);
  };
  const handleSubmitClick = async () => {
    setIsLoading(true);
    try {
      await onAddEmails(Object.values(draftEmails));
      setDraftEmails({});
    } catch (e) {
      console.error(e);
      window.alert(
        "Something went wrong, please check the emails and try again"
      );
    }
    setIsLoading(false);
  };
  const handleUserRemoved = async (email: string) => {
    if (!window.confirm(`Remove ${email}?`)) return;
    setIsLoading(true);
    try {
      await onRemoveEmail(email);
    } catch (e) {
      console.error(e);
      window.alert("Something went wrong, please try again");
    }
    setIsLoading(false);
  };
  const onPaste = (key: string, e: ClipboardEvent<HTMLInputElement>) => {
    if (draftEmailsCount > 1) return;
    // Detect multiple emails and split into several fields
    const pastedEmails = e.clipboardData
      .getData("text/plain")
      .replace(/\s+/g, " ")
      .trim()
      .split(" ");
    if (pastedEmails.length < 2) return;
    if (pastedEmails.length > 99) return;
    if (!pastedEmails.every((email) => email.includes("@"))) return;
    e.preventDefault();
    const newDraftEmails: Record<string, string> = {};
    if (draftEmails[key]) newDraftEmails[key] = draftEmails[key]; // Keep existing email if set
    for (const email of pastedEmails) {
      newDraftEmails[nanoid()] = email;
    }
    setDraftEmails(newDraftEmails);
  };

  return (
    <div className="users flex flex-col gap-4">
      <ul className="users__list">
        {Object.entries(userProfiles || {})
          .sort(([_, aProfile], [__, bProfile]) =>
            aProfile.email.localeCompare(bProfile.email)
          )
          .map(([uid, profile]) => {
            return (
              <li key={uid} className="users__user">
                {profile.email}
                {
                  <Tippy
                    content={
                      <span>
                        {user.uid === uid
                          ? "You cannot remove yourself"
                          : "Remove user"}
                      </span>
                    }
                  >
                    {/* A wrapping span enables Tippy on disabled buttons */}
                    <span>
                      <Button
                        secondary
                        kind="danger"
                        onClick={() => handleUserRemoved(profile.email)}
                        disabled={isLoading || user.uid === uid}
                      >
                        <FontAwesomeIcon icon={faMinusCircle} />
                      </Button>
                    </span>
                  </Tippy>
                }
              </li>
            );
          })}
      </ul>
      <div>
        {isAdding ? (
          <div ref={addEmailsRef} className="flex flex-col items-start gap-4">
            <div className="self-stretch">
              <Label strong>Email(s)</Label>
              {Object.entries(draftEmails).map(([key, email], i) => {
                return (
                  <div key={key} className="users__add-email">
                    <TextField
                      type="email"
                      aria-label="Email"
                      autoFocus={i === 0}
                      value={email}
                      onChange={(e) => onDraftEmailChanged(key, e.target.value)}
                      onPaste={(e) => onPaste(key, e)}
                      required
                    />
                    {draftEmailsCount > 1 && (
                      <Tippy content={<span>Remove email</span>}>
                        <Button
                          secondary
                          kind="danger"
                          onClick={() => onDraftEmailRemoved(key)}
                          disabled={isLoading}
                        >
                          <FontAwesomeIcon icon={faMinusCircle} />
                        </Button>
                      </Tippy>
                    )}
                  </div>
                );
              })}
              {draftEmailsCount < 2 && (
                <Text variant="smallLight">
                  Add multiple users by copy-pasting a list of emails
                </Text>
              )}
            </div>
            <Button
              onClick={handleSubmitClick}
              disabled={!isEveryEmailValid || isLoading}
            >
              {isLoading ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} spin /> Adding{" "}
                  {draftEmailsCount} user{draftEmailsCount !== 1 && "s"}...
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faPlus} /> Add {draftEmailsCount} user
                  {draftEmailsCount !== 1 && "s"}
                </>
              )}
            </Button>
          </div>
        ) : (
          <Button onClick={onAddPressed}>
            <FontAwesomeIcon icon={faPlus} /> Add user
          </Button>
        )}
      </div>
    </div>
  );
};

export default Users;
