import { FC } from "react";
import { IService } from "_shared/models/Service";

interface ServiceTitleProps {
  service: IService;
}

export const ServiceTitle: FC<ServiceTitleProps> = ({ service }) => {
  const { name, lastEdited } = service;
  return <>{name || (lastEdited ? "-" : "New Service")}</>;
};
