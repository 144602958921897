import { ComponentProps, forwardRef } from "react";
import "_shared/css/Wrapper.css";

interface WrapperProps extends ComponentProps<"div"> {
  center?: boolean;
  pad?: boolean;
  wide?: boolean;
}

const Wrapper = forwardRef<HTMLDivElement, WrapperProps>((props, ref) => {
  const {
    children,
    className,
    center = false,
    pad = false,
    wide = false,
    ...otherProps
  } = props;
  const classes = ["wrapper"];
  if (className) classes.push(className);
  if (center) classes.push(`${classes[0]}--center`);
  if (pad) classes.push(`${classes[0]}--pad`);
  if (wide) classes.push(`${classes[0]}--wide`);
  return (
    <div ref={ref} className={classes.join(" ")} {...otherProps}>
      {children}
    </div>
  );
});
export default Wrapper;
