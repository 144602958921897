import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import Wrapper from "./Wrapper";
import "_shared/css/Modal.css";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { useId } from "_shared/hooks";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Text from "./Text";

const ModalContext = createContext<{
  readonly headerId: string;
  readonly onClose: () => void;
}>({
  headerId: "",
  onClose: () => {
    throw new Error("ModalContext.onClose() was called outside <Modal>");
  },
});

interface ModalProps {
  onClose: () => void;
}

export const Modal: FC<ModalProps> = ({ children, onClose }) => {
  const id = useId();
  const ref = useRef<HTMLDivElement | null>(null);

  // Close modal when escape key is pressed
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") onClose();
    };
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  // Trap focus inside the modal
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!ref.current) return;
      if (e.key !== "Tab") return;
      const isTabbingForward = !e.shiftKey;

      const focusables = ref.current.querySelectorAll<HTMLElement>(
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]'
      );
      const firstFocusable = focusables[0];
      const lastFocusable = focusables[focusables.length - 1];

      if (isTabbingForward) {
        if (document.activeElement === lastFocusable) {
          e.preventDefault();
          firstFocusable.focus();
        }
      } else {
        if (document.activeElement === firstFocusable) {
          e.preventDefault();
          lastFocusable.focus();
        }
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [ref]);

  const headerId = `${id}__header`;
  const context = useMemo(() => {
    return { onClose, headerId };
  }, [headerId, onClose]);

  return (
    <ModalContext.Provider value={context}>
      <div
        ref={ref}
        className="modal"
        role="dialog"
        aria-labelledby={headerId}
        onClick={onClose}
      >
        <Wrapper
          wide
          center
          className="modal__window"
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </Wrapper>
      </div>
    </ModalContext.Provider>
  );
};

export const ModalHeader: FC = ({ children }) => {
  const context = useContext(ModalContext);
  return (
    <div className="modal__header" id={context.headerId}>
      {children}
      <Tippy content={<span>Close</span>}>
        <Button secondary onClick={context.onClose} autoFocus>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      </Tippy>
    </div>
  );
};

export const ModalTitle: FC = ({ children }) => {
  return <Text variant="h3">{children}</Text>;
};

export const ModalFooter: FC = ({ children }) => {
  return <div className="modal__footer">{children}</div>;
};
