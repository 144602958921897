import { signOut } from "firebase/auth";
import { auth } from "_shared/firebase";
import { UserContext } from "_shared/components/Auth";
import ChooseGroup from "./ChooseGroup";
import Wrapper from "_shared/components/Wrapper";
import Button from "_shared/components/Button";
import { FC } from "react";
import Text from "_shared/components/Text";

const Groups: FC = () => {
  return (
    <>
      <Wrapper center pad>
        <Text variant="h1">Welcome</Text>
        <ChooseGroup />
        <hr />
        <UserContext.Consumer>
          {(user) => {
            if (!user?.email) return;
            return (
              <>
                <p>Signed in as {user.email}</p>
                <Button secondary onClick={() => signOut(auth)}>
                  Sign out
                </Button>
              </>
            );
          }}
        </UserContext.Consumer>
      </Wrapper>
    </>
  );
};
export default Groups;
