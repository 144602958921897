import { forwardRef } from "react";
import "_shared/css/Dot.css";

interface DotProps {
  color: "red";
  className?: string;
}

const Dot = forwardRef<HTMLElement, DotProps>((props, ref) => {
  const { color, className } = props;
  const classes = ["dot"];
  classes.push(`${classes[0]}--${color}`);
  if (className) classes.push(className);
  return <span ref={ref} className={classes.join(" ")}></span>;
});
export default Dot;
