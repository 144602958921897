import { omit, set, unset } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import {
  Link,
  Prompt,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCopy,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFileCsv,
  faPaperPlane,
  faPencilAlt,
  faPlus,
  faPrint,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  faMinusCircle,
  faSignalStream,
} from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-light-svg-icons";
import { nanoid } from "nanoid";
import { arrayUnion, doc, runTransaction, updateDoc } from "firebase/firestore";
import * as api from "_shared/api";
import EditService from "./EditService";
import Button, { ButtonGroup } from "_shared/components/Button";
import "_shared/css/Services.css";
import { GroupContext } from "../Group";
import { usePrinter, useUrl } from "_shared/hooks";
import Scrollable from "_shared/components/Scrollable";
import SaveState from "_shared/components/SaveState";
import Tippy from "@tippyjs/react";
import Wrapper from "_shared/components/Wrapper";
import UnloadPrompt from "_shared/components/UnloadPrompt";
import PreviewService from "./PreviewService";
import { ServicesSidebarWithAllFilters } from "./ServicesSidebar";
import { convertToFirestore, firestore } from "_shared/firebase";
import { IService } from "_shared/models/Service";
import { encodeCsv, formatDate, IS_DEV, Nullable } from "_shared/utils";
import ToggleShelfServiceUsage from "_shared/components/ToggleShelfServiceUsage";
import Dot from "_shared/components/Dot";
import Text from "_shared/components/Text";
import { ServiceTitle } from "./ServiceTitle";
import ReportShelfService from "../helpshelf/ReportShelfService";
import { useDynamicLinks } from "_shared/components/DynamicLinks";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { DownloadLink } from "_shared/components/DownloadLink";

const Services: FC = () => {
  const { isServicePublishingOn } = useFeatureFlags();
  const url = useUrl();
  const { path } = useRouteMatch();
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const history = useHistory();
  const group = useContext(GroupContext) || {};
  const { isPrinting, print, printable } = usePrinter();
  const emailServices = useEmailServices();
  const [pendingEdit, setPendingEdit] = useState<Record<string, {} | null>>({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [selectedServiceIds, setSelectedServiceIds] = useState<Set<string>>(
    new Set()
  );
  const [isSelectionBusy, setIsSelectionBusy] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(true);
  const [isPublishing, setIsPublishing] = useState<boolean>(false);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const groupDocPath = `groups/${groupId}`;
  const defaultLocationId = Object.keys(group.locations ?? {})[0];
  const isSaved = Object.keys(pendingEdit).length === 0;
  const servicesById = { ...group.services };
  Object.entries(pendingEdit).forEach(([groupPath, value]) => {
    const path = groupPath.replace("services.", "");
    // null is used to imply a subfield should be deleted
    if (value === null) unset(servicesById, path);
    else set(servicesById, path, value); // use deep setter as pendingEdit may contain updates to subfields stored in dot.notation
  });
  const selectedServices: ReadonlyArray<IService & { id: string }> = Array.from(
    selectedServiceIds
  )
    .map((id) => (servicesById[id] ? { id, ...servicesById[id] } : null))
    .filter(
      (service): service is IService & { id: string } => service !== null
    ); // Service may have just been deleted by another user
  const isASelectedServiceHidden = selectedServices.some(
    (service) => service.isHidden === true
  );

  // Save
  useEffect(() => {
    if (isSaved) return;
    if (!isValid) return;
    const save = async () => {
      const update: Record<string, {}> = {};
      let didEditServices = false;
      Object.entries(pendingEdit).forEach(([path, value]) => {
        if (path.startsWith("services")) didEditServices = true;
        update[path] = convertToFirestore(value);
      });
      // If services were edited, the below changes will trigger a cloud function to save a new version
      if (didEditServices) {
        // Add versionId to an index within the group doc
        const versionId = api.generateVersionId();
        update.versionIds = arrayUnion(versionId);
        update.servicesLastEdited = new Date().getTime(); // UTC unix time
      }
      await updateDoc(doc(firestore, groupDocPath), update);
      setPendingEdit({});
    };
    // If user has signalled intent to leave the page...
    if (isLeaving) {
      setIsLeaving(false);
      // Save immediately
      save();
    } else {
      // Otherwise save after debouncing for a few seconds
      const saveTimer = window.setTimeout(save, 6000);
      // Cleanup timer if relevant variables change before it executes
      return () => window.clearTimeout(saveTimer);
    }
  }, [groupDocPath, isLeaving, isSaved, isValid, pendingEdit]);
  // Re-enable publish button after a short delay
  useEffect(() => {
    setIsPublished(false);
  }, [pendingEdit, setIsPublished]);

  // Service CRUD
  const handleChanged = (serviceId: string, update: Nullable<IService>) => {
    const edit: Record<string, {} | null> = {
      // Hide the service on each change (overwritten if `update` unhides the service)
      [`services.${serviceId}.isHidden`]: true,
    };
    Object.entries(update).forEach(([field, value]) => {
      edit[`services.${serviceId}.${field}`] = value;
    });
    setPendingEdit((current) => ({ ...current, ...edit }));
  };
  const handleCreated = () => {
    const newServiceId = nanoid();
    handleChanged(newServiceId, {
      isHidden: true,
      lastEdited: new Date(),
    });
    history.push(`${url}/${newServiceId}`);
  };
  const handleDeleted = (serviceId: string, skipConfirm = false) => {
    if (!skipConfirm && !window.confirm("Delete this service?")) return;
    setPendingEdit((current) => {
      // Delete service
      const newEdit: Record<string, {} | null> = {
        [`services.${serviceId}`]: null,
      };
      // Ensure any pre-existing pending edits to this service are destroyed
      Object.entries(current).forEach(([path, value]) => {
        if (path.startsWith(`services.${serviceId}`)) return; // Skip edits to this service
        newEdit[path] = value; // Keep any other edits
      });
      return newEdit;
    });
    setIsValid(true);
  };
  const handleDuplicated = (service: IService) => {
    const newServiceId = nanoid();
    handleChanged(newServiceId, {
      ...service,
      name: `${service?.name || "Service"} Copy`,
      isHidden: true,
    });
    history.push(`${url}/${newServiceId}`);
  };
  const handleApproved = (serviceId: string) => {
    handleChanged(serviceId, {
      isHidden: false,
      lastEdited: new Date(),
      _hiddenAfterUpdate: null,
    });
  };

  // Validation
  const handleValidityChanged = setIsValid;

  // Publishing
  const handlePublished = async () => {
    setIsPublishing(true);
    // The mobile app still expects location.services to be an array
    const publicServices = Object.entries(servicesById || {})
      // Only approved services
      .filter(([_, service]) => {
        if (service.tags?.has("private")) return false;
        return service.isHidden === false;
      })
      .map(([id, service]) => {
        // Only public fields
        const censored = omit(
          service,
          "_globalId",
          "_hiddenAfterUpdate",
          "isHidden",
          "notes"
        );
        // Only non-null values
        const pathsToDelete: Array<string> = [];
        const converted = convertToFirestore({ id, ...censored }, (nullPath) =>
          pathsToDelete.push(nullPath)
        );
        return omit(converted, ...pathsToDelete);
      });
    const update = {
      services: publicServices,
    };
    // Copy services into each location
    try {
      await runTransaction(firestore, async (transaction) => {
        const locationsIndexSnap = await transaction.get(
          doc(firestore, "_meta/locations")
        );
        const locationsInGroupById =
          (locationsIndexSnap.data() ?? {})[groupId] ?? {};
        if (Object.keys(locationsInGroupById).length === 0)
          throw new Error(
            "Cannot publish to 0 locations, add at least one location."
          );
        Object.entries(locationsInGroupById).forEach(([locationId, _]) => {
          transaction.update(
            doc(firestore, `groups/${groupId}/locations/${locationId}`),
            update
          );
        });
      });
      setIsPublished(true);
    } catch (e) {
      console.error(e, update);
      window.alert("Services could not be published, please try again later.");
    }
    setIsPublishing(false);
  };

  const clearSelected = () => setSelectedServiceIds(new Set());
  const emailSelected = async () => {
    setIsSelectionBusy(true);
    await emailServices(
      groupId,
      defaultLocationId,
      Array.from(selectedServiceIds),
      servicesById
    );
    setIsSelectionBusy(false);
  };
  const deleteSelected = () => {
    const count = selectedServiceIds.size;
    if (!window.confirm(`Delete ${count} service${count === 1 ? "" : "s"}?`))
      return;
    selectedServiceIds.forEach((serviceId) => {
      handleDeleted(serviceId, true);
    });
    clearSelected();
  };
  const hasDraft = Object.values(group.services ?? {}).some(
    (service) => service.isHidden && !service.tags?.has("private")
  );

  // Render fullscreen portal when printing
  if (isPrinting)
    return ReactDOM.createPortal(
      <>
        {selectedServices.map(({ id, ...service }) => {
          return <PreviewService key={id} service={service} />;
        })}
      </>,
      printable
    );

  return (
    <div className="services">
      <UnloadPrompt when={!isSaved} onBeforeUnload={() => setIsLeaving(true)} />
      <Prompt
        message={(location) => {
          //Cancel all navigation within /services while invalid
          if (!isValid && location.pathname.startsWith(url)) return false;
          //Show a warning before leaving /services
          if (!isSaved && !location.pathname.startsWith(url)) {
            setIsLeaving(true);
            return "Your changes have not been saved.\n\nPress OK to discard changes.";
          }
          return true;
        }}
      />

      <header className="services__header">
        <Wrapper wide pad>
          <div className="services__header-row">
            <Text variant="h1" className="services__title">
              Services
            </Text>
            <SaveState
              state={!isValid ? "invalid" : !isSaved ? "saving" : "saved"}
            />
            <Tippy
              content={
                hasDraft ? (
                  <span>Approve all services or mark them as private</span>
                ) : !isServicePublishingOn ? (
                  <span>Publishing is temporarily disabled</span>
                ) : null
              }
              disabled={!hasDraft && isServicePublishingOn}
            >
              <span>
                <Button
                  className="services__publish"
                  onClick={handlePublished}
                  disabled={
                    !isServicePublishingOn ||
                    !isValid ||
                    !isSaved ||
                    isPublishing ||
                    isPublished ||
                    hasDraft
                  }
                >
                  {isPublished ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} /> Published
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faSignalStream} />
                      &nbsp;
                      {isPublishing ? "Publishing..." : "Publish"}
                    </>
                  )}
                </Button>
              </span>
            </Tippy>
          </div>

          <ButtonGroup>
            <Button secondary onClick={handleCreated} disabled={!isValid}>
              <FontAwesomeIcon icon={faPlus} /> Create service
            </Button>
            <Button
              secondary
              onClick={() => setIsEditMode(!isEditMode)}
              disabled={!isValid}
            >
              {isEditMode ? (
                <>
                  <FontAwesomeIcon icon={faEye} /> Preview
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faPencilAlt} /> Edit
                </>
              )}
            </Button>
            <Link to={`/public/groups/${groupId}`} target="_blank">
              <Button secondary>
                <FontAwesomeIcon icon={faExternalLinkAlt} /> View public link
              </Button>
            </Link>
            <DownloadLink
              filename="services.csv"
              getDataUrl={() => {
                const csv = encodeCsv(
                  Object.values(servicesById)
                    .sort((a, b) => {
                      if (!a.name || !b.name) {
                        return 0;
                      }
                      return a.name.localeCompare(b.name);
                    })
                    .map((service) => [service.name])
                );
                return `data:text/csv,${encodeURIComponent(csv)}`;
              }}
            >
              <Button secondary>
                <FontAwesomeIcon icon={faFileCsv} />
                Download
              </Button>
            </DownloadLink>
            {IS_DEV && (
              <DownloadLink
                filename={`groups_${groupId}.services.json`}
                getDataUrl={() =>
                  `data:text/json,${JSON.stringify(
                    convertToFirestore(servicesById)
                  )}`
                }
              >
                <Button secondary>Download JSON</Button>
              </DownloadLink>
            )}
            {IS_DEV && (
              <Button
                secondary
                kind="danger"
                onClick={() => {
                  if (!window.confirm("Really approve all services at once?"))
                    return;
                  for (const id of Object.keys(servicesById)) {
                    handleChanged(id, {
                      isHidden: false,
                    });
                  }
                }}
              >
                Approve all
              </Button>
            )}
          </ButtonGroup>
        </Wrapper>
      </header>

      <Wrapper wide className="services__main">
        <ServicesSidebarWithAllFilters
          servicesById={group.services} // Don't pass edits to avoid re-renders
          selectedIds={selectedServiceIds}
          onSelectedIdsChange={setSelectedServiceIds}
          disabled={!isValid}
        />
        <Scrollable className="services__detail">
          {selectedServices.length > 0 ? (
            <div className="flex flex-col items-start gap-4">
              <Text variant="h2">
                {selectedServices.length} service
                {selectedServices.length === 1 ? "" : "s"}
              </Text>
              <Button secondary onClick={print} disabled={isSelectionBusy}>
                <FontAwesomeIcon icon={faPrint} fixedWidth /> Print
              </Button>
              <Tippy
                content={<span>One or more services are hidden</span>}
                disabled={!isASelectedServiceHidden}
              >
                <span>
                  <Button
                    secondary
                    onClick={emailSelected}
                    disabled={isSelectionBusy || isASelectedServiceHidden}
                  >
                    <FontAwesomeIcon icon={faPaperPlane} fixedWidth /> Email
                  </Button>
                </span>
              </Tippy>
              <Button
                secondary
                kind="danger"
                onClick={deleteSelected}
                disabled={isSelectionBusy}
              >
                <FontAwesomeIcon icon={faMinusCircle} fixedWidth /> Delete
                selected services
              </Button>
              <Button
                secondary
                onClick={clearSelected}
                disabled={isSelectionBusy}
              >
                <FontAwesomeIcon icon={faTimes} fixedWidth /> Clear selection
              </Button>
            </div>
          ) : (
            <Route
              path={`${path}/:serviceId`}
              render={({ match }) => {
                const { serviceId } = match.params;
                const service = servicesById[serviceId];
                const isHelpShelfService = service?._globalId !== undefined;
                if (!service) return null;
                if (!isEditMode) return <PreviewService service={service} />;
                if (isHelpShelfService)
                  return (
                    <>
                      <div className="services__shelf-service-actions">
                        {service.isHidden ? (
                          <Button onClick={() => handleApproved(serviceId)}>
                            <FontAwesomeIcon icon={faCheck} /> Approve
                          </Button>
                        ) : (
                          <Button
                            secondary
                            onClick={() =>
                              handleChanged(serviceId, { isHidden: true })
                            }
                          >
                            <FontAwesomeIcon icon={faEyeSlash} /> Hide
                          </Button>
                        )}
                        <ButtonGroup>
                          <ToggleShelfServiceUsage
                            service={service}
                            serviceId={serviceId}
                          />
                          <ReportShelfService
                            service={service}
                            serviceId={serviceId}
                          />
                        </ButtonGroup>
                      </div>
                      <PreviewService service={service} />
                    </>
                  );
                const isStale = isServiceStale(service);
                return (
                  <>
                    <Text variant="h2" className="services__service-title">
                      <ServiceTitle service={service} />
                    </Text>
                    <Tippy
                      disabled={!service.tags?.has("private")}
                      content={
                        <span>
                          Services tagged 'Private' are always hidden from the
                          public app
                        </span>
                      }
                    >
                      <small className="services__service-status">
                        {service.tags?.has("private") ? (
                          <>
                            <FontAwesomeIcon icon={faLock} fixedWidth />{" "}
                            {"Private. "}
                          </>
                        ) : service.isHidden === false ? (
                          "Approved. "
                        ) : (
                          "Draft. "
                        )}
                        {service.lastEdited &&
                          `Edited ${formatDate(service.lastEdited)}`}
                      </small>
                    </Tippy>
                    <div className="services__service-actions">
                      <ButtonGroup>
                        {isStale && (
                          <Tippy
                            content={
                              <span>
                                Please check this service is up to date
                              </span>
                            }
                          >
                            <Dot className="service__stale-dot" color="red" />
                          </Tippy>
                        )}
                        <Button
                          onClick={() => handleApproved(serviceId)}
                          disabled={
                            !isValid ||
                            (!isServiceStale(service) && !service.isHidden)
                          }
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          {!isValid ||
                          (!isServiceStale(service) && !service.isHidden)
                            ? "Approved"
                            : "Approve"}
                        </Button>
                      </ButtonGroup>
                      <ButtonGroup>
                        <Button
                          secondary
                          onClick={() => handleDuplicated(service)}
                          disabled={!isValid}
                        >
                          <FontAwesomeIcon icon={faCopy} /> Duplicate
                        </Button>
                        <Button
                          secondary
                          onClick={() =>
                            emailServices(
                              groupId,
                              defaultLocationId,
                              [serviceId],
                              servicesById
                            )
                          }
                          disabled={!isValid}
                        >
                          <FontAwesomeIcon icon={faPaperPlane} /> Share
                        </Button>
                      </ButtonGroup>
                    </div>
                    <EditService
                      service={service}
                      onChange={(update) => handleChanged(serviceId, update)}
                      onDelete={() => handleDeleted(serviceId)}
                      onValidityChange={handleValidityChanged}
                    />
                  </>
                );
              }}
            />
          )}
        </Scrollable>
      </Wrapper>
    </div>
  );
};
export default Services;

export const isServiceStale = (service: IService) => {
  const { lastEdited, isHidden } = service;
  if (isHidden) return false;
  if (!lastEdited) return true;
  const minFreshDate = new Date();
  minFreshDate.setMonth(minFreshDate.getMonth() - 3);
  return lastEdited < minFreshDate;
};

/**
 * Build email of services => dynamic links
 */
export const useEmailServices = () => {
  const { buildLink } = useDynamicLinks();
  const emailServices = async (
    groupId: string,
    locationId: string,
    serviceIds: ReadonlyArray<string>,
    servicesById: Record<string, IService>
  ) => {
    try {
      const linkRequests = serviceIds.map((id) => {
        return buildLink(
          `/groups/${groupId}/locations/${locationId}/services/${id}`
        );
      });
      const links = await Promise.all(linkRequests);
      const lines: Array<string> = [];
      serviceIds.forEach((id, i) => {
        const service = servicesById[id];
        lines.push(service.name + " - " + links[i]);
      });
      const subject = encodeURIComponent(
        "Your Local Services from Help @ Hand"
      );
      const body = encodeURIComponent("\n\n" + lines.join("\n"));
      window.open(`mailto:?subject=${subject}&body=${body}`);
    } catch (e) {
      alert("Something went wrong. Please try again later.");
      console.error(e);
    }
  };
  return emailServices;
};
