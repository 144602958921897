import {
  faChevronDown,
  faPlus,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { collection, doc, writeBatch } from "firebase/firestore";
import { FC, useContext, useState } from "react";
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { firestore } from "_shared/firebase";
import { useUrl } from "_shared/hooks";
import { IS_DEV, localeSort } from "_shared/utils";
import Button from "_shared/components/Button";
import EditShelfService from "./EditShelfService";
import { useShelfServicesQuery } from "../groups/helpshelf/HelpShelf.hooks";
import ListTile from "_shared/components/ListTile";
import PublicHeader from "../public/PublicHeader";
import Scrollable from "_shared/components/Scrollable";
import {
  ServicesSidebarLayout,
  ServicesSidebarStickyFooter,
} from "../groups/services/ServicesSidebar";
import Wrapper from "_shared/components/Wrapper";
import Text from "_shared/components/Text";
import "_shared/css/ShelfServiceManagement.css";
import { UserContext } from "_shared/components/Auth";
import { omit } from "lodash";

const ShelfServiceManagement: FC = () => {
  const url = useUrl();
  const { path } = useRouteMatch();
  const history = useHistory();
  const user = useContext(UserContext);
  const [servicesById, paginationState, loadMore] = useShelfServicesQuery({
    userUid: user.uid,
  });
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isCreating, setIsCreating] = useState<boolean>(false);

  const handleValidityChange = (isNowValid: boolean) => {
    setIsValid(isNowValid);
  };
  const handleCreateClick = async () => {
    setIsCreating(true);
    const { id: newServiceId } = doc(collection(firestore, "services"));
    history.push(`${url}/${newServiceId}`);
  };

  const servicesByIdEntries = Object.entries(servicesById ?? {});
  const count = servicesByIdEntries.length;
  const paginationMessage = `Showing ${count} of ${
    paginationState === "IsLastPage" ? count : "many"
  } services`;

  return (
    <div className="shelf-service-management">
      <PublicHeader />
      <div className="shelf-service-management__header">
        <Wrapper wide pad>
          <Text variant="h1">Services</Text>
          <Button secondary onClick={handleCreateClick} disabled={isCreating}>
            <FontAwesomeIcon icon={faPlus} /> Create service
          </Button>
          <UploadJsonButton />
        </Wrapper>
      </div>
      <Wrapper wide className="shelf-service-management__main">
        <ServicesSidebarLayout
          disabled={!isValid}
          filters={
            <>
              <small className="shelf-service-management__pagination-message">
                {paginationMessage}
              </small>
            </>
          }
          list={
            <>
              {servicesByIdEntries
                .sort(([, { name: aName }], [, { name: bName }]) =>
                  localeSort(aName, bName)
                )
                .map(([id, { name }]) => {
                  return (
                    <NavLink key={id} to={`${url}/${id}`}>
                      <ListTile title={name} />
                    </NavLink>
                  );
                })}
              {paginationState !== "IsLastPage" && (
                <ServicesSidebarStickyFooter>
                  <Button
                    secondary
                    onClick={loadMore}
                    disabled={paginationState === "Loading"}
                  >
                    {paginationState === "Loading" ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faChevronDown} /> Load more
                      </>
                    )}
                  </Button>
                </ServicesSidebarStickyFooter>
              )}
            </>
          }
        />
        <Scrollable className="shelf-service-management__detail">
          <Wrapper pad>
            <Switch>
              <Route
                path={`${path}/:serviceId`}
                render={({ match }) => {
                  const { serviceId } = match.params;
                  return (
                    <>
                      <EditShelfService
                        serviceId={serviceId}
                        onValidityChange={handleValidityChange}
                        isCreating={isCreating}
                        onSave={() => setIsCreating(false)}
                      />
                    </>
                  );
                }}
              />
            </Switch>
          </Wrapper>
        </Scrollable>
      </Wrapper>
    </div>
  );
};

export default ShelfServiceManagement;

const UploadJsonButton: FC = () => {
  const user = useContext(UserContext);

  const handleUploadJsonClick = async () => {
    const text = window.prompt("Paste JSON array of services");
    try {
      if (!text) throw new Error("Cannot parse empty JSON");
      const json = JSON.parse(text);
      if (!Array.isArray(json))
        throw new Error("Expected JSON to contain an array");
      const services = json.map((service) => {
        if (!service.name) throw new Error("Service is missing name");
        const censored = omit(
          service,
          "_globalId",
          "_hiddenAfterUpdate",
          "isHidden"
        );
        return {
          ...censored,
          tags: Array.from(service.tags),
          lastEdited: service.lastEdited
            ? new Date(service.lastEdited)
            : new Date(),
          users: [user.uid],
          _userProfiles: {
            [user.uid]: {
              email: user.email,
            },
          },
        };
      });
      const batch = writeBatch(firestore);
      for (const service of services) {
        const newServiceRef = doc(collection(firestore, "services"));
        batch.set(newServiceRef, service);
      }
      await batch.commit();
      window.alert("Done. Please refresh.");
    } catch (e) {
      window.console.error(e);
      window.alert("Failed");
    }
  };

  if (!IS_DEV || !user.isAdmin) return null;

  return (
    <Button secondary kind="danger" onClick={handleUploadJsonClick}>
      Upload JSON
    </Button>
  );
};
