import { FC, ReactElement, useEffect } from "react";

interface UnloadPromptProps {
  children?: ReactElement;
  onBeforeUnload?: () => void;
  when?: boolean;
}

/**
 * Calls onBeforeUnload when the user is about to leave the page.
 * If onBeforeUnload returns false, navigation is cancelled.
 */
const UnloadPrompt: FC<UnloadPromptProps> = ({
  when = false,
  onBeforeUnload,
  children = null,
}) => {
  useEffect(() => {
    const beforeUnload = (e: BeforeUnloadEvent) => {
      if (when !== true) return;
      if (onBeforeUnload) onBeforeUnload();
      e.preventDefault();
      e.returnValue = ""; //Chrome requires this to be set
    };
    window.addEventListener("beforeunload", beforeUnload);
    return () => window.removeEventListener("beforeunload", beforeUnload);
  }, [when, onBeforeUnload]);

  return children;
};

export default UnloadPrompt;
