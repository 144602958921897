import { FC, useContext, useState } from "react";
import Button, { ButtonGroup } from "./Button";
import { GroupContext } from "../../groups/Group";
import ListTile from "./ListTile";
import { Modal, ModalFooter, ModalHeader, ModalTitle } from "./Modal";
import PreviewService from "../../groups/services/PreviewService";
import Scrollable from "./Scrollable";
import { ServicesSidebarWithAllFilters } from "../../groups/services/ServicesSidebar";

interface ChooseServiceModalProps {
  onChoose: (serviceId: string) => void;
  onClose: () => void;
}

const ChooseServiceModal: FC<ChooseServiceModalProps> = ({
  onChoose,
  onClose,
}) => {
  const group = useContext(GroupContext);
  const servicesById = group?.services ?? {};
  const [currentServiceId, setCurrentServiceId] = useState<string>();
  const currentService = currentServiceId && servicesById[currentServiceId];

  const onChoosePressed = () => {
    onChoose(currentServiceId!);
    onClose();
  };

  return (
    <Modal onClose={onClose}>
      <ModalHeader>
        <ModalTitle>Choose a service</ModalTitle>
      </ModalHeader>
      <div className="choose-services">
        <ServicesSidebarWithAllFilters
          servicesById={servicesById}
          renderService={(service, id) => (
            <a
              key={id}
              href="/"
              aria-current={id === currentServiceId ? "page" : undefined}
              onClick={(e) => {
                e.preventDefault();
                setCurrentServiceId(id);
              }}
            >
              <ListTile title={service.name} />
            </a>
          )}
        />
        <Scrollable className="choose-services__detail">
          {currentService && <PreviewService service={currentService} />}
        </Scrollable>
      </div>
      <ModalFooter>
        <ButtonGroup>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={onChoosePressed} disabled={!currentService}>
            Choose
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};
export default ChooseServiceModal;
