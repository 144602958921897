import { User } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { omit } from "lodash";
import { convertToFirestore, firestore, functions, storage } from "./firebase";
import { IGroup } from "./models/Group";
import { ILocation } from "./models/Location";
import { convertServiceFromFirestore, IService } from "./models/Service";

export async function createGroup(
  group: IGroup & { name: string },
  user: User
) {
  const { uid, email } = user;
  // Only non-null values
  const pathsToDelete: Array<string> = [];
  const converted = convertToFirestore(group, (nullPath) =>
    pathsToDelete.push(nullPath)
  );
  const data = omit(
    converted,
    "address",
    "locations",
    "versionIds",
    ...pathsToDelete
  );
  const ref = await addDoc(collection(firestore, "groups"), {
    ...data,
    //Grant access only to the current user
    users: [uid],
    _userProfiles: {
      [uid]: { email },
    },
  });
  return ref.id;
}

export async function revertGroupToVersion(
  groupId: string,
  oldVersionId: string
) {
  //Copy old version into group
  const path = `groups/${groupId}/versions/${oldVersionId}`;
  const oldVersionSnap = await getDoc(doc(firestore, path));
  if (!oldVersionSnap.exists())
    throw new Error(`Failed to fetch ${path}, the document does not exist.`);
  const oldVersion = oldVersionSnap.data();
  //Support old versions from when services were stored as an array
  let services: Record<string, IService> = {};
  if (Array.isArray(oldVersion.services)) {
    oldVersion.services.forEach((service) => {
      const { id, ...remainder } = service;
      services[id] = remainder;
    });
  } else {
    services = oldVersion.services;
  }
  await updateDoc(doc(firestore, `groups/${groupId}`), { services });
}

// TODO: at the moment this gets _any_ location. In the future, we want to guarantee
// that a group has at least one default location, for example at `/locations/default`
export async function getDefaultLocation(
  groupId: string
): Promise<[id: string, location: ILocation]> {
  const snap = await getDocs(
    query(collection(firestore, `groups/${groupId}/locations`), limit(1))
  );
  if (snap.empty) throw new Error(`No locations found in group: ${groupId}`);
  const location = snap.docs[0].data();
  if ("services" in location) {
    location.services = location.services.map(convertServiceFromFirestore);
  }
  return [snap.docs[0].id, location];
}

export async function uploadImage(
  path: string,
  file: File,
  onProgress: (progress: number) => void
) {
  const upload = uploadBytesResumable(ref(storage, path), file);
  return new Promise((resolve, reject) => {
    upload.on(
      "state_changed",
      (snapshot) => {
        //Progress
        onProgress(snapshot.bytesTransferred / snapshot.totalBytes);
      },
      (error) => {
        //Error
        console.error(error);
        reject(null);
      },
      async () => {
        //Complete
        const downloadURL = await getDownloadURL(upload.snapshot.ref);
        resolve(downloadURL);
      }
    );
  });
}

export const generateVersionId = () => {
  //Store versions in hour intervals
  const now = new Date();
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours()
  ).toISOString();
};

export const sendNotification = httpsCallable(functions, "sendNotification");

export const setUserPermissionsOnGroup = httpsCallable(
  functions,
  "setUserPermissionsOnGroup"
);

export const setUserPermissionsOnService = httpsCallable(
  functions,
  "setUserPermissionsOnService"
);

export const setup = httpsCallable(functions, "setup");

export const fetchAnalytics = httpsCallable(functions, "fetchAnalytics");
