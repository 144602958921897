import { NavLink, Route, useHistory, useRouteMatch } from "react-router-dom";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import "_shared/css/HelpShelf.css";
import {
  ServicesSidebarLayout,
  ServicesSidebarStickyFooter,
} from "../services/ServicesSidebar";
import Scrollable from "_shared/components/Scrollable";
import Button from "_shared/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faChevronDown, faSpinner } from "@fortawesome/free-solid-svg-icons";
import TagSelect, {
  useDemographicsServiceFilters,
} from "_shared/components/TagSelect";
import { Label } from "_shared/components/Field";
import { useUrl } from "_shared/hooks";
import { localeSort } from "_shared/utils";
import ListTile from "_shared/components/ListTile";
import { useShelfServicesQuery } from "./HelpShelf.hooks";
import { IService } from "_shared/models/Service";
import { omit } from "lodash";
import ShelfService from "./ShelfService";

const HelpShelf = () => {
  const { path } = useRouteMatch();
  const url = useUrl();
  const history = useHistory();
  const [filterTag, setFilterTag] = useState<string | undefined>();
  const [demographicsFilterOptionGroups] = useDemographicsServiceFilters();
  const [shelfServicesById, paginationState, loadMore] = useShelfServicesQuery({
    tag: filterTag,
  });
  const servicesById: Record<string, IService> = {};
  Object.entries(shelfServicesById).forEach(([id, shelfService]) => {
    servicesById[id] = omit(shelfService, "users", "_userProfiles");
  });

  const handleFilterTagsChanged = (tags: Set<string>) => {
    // isMulti === false, so this will either be a set of 1 or an empty set
    setFilterTag(undefined);
    tags.forEach((tag) => setFilterTag(tag));
    history.replace(url);
  };

  const servicesByIdEntries = Object.entries(servicesById);
  const count = servicesByIdEntries.length;
  const paginationMessage = `Showing ${count} of ${
    paginationState === "IsLastPage" ? count : "many"
  } matching services`;

  return (
    <div className="helpshelf">
      <header className="helpshelf__header">
        <Wrapper pad wide>
          <Text variant="h1" className="helpshelf__title">
            HelpShelf
          </Text>
        </Wrapper>
      </header>
      <Wrapper wide className="helpshelf__main">
        <ServicesSidebarLayout
          filters={
            <>
              <div>
                <Label id="services-tag-filter" strong>
                  Filter
                </Label>
                <TagSelect
                  aria-labelledby="services-tag-filter"
                  value={filterTag ? new Set([filterTag]) : undefined}
                  optionGroups={demographicsFilterOptionGroups}
                  onChange={handleFilterTagsChanged}
                  isMulti={false}
                />
              </div>
              <small className="helpshelf__pagination-message">
                {paginationMessage}
              </small>
            </>
          }
          list={
            <>
              {servicesByIdEntries
                .sort(([, { name: aName }], [, { name: bName }]) =>
                  localeSort(aName, bName)
                )
                .map(([id, { name }]) => {
                  return (
                    <NavLink key={id} to={`${url}/${id}`}>
                      <ListTile title={name} />
                    </NavLink>
                  );
                })}
              {paginationState !== "IsLastPage" && (
                <ServicesSidebarStickyFooter>
                  <Button
                    secondary
                    onClick={loadMore}
                    disabled={paginationState === "Loading"}
                  >
                    {paginationState === "Loading" ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} spin /> Loading...
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faChevronDown} /> Load more
                      </>
                    )}
                  </Button>
                </ServicesSidebarStickyFooter>
              )}
            </>
          }
        />
        <Scrollable className="helpshelf__detail">
          <Wrapper pad>
            <Route path={`${path}/:serviceId`}>
              <ShelfService />
            </Route>
          </Wrapper>
        </Scrollable>
      </Wrapper>
    </div>
  );
};

export default HelpShelf;
