import { FC } from "react";
import Text from "_shared/components/Text";
import { TextField, OutputField } from "_shared/components/Field";
import { Nullable } from "_shared/utils";
import {
  IPatientEvent,
  calculateBmi,
  toFeetAndInches,
  toStoneAndPounds,
} from "_shared/models/Patient";

interface BodyQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const BodyQuestionnaire: FC<BodyQuestionnaireProps> = ({
  event,
  onChange,
}) => {
  const { height, weight } = event;

  return (
    <>
      <hr />
      <Text variant="h2" id="body">
        Body
      </Text>
      <TextField
        type="number"
        label="Height / m"
        id="height"
        value={height?.toString() ?? ""}
        min="0"
        step="any"
        onChange={(e) =>
          onChange({
            height: e.target.value ? parseFloat(e.target.value) : null,
          })
        }
        trailing={
          <output htmlFor="height">({toFeetAndInches(height || 0)})</output>
        }
      />
      <TextField
        type="number"
        label="Weight / kg"
        id="weight"
        value={weight?.toString() ?? ""}
        min="0"
        step="any"
        onChange={(e) =>
          onChange({
            weight: e.target.value ? parseFloat(e.target.value) : null,
          })
        }
        trailing={
          <output htmlFor="weight">({toStoneAndPounds(weight || 0)})</output>
        }
      />
      <OutputField label="BMI" htmlFor="height weight">
        {height && weight ? calculateBmi(height, weight) : ""}
      </OutputField>
    </>
  );
};
