import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "_shared/firebase";
import {
  convertServiceFromFirestore,
  convertShelfServiceFromFirestore,
  IShelfService,
} from "_shared/models/Service";

type PaginationState = "Loading" | "HasNextPage" | "IsLastPage";

interface CommonVariables {
  pageSize?: number;
}

interface TagVariables extends CommonVariables {
  tag?: string;
  userUid?: never;
}

interface UserVariables extends CommonVariables {
  tag?: never;
  userUid?: string;
}

type ShelfServicesQueryVariables = TagVariables | UserVariables;

export const useShelfServicesQuery = ({
  tag,
  userUid,
  pageSize = 10,
}: ShelfServicesQueryVariables): [
  servicesById: Record<string, IShelfService>,
  paginationState: PaginationState,
  loadMore: () => void
] => {
  const [servicesById, setServicesById] = useState<
    Record<string, IShelfService>
  >({});
  const [lastInPage, setLastInPage] = useState<QueryDocumentSnapshot | null>(
    null
  );
  const [paginationState, setPaginationState] =
    useState<PaginationState>("Loading");

  // Reset when tag changes
  useEffect(() => {
    setServicesById({});
    setPaginationState("Loading");
  }, [tag]);

  // Query services
  useEffect(() => {
    let isStale = false;
    const fetch = async () => {
      const constraints: Array<QueryConstraint> = [
        orderBy("name"),
        startAfter(lastInPage),
        // We fetch 1 extra doc to detect when no more exist.
        limit(pageSize + 1),
      ];
      /**
       * Due to Firestore query limitations, we cannot combine 2 array-contains constraints.
       * We also cannot use a constraint like where(`users.${uid}.hasAccess`, '==', true) as
       * this necessitates a composite index for each `uid` (and there's a limit of 200).
       */
      if (tag) {
        constraints.push(where("tags", "array-contains", tag));
      } else if (userUid) {
        constraints.push(where("users", "array-contains", userUid));
      }
      const { docs } = await getDocs(
        query(collection(firestore, "services"), ...constraints)
      );
      // query variables may have changed while we waited for a response.
      // In this case the query will be re-run in a new effect and we can discard this result.
      if (isStale) return;
      const page = docs.slice(0, pageSize);
      const newServicesById = { ...servicesById };
      for (const doc of page) {
        newServicesById[doc.id] = convertShelfServiceFromFirestore(doc.data());
      }
      setServicesById(newServicesById);
      setLastInPage(page[page.length - 1] ?? null);
      setPaginationState(docs.length > pageSize ? "HasNextPage" : "IsLastPage");
    };
    if (paginationState === "Loading") fetch();
    return () => {
      // Mark the query as stale if any inputs to the effect changed
      isStale = true;
    };
  }, [tag, lastInPage, servicesById, paginationState, pageSize, userUid]);

  const loadMore = () => {
    if (paginationState !== "HasNextPage") return;
    setPaginationState("Loading");
  };

  return [servicesById, paginationState, loadMore];
};

export const useShelfServiceQuery = (
  serviceId: string
): IShelfService | undefined | null => {
  const [service, setService] = useState<IShelfService | undefined | null>();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `/services/${serviceId}`),
      (doc) => {
        if (!doc.exists()) {
          setService(null);
        } else {
          setService(convertServiceFromFirestore(doc.data()) as IShelfService);
        }
      },
      (e) => {
        console.error(e);
        setService(null);
      }
    );
    return unsubscribe;
  }, [serviceId]);

  return service;
};
