import { FC, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import Button, { ButtonGroup } from "_shared/components/Button";
import {
  DebouncedTextField,
  Field,
  Label,
  SearchableSelect,
  TextareaField,
  TextField,
} from "_shared/components/Field";
import { InfoIcon } from "_shared/components/Icons";
import "_shared/css/Events.css";
import { GroupContext } from "../Group";
import { useId } from "_shared/hooks";
import { IPatientEvent } from "_shared/models/Patient";
import Accordion, { AccordionGroup } from "_shared/components/Accordion";
import Wrapper from "_shared/components/Wrapper";
import SideNav, { SideNavLink } from "_shared/components/SideNav";
import { faMinusCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { formatDate, Nullable } from "_shared/utils";
import Text from "_shared/components/Text";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { BodyQuestionnaire } from "./consultations/BodyQuestionnaire";
import { EmploymentQuestionnaire } from "./consultations/EmploymentQuestionnaire";
import { LifestyleQuestionnaire } from "./consultations/LifestyleQuestionnaire";
import { MycawQuestionnaire } from "./consultations/MycawQuestionnaire";
import { WarwickEdinburghQuestionnaire } from "./consultations/WarwickEdinburghQuestionnaire";
import { Ons4Questionnaire } from "./consultations/Ons4Questionnaire";
import { LonelinessQuestionnaire } from "./consultations/LonelinessQuestionnaire";
import { KidscreenQuestionnaire } from "./consultations/KidscreenQuestionnaire";

interface EventsProps {
  eventsById: Record<string, IPatientEvent>;
  onCreate: (event: { date: string }) => void;
  onUpdate: (id: string, update: Nullable<Partial<IPatientEvent>>) => void;
  onDelete: (id: string) => void;
  isOuterFormValid?: boolean;
  isKidscreenAvailable?: boolean;
}

const Events: FC<EventsProps> = ({
  eventsById,
  onCreate,
  onUpdate,
  onDelete,
  isOuterFormValid = true,
  isKidscreenAvailable = true,
  ...otherProps
}) => {
  //Convert map of id => event to array sorted by date
  const eventsOldestFirst = Object.entries(eventsById || {})
    .map(([id, event]) => {
      return { ...event, id };
    })
    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date)); //Sort by earliest date first

  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const group = useContext(GroupContext);
  const typeNamesById = group?.patientEvents;
  const firstMycawEvent = eventsOldestFirst.find(
    (event) => event.mycaw !== undefined
  );
  const onAddPressed = () => {
    onCreate({
      date: new Date().toLocaleDateString("en-CA"),
    });
  };
  const onDeletePressed = (eventId: string) => {
    if (
      !window.confirm(
        `This event, including all notes and wellbeing data will be deleted`
      )
    )
      return;
    onDelete(eventId);
  };

  if (!typeNamesById || Object.keys(typeNamesById).length === 0)
    return (
      <Field wide {...otherProps}>
        <p>
          Events must be configured in{" "}
          <Link to={`/groups/${groupId}/settings`}>Settings</Link>
        </p>
      </Field>
    );

  return (
    <div className="events" {...otherProps}>
      <div className="flex flex-col items-start gap-4">
        <Button onClick={onAddPressed}>
          <FontAwesomeIcon icon={faPlus} /> Add event
        </Button>
        <AccordionGroup>
          {eventsOldestFirst.reverse().map(({ id, ...event }) => {
            const { date, type, duration } = event;
            const name =
              type && typeNamesById[type] ? typeNamesById[type] : "New Event";
            const hasDate = date && !isNaN(new Date(date).getTime());
            return (
              <Accordion
                key={id}
                className="events__event"
                disabled={!isOuterFormValid}
                summary={
                  <>
                    {hasDate ? (
                      <small className="events__timing">
                        {formatDate(new Date(date))}
                        {duration
                          ? ` (${duration} minute${duration === 1 ? "" : "s"})`
                          : null}
                      </small>
                    ) : null}
                    <Text variant="h3" className="events__name">
                      {name}
                    </Text>
                  </>
                }
              >
                <Event
                  event={event}
                  onChange={(update) => onUpdate(id, update)}
                  onDelete={() => onDeletePressed(id)}
                  isKidscreenAvailable={isKidscreenAvailable}
                  firstMycawEvent={firstMycawEvent}
                />
              </Accordion>
            );
          })}
        </AccordionGroup>
      </div>
    </div>
  );
};

export default Events;

interface EventProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
  onDelete: () => void;
  isKidscreenAvailable?: boolean;
  firstMycawEvent?: IPatientEvent;
}

type QuestionnaireId =
  | "body"
  | "employment"
  | "lifestyle"
  | "mycaw"
  | "warwickEdinburgh"
  | "ons4"
  | "loneliness"
  | "kidscreen";

const Event: FC<EventProps> = ({
  event,
  onChange,
  onDelete,
  isKidscreenAvailable,
  firstMycawEvent,
}) => {
  const { date, type, tags, notes, duration } = event;

  const { isMycawEnabled } = useFeatureFlags();
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const group = useContext(GroupContext);
  const typeNamesById = group?.patientEvents ?? {};
  const tagNamesById = group?.patientEventTags ?? {};
  const name = type && typeNamesById[type] ? typeNamesById[type] : "New Event";
  const elementId = useId();
  const [showBody, setShowBody] = useState(event.height !== undefined);
  const [showEmployment, setShowEmployment] = useState(
    event.employment !== undefined
  );
  const [showLifestyle, setShowLifestyle] = useState(
    event.alcohol !== undefined
  );
  const questionnaireVisibility: Partial<Record<QuestionnaireId, boolean>> = {
    body: showBody,
    employment: showEmployment,
    lifestyle: showLifestyle,
    warwickEdinburgh: event.warwickEdinburgh !== undefined,
    ons4: event.ons4 !== undefined,
    loneliness: event.loneliness !== undefined,
  };
  if (isMycawEnabled) questionnaireVisibility.mycaw = event.mycaw !== undefined;
  if (isKidscreenAvailable) {
    questionnaireVisibility.kidscreen = event.kidscreen !== undefined;
  }
  const isAnyQuestionnaireHidden = Object.values(questionnaireVisibility).some(
    (visibility) => visibility === false
  );

  return (
    <div className="event">
      <Wrapper className="event__main">
        <Field>
          <Text variant="h2" id="event">
            Event
          </Text>
          <Label strong id={`${elementId}_type`}>
            Type
          </Label>
          <SearchableSelect
            aria-labelledby={`${elementId}_type`}
            options={Object.entries(typeNamesById)
              .map(([value, label]) => {
                return { value, label };
              })
              .sort((a, b) => a.label.localeCompare(b.label))}
            value={{ value: type, label: name }}
            onChange={(option) => onChange({ type: option?.value })}
            autoFocus={!type}
            aria-label="Event type"
            placeholder=""
            menuPlacement="auto"
          />
        </Field>
        <Field>
          <Label strong id={`${elementId}_tags`}>
            Tags
          </Label>
          {tagNamesById && Object.keys(tagNamesById).length > 0 ? (
            <SearchableSelect
              aria-labelledby={`${elementId}_tags`}
              options={Object.entries(tagNamesById)
                .map(([value, label]) => {
                  return { value, label };
                })
                .sort((a, b) => a.label.localeCompare(b.label))}
              value={Array.from(tags ?? []).map((id) => {
                return { value: id, label: tagNamesById[id] };
              })}
              onChange={(options) =>
                onChange({
                  tags: new Set(options.map(({ value }) => value)),
                })
              }
              placeholder=""
              menuPlacement="auto"
              isMulti
            />
          ) : (
            <em>
              You can configure event tags in{" "}
              <Link to={`/groups/${groupId}/settings/patients`}>Settings</Link>
            </em>
          )}
        </Field>
        <DebouncedTextField // Events are sorted by date. Debouncing stops them moving as the user types
          type="date"
          label="Date"
          value={date || ""}
          onChange={(e) => onChange({ date: e.target.value })}
          max={new Date().toLocaleDateString("en-CA")}
          required
        />
        <TextField
          type="number"
          label={
            <>
              Duration / minutes
              <Tippy content={<span>Log the length of consultations</span>}>
                <InfoIcon />
              </Tippy>
            </>
          }
          min="1"
          step="1"
          value={duration?.toString() || ""}
          onChange={(e) =>
            onChange({
              duration: e.target.value ? parseInt(e.target.value) : null,
            })
          }
        />
        <TextareaField
          label="Notes"
          className="event__notes-input"
          rows={5}
          value={notes || ""}
          onChange={(e) => onChange({ notes: e.target.value })}
        />
        <Field>
          <Button secondary kind="danger" onClick={onDelete}>
            <FontAwesomeIcon icon={faMinusCircle} /> Delete event
          </Button>
        </Field>
        {questionnaireVisibility.body ? (
          <BodyQuestionnaire event={event} onChange={onChange} />
        ) : null}
        {questionnaireVisibility.employment ? (
          <EmploymentQuestionnaire event={event} onChange={onChange} />
        ) : null}
        {questionnaireVisibility.lifestyle ? (
          <LifestyleQuestionnaire event={event} onChange={onChange} />
        ) : null}
        {questionnaireVisibility.mycaw ? (
          <MycawQuestionnaire
            event={event}
            onChange={onChange}
            firstMycawEvent={firstMycawEvent}
          />
        ) : null}
        {questionnaireVisibility.warwickEdinburgh ? (
          <WarwickEdinburghQuestionnaire event={event} onChange={onChange} />
        ) : null}
        {questionnaireVisibility.ons4 ? (
          <Ons4Questionnaire event={event} onChange={onChange} />
        ) : null}
        {questionnaireVisibility.loneliness ? (
          <LonelinessQuestionnaire event={event} onChange={onChange} />
        ) : null}
        {questionnaireVisibility.kidscreen ? (
          <KidscreenQuestionnaire event={event} onChange={onChange} />
        ) : null}
        {isAnyQuestionnaireHidden ? (
          <>
            <hr />
            <div className="flex flex-col gap-8">
              <Text variant="h2" id="body">
                Add questionnaire...
              </Text>
              <ButtonGroup>
                {questionnaireVisibility.body === false ? (
                  <Button secondary onClick={() => setShowBody(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                    Body
                  </Button>
                ) : null}
                {questionnaireVisibility.employment === false ? (
                  <Button secondary onClick={() => setShowEmployment(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                    Employment/Housing
                  </Button>
                ) : null}
                {questionnaireVisibility.lifestyle === false ? (
                  <Button secondary onClick={() => setShowLifestyle(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                    Lifestyle
                  </Button>
                ) : null}
                {questionnaireVisibility.mycaw === false ? (
                  <Button secondary onClick={() => onChange({ mycaw: {} })}>
                    <FontAwesomeIcon icon={faPlus} />
                    MYCaW
                  </Button>
                ) : null}
                {questionnaireVisibility.warwickEdinburgh === false ? (
                  <Button
                    secondary
                    onClick={() => onChange({ warwickEdinburgh: {} })}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Warwick-Edinburgh
                  </Button>
                ) : null}
                {questionnaireVisibility.ons4 === false ? (
                  <Button secondary onClick={() => onChange({ ons4: {} })}>
                    <FontAwesomeIcon icon={faPlus} />
                    ONS4
                  </Button>
                ) : null}
                {questionnaireVisibility.loneliness === false ? (
                  <Button
                    secondary
                    onClick={() => onChange({ loneliness: {} })}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Loneliness
                  </Button>
                ) : null}
                {questionnaireVisibility.kidscreen === false ? (
                  <Button secondary onClick={() => onChange({ kidscreen: {} })}>
                    <FontAwesomeIcon icon={faPlus} />
                    KidScreen
                  </Button>
                ) : null}
              </ButtonGroup>
            </div>
          </>
        ) : null}
      </Wrapper>
      <SideNav className="event__side-nav">
        <SideNavLink href="#event">Event</SideNavLink>
        {questionnaireVisibility.body ? (
          <SideNavLink href="#body">Body</SideNavLink>
        ) : null}
        {questionnaireVisibility.employment ? (
          <SideNavLink href="#employment-housing">
            Employment/Housing
          </SideNavLink>
        ) : null}
        {questionnaireVisibility.lifestyle ? (
          <SideNavLink href="#lifestyle">Lifestyle</SideNavLink>
        ) : null}
        {questionnaireVisibility.mycaw ? (
          <SideNavLink href="#mycaw">MYCaW</SideNavLink>
        ) : null}
        {questionnaireVisibility.warwickEdinburgh ? (
          <SideNavLink href="#warwick-edinburgh">Warwick-Edinburgh</SideNavLink>
        ) : null}
        {questionnaireVisibility.ons4 ? (
          <SideNavLink href="#ons4">ONS4</SideNavLink>
        ) : null}
        {questionnaireVisibility.loneliness ? (
          <SideNavLink href="#loneliness">Loneliness</SideNavLink>
        ) : null}
        {questionnaireVisibility.kidscreen ? (
          <SideNavLink href="#kidscreen">Kidscreen</SideNavLink>
        ) : null}
      </SideNav>
    </div>
  );
};
