import {
  ChangeEvent,
  FC,
  RefCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Field, Input, Label, StringInputProps } from "./Field";
import "_shared/css/AddressSearchInput.css";
import { useId } from "_shared/hooks";

interface CommonProps
  extends Pick<StringInputProps, "className" | "id" | "required" | "value"> {}

interface NullableProps extends CommonProps {
  requireLatLng?: false;
  onChange: (addressData: {
    address: string;
    postcode: string | null;
    lat: number | null;
    lng: number | null;
  }) => void;
}

interface NonNullableProps extends CommonProps {
  /**
   * When true, {@link LatLngRequiredProps.onChange} is only called for recognised locations, with
   * guaranteed co-ordinates, and the input is marked as invalid for any unrecognised locations.
   */
  requireLatLng: true;
  onChange: (addressData: {
    address: string;
    postcode: string | null;
    lat: number;
    lng: number;
  }) => void;
}

type AddressSearchInputProps = NullableProps | NonNullableProps;

const AddressSearchInput: FC<AddressSearchInputProps> = (props) => {
  const { value, onChange, requireLatLng, ...otherProps } = props;
  const [inputValue, setInputValue] = useState<string>(value);
  const [isFocused, setIsFocused] = useState(false);
  const autocomplete = useRef<google.maps.places.Autocomplete>();

  const inputRef: RefCallback<HTMLInputElement> = useCallback(async (input) => {
    if (!input) return;
    autocomplete.current = new google.maps.places.Autocomplete(input, {
      componentRestrictions: {
        country: "gb",
      },
      fields: ["address_components", "formatted_address", "geometry"],
    });
  }, []);

  useEffect(() => {
    const onPlaceSelected = () => {
      if (!autocomplete.current) return;
      const { formatted_address, address_components, geometry } =
        autocomplete.current.getPlace();
      if (geometry?.location?.lat() && geometry?.location?.lng()) {
        //Trigger update which includes location details: postcode, lat, lng
        onChange({
          address: formatted_address ?? "",
          postcode:
            (address_components || [])
              .filter(({ types }) => types.includes("postal_code"))
              .map((component) => component.long_name)
              .find((postcode) => postcode !== undefined) || null,
          lat: geometry.location.lat(),
          lng: geometry.location.lng(),
        });
      }
    };
    autocomplete.current?.addListener("place_changed", onPlaceSelected);
    return () => {
      if (!autocomplete.current) return;
      google.maps.event.clearInstanceListeners(autocomplete.current);
    };
  }, [onChange]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (requireLatLng) return;
    onChange({
      address: e.target.value,
      postcode: null,
      lng: null,
      lat: null,
    });
  };

  return (
    <Input
      type="text"
      ref={inputRef}
      value={isFocused ? inputValue : value}
      onChange={handleInputChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => {
        setIsFocused(false);
        setInputValue(value);
      }}
      placeholder="Search for a UK address..."
      {...otherProps}
    />
  );
};

type AddressSearchFieldProps = AddressSearchInputProps & {
  label?: string;
};

export const AddressSearchField: FC<AddressSearchFieldProps> = ({
  label = "Address",
  ...otherProps
}) => {
  const id = useId();
  return (
    <Field className="address-search-field">
      <Label strong htmlFor={id}>
        {label}
      </Label>
      <AddressSearchInput
        id={id}
        className="address-search-field__input"
        {...otherProps}
      />
    </Field>
  );
};
