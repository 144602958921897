import { FC, useContext, useEffect, useRef, useState } from "react";
import {
  Prompt,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { nanoid } from "nanoid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinusCircle,
  faPencilAlt,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  faIdCardAlt,
  faLocationDot as falLocationDot,
  faUserCircle as falUserCircle,
  faUserFriends as falUserFriends,
} from "@fortawesome/pro-light-svg-icons";
import { doc, updateDoc } from "firebase/firestore";
import { auth, firestore } from "_shared/firebase";
import Button from "_shared/components/Button";
import ChooseGroup from "../ChooseGroup";
import { Field, Input, Label, TextField } from "_shared/components/Field";
import "_shared/css/Settings.css";
import UnloadPrompt from "_shared/components/UnloadPrompt";
import SaveState from "_shared/components/SaveState";
import Wrapper from "_shared/components/Wrapper";
import Tippy from "@tippyjs/react";
import { UserContext } from "_shared/components/Auth";
import { useUrl } from "_shared/hooks";
import Tab, { TabBar, TabIcon } from "_shared/components/Tab";
import { GroupContext } from "../Group";
import Scrollable from "_shared/components/Scrollable";
import { sendPasswordResetEmail, signOut } from "firebase/auth";
import Locations from "./locations/Locations";
import Location from "./locations/Location";
import { IGroup } from "_shared/models/Group";
import GroupUsers from "./GroupUsers";
import Text from "_shared/components/Text";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { InfoIcon } from "_shared/components/Icons";

const Settings = () => {
  const url = useUrl();
  const { path } = useRouteMatch();
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const [pendingEdit, setPendingEdit] = useState({});
  const [isLeaving, setIsLeaving] = useState(false);
  const group = { ...useContext(GroupContext), ...pendingEdit };
  const isSaved = Object.keys(pendingEdit).length === 0;
  const onChanged = (update: Partial<IGroup>) => {
    setPendingEdit({ ...pendingEdit, ...update });
  };
  useEffect(() => {
    if (isSaved) return;
    const save = async () => {
      await updateDoc(doc(firestore, `/groups/${groupId}`), pendingEdit);
      setPendingEdit({});
    };
    //If user has signalled intent to leave the page...
    if (isLeaving) {
      setIsLeaving(false);
      //Save immediately
      save();
    } else {
      //Otherwise save after debouncing for a few seconds
      const saveTimer = window.setTimeout(save, 6000);
      //Cleanup timer if relevant variables change before it executes
      return () => window.clearTimeout(saveTimer);
    }
  }, [isSaved, pendingEdit, isLeaving, groupId]);
  return (
    <Switch>
      <Route path={`${path}/locations/:locationId`}>
        <Location />
      </Route>
      <Route>
        <div className="settings">
          <UnloadPrompt
            when={!isSaved}
            onBeforeUnload={() => setIsLeaving(true)}
          />
          <Prompt
            message={(location) => {
              //Alert when navigating away from this page with unsaved changes
              if (!isSaved && !location.pathname.startsWith(url)) {
                setIsLeaving(true);
                return "Unsaved changes! If you leave now, your changes will not be saved.";
              }
              return true;
            }}
          />
          <header className="settings__header">
            <Wrapper pad className="settings__header-row">
              <Text variant="h1" className="settings__title">
                Settings
              </Text>
              <SaveState state={!isSaved ? "saving" : "saved"} />
            </Wrapper>
            <TabBar>
              <Tab to={`${url}/patients`}>
                <TabIcon icon={falUserFriends} />{" "}
                <LocalizedMessage id="patients_title" />
              </Tab>
              <Tab to={`${url}/locations`}>
                <TabIcon icon={falLocationDot} /> Locations
              </Tab>
              <Tab to={`${url}/users`}>
                <TabIcon icon={faIdCardAlt} /> Users
              </Tab>
              <Tab to={`${url}/account`}>
                <TabIcon icon={falUserCircle} /> Account
              </Tab>
            </TabBar>
          </header>
          <Scrollable className="settings__main">
            <Wrapper pad>
              <Switch>
                <Route path={path} exact>
                  <Redirect to={`${url}/patients`} />
                </Route>
                <Route path={`${path}/patients`}>
                  <Text variant="h2" id="status">
                    Organisation
                  </Text>
                  <Field wide>
                    <Label strong>
                      Tags
                      <Tippy
                        content={
                          <span>
                            Assign many tags to a patient. A 'dementia' tag can
                            help you generate reports.
                          </span>
                        }
                      >
                        <InfoIcon />
                      </Tippy>
                    </Label>
                    <EditTags
                      tagsById={group.patientFlags}
                      onChange={(patientFlags) => onChanged({ patientFlags })}
                    />
                  </Field>
                  <hr />
                  <Text variant="h2" id="history">
                    History
                  </Text>
                  <Field wide>
                    <Label strong>Events</Label>
                    <EditTags
                      tagsById={group.patientEvents}
                      onChange={(patientEvents) => onChanged({ patientEvents })}
                    />
                  </Field>
                  <Field wide>
                    <Label strong>Event tags</Label>
                    <EditTags
                      tagsById={group.patientEventTags}
                      onChange={(patientEventTags) =>
                        onChanged({ patientEventTags })
                      }
                    />
                  </Field>
                  <hr />
                  <Text variant="h2" id="referrals">
                    Referrals
                  </Text>
                  <Field wide>
                    <Label strong>Referrers</Label>
                    <EditTags
                      tagsById={group.patientReferrers}
                      onChange={(patientReferrers) =>
                        onChanged({ patientReferrers })
                      }
                    />
                  </Field>
                  <Field wide>
                    <Label strong>Reasons for referral</Label>
                    <EditTags
                      tagsById={group.patientReferralReasons}
                      onChange={(patientReferralReasons) =>
                        onChanged({ patientReferralReasons })
                      }
                    />
                  </Field>
                </Route>
                <Route path={`${path}/locations`}>
                  <Text variant="h2" id="locations">
                    Locations
                  </Text>
                  <Locations />
                </Route>
                <Route path={`${path}/users`}>
                  <GroupUsers group={group} />
                </Route>
                <Route path={`${path}/account`}>
                  <Text variant="h2" id="account">
                    Account
                  </Text>
                  <UserContext.Consumer>
                    {({ email }) => (
                      <>
                        <TextField
                          label="Email"
                          value={email ?? ""}
                          onChange={() => {}}
                          readOnly
                        />
                      </>
                    )}
                  </UserContext.Consumer>
                  <Field wide>
                    <ResetPasswordButton />
                  </Field>
                  <Field>
                    <Button secondary onClick={() => signOut(auth)}>
                      Sign out
                    </Button>
                  </Field>
                  <ChooseGroup />
                </Route>
              </Switch>
              <footer className="settings__footer">
                <Text variant="smallLight">© 2020 Akard Systems Limited</Text>
                <Text variant="smallLight">
                  <a
                    href="https://hand.community/privacy/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </Text>
              </footer>
            </Wrapper>
          </Scrollable>
        </div>
      </Route>
    </Switch>
  );
};
export default Settings;

interface EditTagsProps {
  onChange: (tagsById: Record<string, string>) => void;
  tagsById?: Record<string, string>;
}

const EditTags: FC<EditTagsProps> = ({ onChange, tagsById = {} }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [editingId, setEditingId] = useState<string | undefined>();
  const existingNamesLowerCase = new Set(
    Object.values(tagsById).map((name) => name.toLowerCase())
  );
  const validate = (name: string, id?: string) => {
    if (id && tagsById[id] && name.toLowerCase() === tagsById[id].toLowerCase())
      return true; //Allow the name of a tag to remain the same
    return !existingNamesLowerCase.has(name.toLowerCase()); //Otherwise name must be different to every other tag
  };
  const onAddPressed = () => {
    setIsAdding(true);
    setEditingId(undefined);
  };
  const onEditPressed = (id: string) => {
    setEditingId(id);
    setIsAdding(false);
  };
  // Because only one user is likely to edit settings at a time, we know all the tag IDs.
  // So, we can generate super-short new IDs and check for conflicts.
  const generateId: () => string = () => {
    const newId = nanoid(2);
    if (tagsById.hasOwnProperty(newId)) return generateId();
    return newId;
  };
  const onAdded = (name: string) => {
    setIsAdding(false);
    onChange({
      ...tagsById,
      [generateId()]: name,
    });
  };
  const onDeleted = (id: string, name: string) => {
    if (!window.confirm(`Delete '${name}'\n\n'${name}' will be gone forever`))
      return;
    const update = { ...tagsById };
    delete update[id];
    onChange(update);
  };
  return (
    <div className="edit-tags">
      {Object.entries(tagsById)
        .sort(([_, aName], [__, bName]) => {
          if (!aName) return 1;
          if (!bName) return -1;
          return aName.localeCompare(bName);
        })
        .map(([id, name]) => {
          return (
            <div className="edit-tags__tag" key={id}>
              {editingId === id ? (
                <TagDraft
                  defaultValue={name}
                  validateName={(name) => validate(name, id)}
                  onDone={(name) => {
                    onChange({ ...tagsById, [id]: name });
                    setEditingId(undefined);
                  }}
                  onCancel={() => setEditingId(undefined)}
                />
              ) : (
                <>
                  <span className="edit-tags__tag-name">{name}</span>
                  <Tippy content={<span>Edit{name && ` '${name}'`}</span>}>
                    <Button secondary onClick={() => onEditPressed(id)}>
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </Button>
                  </Tippy>
                  <Tippy content={<span>Delete{name && ` '${name}'`}</span>}>
                    <Button
                      secondary
                      kind="danger"
                      onClick={() => onDeleted(id, name)}
                    >
                      <FontAwesomeIcon icon={faMinusCircle} />
                    </Button>
                  </Tippy>
                </>
              )}
            </div>
          );
        })}
      <div className="edit-tags__new">
        {isAdding ? (
          <TagDraft
            validateName={(name) => validate(name)}
            onDone={onAdded}
            onCancel={() => setIsAdding(false)}
          />
        ) : (
          <Button secondary onClick={onAddPressed}>
            <FontAwesomeIcon icon={faPlus} /> Add
          </Button>
        )}
      </div>
    </div>
  );
};

interface TagDraftProps {
  onCancel: () => void;
  onDone: (name: string) => void;
  validateName: (name: string) => boolean;
  defaultValue?: string;
}

const TagDraft: FC<TagDraftProps> = ({
  onCancel,
  onDone,
  validateName,
  defaultValue = "",
}) => {
  const [draft, setDraft] = useState<string>(defaultValue);
  const [isValid, setIsValid] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.setCustomValidity("");
    const isInputValid = inputRef.current.checkValidity();
    const isNameValid = validateName(draft);
    if (!isNameValid)
      inputRef.current.setCustomValidity(
        `'${draft}' is taken. Two tags cannot have the same name`
      );
    if (draft) inputRef.current.reportValidity();
    setIsValid(isNameValid && isInputValid);
  }, [draft, inputRef, validateName, setIsValid]);
  return (
    <div className="edit-tags__draft">
      <Input
        ref={inputRef}
        className="edit-tags__draft-name"
        aria-label="Name"
        autoFocus={true}
        value={draft}
        onChange={(e) => setDraft(e.target.value)}
        onKeyPress={(e) => {
          if (!isValid) return;
          if (e.key === "Enter") onDone(draft);
        }}
        pattern="^\S.*"
        maxLength={40}
        required
      />
      <span>
        <Button onClick={() => onDone(draft)} disabled={!isValid}>
          Done
        </Button>
        <Tippy content={<span>Cancel</span>}>
          <Button secondary onClick={onCancel}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
        </Tippy>
      </span>
    </div>
  );
};

const ResetPasswordButton = () => {
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const sendResetEmail = (email: string) => {
    sendPasswordResetEmail(auth, email);
    setIsEmailSent(true);
  };
  return (
    <div>
      <UserContext.Consumer>
        {({ email }) => {
          if (!email) return null;
          if (isEmailSent)
            return (
              <p>
                A link has been sent to {email}. Please check your inbox and
                spam folder.
              </p>
            );
          return (
            <>
              <Tippy
                content={<span>A link will be sent to {email}</span>}
                hideOnClick={false}
              >
                <Button secondary onClick={() => sendResetEmail(email)}>
                  Reset password
                </Button>
              </Tippy>
            </>
          );
        }}
      </UserContext.Consumer>
    </div>
  );
};
