import { FC } from "react";
import {
  WARWICK_EDINBURGH_STATEMENTS,
  WARWICK_EDINBURGH_MAX,
  IPatientEvent,
} from "_shared/models/Patient";
import Text from "_shared/components/Text";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "_shared/chart";

interface WarwickEdinburghTotalsChartProps {
  events: Array<IPatientEvent>;
  eventTypeNamesById: Record<string, string>;
}

export const WarwickEdinburghTotalsChart: FC<
  WarwickEdinburghTotalsChartProps
> = ({ events, eventTypeNamesById }) => {
  const warwickEdinburghTotalsDataset: Array<{ x: Date; y: number }> = [];
  const warwickEdinburghLabels: Array<string> = [];
  events.forEach((event) => {
    const scores = Object.values(event.warwickEdinburgh ?? {});
    if (scores.length !== WARWICK_EDINBURGH_STATEMENTS.length) return;
    let total = 0;
    scores.forEach((score) => (total += score! + 1)); // Scale is stored 0-4 but displayed 1-5
    warwickEdinburghTotalsDataset.push({
      x: new Date(event.date),
      y: total,
    });
    const eventName =
      (event.type && eventTypeNamesById[event.type]) ?? "New Event";
    const label = `${eventName}: ${total} / ${WARWICK_EDINBURGH_MAX}`;
    warwickEdinburghLabels.push(label);
  });

  return (
    <>
      <Text variant="h3">Totals</Text>
      <Line
        options={{
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: "week",
                tooltipFormat: "Do MMMM yyyy",
              },
              grid: {
                display: false,
              },
            },
            y: {
              min: 0,
              max: WARWICK_EDINBURGH_MAX,
              ticks: {
                callback: (value) => {
                  switch (value) {
                    case WARWICK_EDINBURGH_MAX:
                      return `${value} (good)`;
                    case 0:
                      return `${value} (bad)`;
                    default:
                      return value;
                  }
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => warwickEdinburghLabels[context.dataIndex],
              },
            },
          },
        }}
        data={{
          datasets: [
            {
              label: "Warwick Edinburgh Total Scores",
              borderColor: CHART_COLORS[3],
              pointBackgroundColor: CHART_COLORS[3],
              backgroundColor: "transparent",
              data: warwickEdinburghTotalsDataset,
            },
          ],
        }}
      />
    </>
  );
};
