import { useState, FC } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock as fasClock,
  faSearch,
  faUserPlus,
  faUndo,
  faArchive,
} from "@fortawesome/free-solid-svg-icons";
import {
  faClock as falClock,
  faArchive as falArchive,
  faUsers as falUsers,
} from "@fortawesome/pro-light-svg-icons";
import Tippy from "@tippyjs/react";
import { differenceInCalendarDays } from "date-fns";
import { createColumnHelper } from "@tanstack/react-table";
import Button from "_shared/components/Button";
import Patient from "./Patient";
import Wrapper from "_shared/components/Wrapper";
import {
  CheckboxField,
  DebouncedTextField,
  TextFieldClearButton,
} from "_shared/components/Field";
import { useUrl } from "_shared/hooks";
import { useGroup } from "../Group";
import Text from "_shared/components/Text";
import { Table } from "_shared/components/Table";
import { partition, pickBy } from "lodash";
import { localeSort } from "_shared/utils";
import { createPatient, useMetaPatientsQuery } from "./Patients.queries";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { PatientsIndexEntry } from "_shared/models/_meta";
import UserField from "_shared/components/UserField";
import Scrollable from "_shared/components/Scrollable";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "_shared/firebase";
import Tab, { TabBar, TabIcon } from "_shared/components/Tab";

interface PatientRow extends PatientsIndexEntry {
  id: string;
}

const columnHelper = createColumnHelper<PatientRow>();

const nameColumn = columnHelper.accessor("name", {
  header: "Name",
  cell: function Cell({ row }) {
    const { groupId } = useParams<{ groupId: string }>();
    const { id, name } = row.original;
    return (
      <Link to={`/groups/${groupId}/patients/patient/${id}`}>
        <Text variant="link" className="w-[100%] text-left">
          {name?.length ? name : <LocalizedMessage id="patients_new_patient" />}
        </Text>
      </Link>
    );
  },
});

const getOngoingColumns = (isPatientArchiveOn: boolean) => {
  const columns = [
    nameColumn,
    columnHelper.accessor("assignee", {
      header: "Assignee",
      cell: function Cell({ getValue }) {
        const group = useGroup();
        const assigneeId = getValue();
        if (!assigneeId) return null;
        const email = group._userProfiles?.[assigneeId]?.email;
        return <Text variant="small">{email}</Text>;
      },
    }),
    columnHelper.accessor("reminder", {
      header: "Reminder",
      cell: ({ getValue }) => {
        return <Reminder reminder={getValue()} />;
      },
    }),
    ...(isPatientArchiveOn
      ? [
          columnHelper.display({
            id: "archive",
            cell: function Cell({ row }) {
              const { groupId } = useParams<{ groupId: string }>();
              const { id, name } = row.original;
              return (
                <Tippy
                  content={
                    <span>
                      <LocalizedMessage id="patients_archive_patient" />
                    </span>
                  }
                >
                  <Button
                    secondary
                    onClick={() =>
                      updateDoc(
                        doc(firestore, `/groups/${groupId}/_meta/patients`),
                        {
                          [id]: name,
                        }
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faArchive} />
                  </Button>
                </Tippy>
              );
            },
          }),
        ]
      : []),
  ];
  return columns;
};

const archivedColumns = [
  nameColumn,
  columnHelper.display({
    id: "archive",
    cell: function Cell({ row }) {
      const { groupId } = useParams<{ groupId: string }>();
      const { id, name } = row.original;
      return (
        <Tippy
          content={
            <span>
              <LocalizedMessage id="patients_restore_archived_patient" />
            </span>
          }
        >
          <Button
            secondary
            onClick={async () => {
              const patientDoc = await getDoc(
                doc(firestore, `/groups/${groupId}/patients/${id}`)
              );
              const { assignee, flags, reminder } =
                patientDoc.data() as Partial<PatientsIndexEntry>;
              const restoredPatient: Partial<PatientsIndexEntry> = pickBy({
                name: name,
                assignee,
                flags,
                reminder,
              });
              updateDoc(doc(firestore, `/groups/${groupId}/_meta/patients`), {
                [id]: restoredPatient,
              });
            }}
          >
            <FontAwesomeIcon icon={faUndo} />
          </Button>
        </Tippy>
      );
    },
  }),
];

const Patients: FC = () => {
  //TODO: get groupId from context
  const match = useRouteMatch<{ groupId: string }>();
  const { groupId } = match.params;
  const { path } = match;
  const url = useUrl();
  const history = useHistory();
  const { isPatientCountUnlimited, isPatientArchiveOn } = useFeatureFlags();
  const group = useGroup();
  const [query, setQuery] = useState<string>("");
  const [flagsFilter, setFlagsFilter] = useState<ReadonlyArray<string>>([]);
  const [assigneeUidFilter, setAssigneeUidFilter] = useState<string>("");
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [patientsIndex = {}, queryState] = useMetaPatientsQuery(groupId);

  const add = async () => {
    const newPatientId = await createPatient(groupId);
    history.push(`${url}/${newPatientId}`);
  };
  const onAddPressed = () => {
    if (isAdding) add(); //If this is the second click, add the patient
    setIsAdding(!isAdding); //Toggle isAdding
  };

  if (queryState === "loading") return null;
  if (queryState === "error") {
    return (
      <Wrapper pad className="flex flex-col gap-8 py-8">
        <p>Connection error, please try again</p>
      </Wrapper>
    );
  }

  const keywords = query.toLowerCase().trim().replace(/,/g, "").split(" ");
  const filteredPatients = Object.entries(patientsIndex)
    .map(([id, patient]) => ({ id, ...patient }))
    .filter((patient) => {
      return (
        !patient.isArchived &&
        matchAssignee(patient, assigneeUidFilter) &&
        matchFlags(patient, flagsFilter) &&
        matchSearch(patient, keywords)
      );
    });
  const filteredArchivedPatients = Object.entries(patientsIndex)
    .map(([id, patient]) => ({ id, ...patient }))
    .filter((patient) => patient.isArchived);
  const [newPatients, nonNewPatients] = partition(filteredPatients, matchIsNew);
  const [duePatients, nonDuePatients] = partition(nonNewPatients, matchIsDue);
  const filteredSortedPatients = [
    ...newPatients,
    ...duePatients.sort((a, b) => {
      const daysDiff = differenceInCalendarDays(
        new Date(a.reminder!),
        new Date(b.reminder!)
      );
      if (daysDiff !== 0) return daysDiff;
      return localeSort(a.name, b.name);
    }),
    ...nonDuePatients.sort((a, b) => localeSort(a.name, b.name)),
  ];
  const flagOptions = Object.entries(group.patientFlags ?? {})
    .sort(([_, aLabel], [__, bLabel]) => aLabel.localeCompare(bLabel))
    .map(([value, label]) => {
      return { value, label: <Text variant="small">{label}</Text> };
    });

  return (
    <Switch>
      <Route path={`${path}/patient/:patientId`}>
        <Patient />
      </Route>
      <Route>
        <div className="flex h-full flex-1 flex-col">
          <header>
            <Wrapper wide pad className="flex justify-between gap-4 pb-2 pt-6">
              <div className="flex items-center gap-4">
                <Text variant="h1">
                  <LocalizedMessage id="patients_title" />
                </Text>
                {isPatientCountUnlimited ? null : (
                  <Text variant="smallLight">
                    <LocalizedMessage
                      id="patients_limit"
                      values={{ count: filteredSortedPatients.length }}
                    />
                  </Text>
                )}
              </div>
              <Tippy
                content={
                  <span>
                    <LocalizedMessage id="patients_consent_reminder" />
                  </span>
                }
                hideOnClick={false}
              >
                <Button
                  onClick={onAddPressed}
                  onBlur={() => setIsAdding(false)}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                  {isAdding ? (
                    "Confirm consent"
                  ) : (
                    <LocalizedMessage id="patients_add_patient" />
                  )}
                </Button>
              </Tippy>
            </Wrapper>
            <TabBar>
              <Tab to={`${url}/ongoing`}>
                <TabIcon icon={falUsers} /> Ongoing
              </Tab>
              {isPatientArchiveOn ? (
                <Tab to={`${url}/archived`}>
                  <TabIcon icon={falArchive} /> Archived
                </Tab>
              ) : null}
            </TabBar>
          </header>
          <Wrapper wide pad className="flex min-h-0 flex-1">
            <div className="flex flex-1 flex-col">
              <Switch>
                <Route path={path} exact>
                  <Redirect to={`${url}/ongoing`} />
                </Route>
                <Route path={`${path}/ongoing`}>
                  <Scrollable behavior="auto">
                    <Table
                      columns={getOngoingColumns(isPatientArchiveOn)}
                      data={filteredSortedPatients}
                    />
                  </Scrollable>
                </Route>
                <Route path={`${path}/archived`}>
                  <Scrollable behavior="auto">
                    <Table
                      columns={archivedColumns}
                      data={filteredArchivedPatients}
                    />
                  </Scrollable>
                </Route>
              </Switch>
            </div>
            <div className="ml-8 flex w-72 flex-col gap-2 self-stretch border-l border-l-pink pl-6 pt-1">
              <DebouncedTextField
                wait={300}
                short
                label={<Text variant="smallLight">Name</Text>}
                value={query || ""}
                onChange={(e) => setQuery(e.target.value)}
                leading={<FontAwesomeIcon icon={faSearch} />}
                trailing={
                  query && <TextFieldClearButton onClick={() => setQuery("")} />
                }
              />
              <UserField
                short
                label={<Text variant="smallLight">Assignee</Text>}
                uid={assigneeUidFilter}
                onChange={(value) => setAssigneeUidFilter(value ?? "")}
              />
              <CheckboxField
                short
                label={<Text variant="smallLight">Tags</Text>}
                value={new Set(flagsFilter)}
                onChange={(value) => setFlagsFilter(Array.from(value))}
                options={flagOptions}
              />
            </div>
          </Wrapper>
        </div>
      </Route>
    </Switch>
  );
};
export default Patients;

interface ReminderProps {
  reminder?: string;
}

const Reminder: FC<ReminderProps> = (props) => {
  const reminder = props.reminder ? new Date(props.reminder) : undefined;
  if (!reminder) return null;
  let description = "";
  const daysToFromReminder = differenceInCalendarDays(reminder, new Date());
  description =
    daysToFromReminder === 0
      ? "Reminder due today"
      : `Reminder ${new Intl.RelativeTimeFormat().format(
          daysToFromReminder,
          "day"
        )}`;
  let className = "";
  let icon = fasClock;
  if (daysToFromReminder <= 0) {
    className = "text-red";
  } else if (daysToFromReminder <= 14) {
    className = "text-orange";
  } else {
    icon = falClock;
  }

  return (
    <Tippy content={<span>{description}</span>}>
      <small className={className}>
        <FontAwesomeIcon icon={icon} />{" "}
        <time dateTime={reminder.toISOString()}>
          {reminder.toLocaleDateString()}
        </time>
      </small>
    </Tippy>
  );
};

const matchIsDue = (patient: PatientRow): boolean => {
  const { reminder } = patient;
  if (!reminder) return false;
  return differenceInCalendarDays(new Date(reminder), new Date()) <= 0;
};

const matchIsNew = (patient: PatientRow): boolean => {
  return !patient.name;
};

const matchName = (patient: PatientRow, keywords: string[]): boolean => {
  const name = patient.name?.toLowerCase() ?? "";
  return keywords.every((keyword) => name.includes(keyword)) ? true : false;
};

const matchSearch = (patient: PatientRow, keywords: string[]): boolean => {
  return (
    keywords.length === 0 || matchIsNew(patient) || matchName(patient, keywords)
  );
};

const matchFlags = (
  patient: PatientRow,
  flagsFilter: ReadonlyArray<string>
): boolean => {
  const { flags = new Set() } = patient;
  if (flagsFilter.length === 0) return true;
  return flagsFilter.every((flag) => flags.has(flag));
};

const matchAssignee = (
  patient: PatientRow,
  assigneeUidFilter: string
): boolean => {
  return !assigneeUidFilter || patient.assignee === assigneeUidFilter;
};
