import { FC } from "react";
import { useParams } from "react-router-dom";
import { ButtonGroup } from "_shared/components/Button";
import { useShelfServiceQuery } from "./HelpShelf.hooks";
import PreviewService from "../services/PreviewService";
import ReportShelfService from "./ReportShelfService";
import ToggleShelfServiceUsage from "_shared/components/ToggleShelfServiceUsage";
import "_shared/css/ShelfService.css";

const ShelfService: FC = () => {
  const { serviceId = "" } = useParams<{ serviceId: string }>();
  const service = useShelfServiceQuery(serviceId);
  if (service === undefined) return null;
  if (service === null) return <em>Not found</em>;
  return (
    <>
      <div className="shelf-service__actions">
        <ButtonGroup>
          <ToggleShelfServiceUsage service={service} serviceId={serviceId} />
          <ReportShelfService service={service} serviceId={serviceId} />
        </ButtonGroup>
      </div>
      <PreviewService service={service} />
    </>
  );
};

export default ShelfService;
