import { FC } from "react";
import Text from "_shared/components/Text";
import { invertInRange, Nullable } from "_shared/utils";
import {
  DiscreteRangeInput,
  Field,
  Label,
  Input,
} from "_shared/components/Field";
import {
  LONELINESS_STATEMENTS,
  LONELINESS_STEPS,
  IPatientEvent,
} from "_shared/models/Patient";

interface LonelinessQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const LonelinessQuestionnaire: FC<LonelinessQuestionnaireProps> = ({
  event,
  onChange,
}) => {
  const { loneliness } = event;

  return (
    <>
      <hr />
      <Text variant="h2" id="loneliness">
        Loneliness
      </Text>
      <Field wide>
        <p>
          The next few questions are about your relationships with others. There
          are no right or wrong answers — we want to know what you think. When
          responding it is best to think of your life as it generally is now (we
          all have some good or bad days).
        </p>
        <p>
          <small>{[...LONELINESS_STEPS].reverse().join(" / ")}</small>
        </p>
      </Field>
      {LONELINESS_STATEMENTS.map((statement, i) => {
        const value = loneliness ? loneliness[i] : undefined;
        return (
          <Field key={statement} wide>
            <div className="contents" role="group">
              <DiscreteRangeInput
                label={statement}
                value={
                  value === undefined || value === 5 // "Don't know" is shown outside the slider
                    ? undefined
                    : // Invert for reversed scale, ignoring 5 for "Don't know", which isn't shown on the slider
                      invertInRange(value, LONELINESS_STEPS.length - 1)
                }
                onChange={(newValue) =>
                  onChange({
                    // Invert for reversed scale, ignoring 5 for "Don't know", which isn't shown on the slider
                    loneliness: {
                      ...loneliness,
                      [i]: invertInRange(newValue, LONELINESS_STEPS.length - 1),
                    },
                  })
                }
                // Display scale in reverse so a full slider (good) corresponsds to low loneliness (also good)
                steps={[...LONELINESS_STEPS.slice(0, 5)].reverse()}
              />
              <Label>
                <Input
                  type="radio"
                  checked={value === 5}
                  onChange={() =>
                    onChange({
                      loneliness: { ...loneliness, [i]: 5 },
                    })
                  }
                />
                Don't know
              </Label>
            </div>
          </Field>
        );
      })}
    </>
  );
};
