import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentProps, FC } from "react";
import { NavLink } from "react-router-dom";
import "_shared/css/Tab.css";
import Wrapper from "./Wrapper";

interface TabProps extends ComponentProps<typeof NavLink> {
  disabled?: boolean;
}

const Tab: FC<TabProps> = ({ children, disabled = false, ...otherProps }) => {
  const classes = ["tab"];
  if (disabled) classes.push("tab--disabled");
  return (
    <NavLink
      className={classes.join(" ")}
      activeClassName="tab--active"
      {...otherProps}
    >
      {children}
    </NavLink>
  );
};
export default Tab;

export const TabIcon: FC<ComponentProps<typeof FontAwesomeIcon>> = (props) => {
  return (
    <span className="tab__icon">
      <FontAwesomeIcon fixedWidth {...props} />
    </span>
  );
};

export const TabBar: FC = ({ children }) => {
  return (
    <nav className="tab-bar">
      <Wrapper wide pad className="tab-bar__wrapper">
        {children}
      </Wrapper>
    </nav>
  );
};
