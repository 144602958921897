import { Timestamp } from "firebase/firestore";

export const SOCIAL_NETWORKS = [
  "facebook",
  "instagram",
  "linkedin",
  "twitter",
  "youtube",
] as const;
export type SocialNetwork = (typeof SOCIAL_NETWORKS)[number];

export interface IService {
  /**
   * A service with a _globalId is from HelpShelf
   */
  readonly _globalId?: string;
  /**
   * `true` when a HelpShelf service has a newer version available
   */
  readonly _hiddenAfterUpdate?: true;
  readonly lastEdited?: Date;
  readonly name?: string;
  readonly tags?: Set<string>;
  readonly description?: string;
  readonly link?: string;
  readonly number?: string;
  readonly email?: string;
  readonly address?: string;
  readonly pricing?: string;
  readonly contactName?: string;
  readonly lat?: number;
  readonly lng?: number;
  readonly social?: {
    [network in SocialNetwork]?: string;
  };
  readonly notes?: string;
  readonly isHidden?: boolean;
}

export interface IShelfService extends IService {
  readonly users: ReadonlyArray<string>;
  readonly _userProfiles: {
    [uid: string]: {
      email: string;
    };
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertServiceFromFirestore = (data: any): IService => {
  const service = { ...data };
  if (data.lastEdited) {
    // lastEdited was originally stored as a string.
    // Going forward, we want to use Firestore Timestamps.
    service.lastEdited =
      typeof data.lastEdited === "string"
        ? new Date(data.lastEdited)
        : (data.lastEdited as Timestamp).toDate();
  }
  if (data.tags) service.tags = new Set(data.tags);
  return service;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertShelfServiceFromFirestore = (data: any): IShelfService => {
  return convertServiceFromFirestore(data) as IShelfService;
};
