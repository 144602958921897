import { collection, doc, onSnapshot, writeBatch } from "firebase/firestore";
import { useEffect, useState } from "react";
import { PatientsIndex } from "_shared/models/_meta";
import { firestore } from "_shared/firebase";
import { QueryResult, QueryState } from "_shared/queries";

export const useMetaPatientsQuery = (
  groupId: string
): QueryResult<PatientsIndex> => {
  const [patients, setPatients] = useState<PatientsIndex | undefined>();
  const [state, setState] = useState<QueryState>("loading");

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `groups/${groupId}/_meta/patients`),
      (doc) => {
        setState("success");
        const data = doc.data();
        if (data) {
          setPatients(
            Object.fromEntries(
              Object.entries(data).map(([id, patient]) => {
                // To reduce the size of the index, archived patients in groups/${groupId}/_meta/patients document are set to strings
                if (typeof patient === "string") {
                  return [
                    id,
                    {
                      name: patient,
                      isArchived: true,
                    },
                  ];
                }
                return [
                  id,
                  {
                    ...patient,
                    flags: patient.flags ? new Set(patient.flags) : undefined,
                  },
                ];
              })
            )
          );
        } else {
          setPatients(undefined);
        }
      },
      (error) => {
        setState("error");
        if (error.code !== "permission-denied") console.error(error);
      }
    );
    return unsubscribe;
  }, [groupId]);

  return [patients, state];
};

export const createPatient = async (groupId: string): Promise<string> => {
  const batch = writeBatch(firestore);
  const newPatientRef = doc(
    collection(firestore, `/groups/${groupId}/patients`)
  );
  batch.set(newPatientRef, {});
  batch.set(
    doc(firestore, `/groups/${groupId}/_meta/patients`),
    { [newPatientRef.id]: {} },
    { merge: true }
  );
  await batch.commit();
  return newPatientRef.id;
};
