import {
  arrayRemove,
  arrayUnion,
  deleteField,
  doc,
  updateDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { IService } from "_shared/models/Service";
import Button from "./Button";
import { GroupContext } from "../../groups/Group";
import { firestore, convertToFirestore } from "_shared/firebase";
import {
  faLink,
  faLinkSlash,
  faSpinner,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import PersistentTippy from "./PersistentTippy";
import { omit } from "lodash";

enum HelpShelfServiceStatus {
  Unused = "Unused",
  Used = "Used",
  /**
   * Status is uncertain, for instance when a recent change is still being persisted
   */
  Loading = "Loading",
}

interface ToggleShelfServiceUsageProps {
  service: IService;
  serviceId: string;
}

const ToggleShelfServiceUsage: FC<ToggleShelfServiceUsageProps> = ({
  service,
  serviceId,
}) => {
  const [status, setServiceUsage] = useHelpShelf(service, serviceId);
  const [prevStatus, setPrevStatus] = useState<
    HelpShelfServiceStatus | undefined
  >();

  const toggle = () => {
    if (status === HelpShelfServiceStatus.Unused) {
      setServiceUsage(true);
    } else if (status === HelpShelfServiceStatus.Used) {
      setServiceUsage(false);
    } else {
      throw new Error(
        `Cannot toggle use of HelpShelf service when status is ${status}`
      );
    }
    setPrevStatus(status);
  };

  const undo = () => {
    toggle();
    setPrevStatus(undefined);
  };

  const buttonProps = {
    disabled: status === HelpShelfServiceStatus.Loading,
    secondary: true,
  };

  switch (status) {
    case HelpShelfServiceStatus.Used: {
      if (prevStatus === HelpShelfServiceStatus.Unused)
        return (
          <PersistentTippy content="Added to services">
            <Button onClick={undo} {...buttonProps}>
              <FontAwesomeIcon icon={faUndo} /> Undo
            </Button>
          </PersistentTippy>
        );
      return (
        <Tippy content="Remove from your list of services">
          <Button onClick={toggle} kind="danger" {...buttonProps}>
            <FontAwesomeIcon icon={faLinkSlash} /> Unlink service
          </Button>
        </Tippy>
      );
    }
    case HelpShelfServiceStatus.Unused: {
      if (prevStatus === HelpShelfServiceStatus.Used)
        return (
          <PersistentTippy content="Removed from services">
            <Button onClick={undo} {...buttonProps}>
              <FontAwesomeIcon icon={faUndo} /> Undo
            </Button>
          </PersistentTippy>
        );
      return (
        <Tippy content="Copy to your list of services">
          <Button onClick={toggle} {...buttonProps}>
            <FontAwesomeIcon icon={faLink} /> Link service
          </Button>
        </Tippy>
      );
    }
    case HelpShelfServiceStatus.Loading: {
      return (
        <>
          <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </>
      );
    }
  }
};

export default ToggleShelfServiceUsage;

const useHelpShelf = (
  service: IService,
  serviceId: string
): [HelpShelfServiceStatus, (isInUse: boolean) => void] => {
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const group = useContext(GroupContext) ?? {};
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const setServiceUsage = async (isInUse: boolean) => {
    setIsUpdating(true);
    try {
      await updateDoc(doc(firestore, `groups/${groupId}`), {
        [`services.${serviceId}`]: isInUse
          ? convertToFirestore({
              _globalId: serviceId,
              // Adding a HelpShelf service implicitly approves it
              isHidden: false,
              ...omit(service, "users", "_userProfiles"),
            })
          : deleteField(),
        _globalServiceIds: isInUse
          ? arrayUnion(serviceId)
          : arrayRemove(serviceId),
      });
    } catch (e) {
      console.error(e);
      window.alert(
        "Could not access HelpShelf service, please try again later."
      );
    }
    setIsUpdating(false);
  };

  const status = isUpdating
    ? HelpShelfServiceStatus.Loading
    : serviceId in (group.services ?? {})
    ? HelpShelfServiceStatus.Used
    : HelpShelfServiceStatus.Unused;

  return [status, setServiceUsage];
};
