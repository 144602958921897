import { useEffect, useState } from "react";
import { Report } from "_shared/models/_meta";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "_shared/firebase";
import { IPatient } from "_shared/models/Patient";
import { QueryResult, QueryState } from "_shared/queries";

export const useMetaReportQuery = (groupId: string): QueryResult<Report> => {
  const [report, setReport] = useState<Report | undefined>();
  const [state, setState] = useState<QueryState>("loading");

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `/groups/${groupId}/_meta/report`),
      (doc) => {
        setState("success");
        const data = doc.data();
        if (data === undefined) {
          setReport(undefined);
        } else {
          setReport({
            ...data,
            patients: Object.fromEntries(
              Object.entries<Partial<IPatient>>(data.patients).map(
                ([id, { referralReasons, flags, ...patient }]) => {
                  return [
                    id,
                    {
                      ...patient,
                      referralReasons: referralReasons
                        ? new Set(referralReasons)
                        : undefined,
                      flags: flags ? new Set(flags) : undefined,
                    },
                  ];
                }
              )
            ),
          });
        }
      },
      (error) => {
        setState("error");
        if (error.code !== "permission-denied") console.error(error);
      }
    );
    return unsubscribe;
  }, [groupId]);

  return [report, state];
};
