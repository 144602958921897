import { FC } from "react";
import Text from "_shared/components/Text";
import { Nullable } from "_shared/utils";
import { DiscreteRangeInput, Field, Label } from "_shared/components/Field";
import { KIDSCREEN_QUESTIONS, IPatientEvent } from "_shared/models/Patient";

interface KidscreenQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const KidscreenQuestionnaire: FC<KidscreenQuestionnaireProps> = ({
  event,
  onChange,
}) => {
  const kidscreen = event.kidscreen ?? [];

  return (
    <>
      <hr />
      <Text variant="h2" id="kidscreen">
        Kidscreen
      </Text>
      <Field wide>
        {Object.entries(KIDSCREEN_QUESTIONS).map(([question, steps], i) => {
          return (
            <DiscreteRangeInput
              key={question}
              label={
                <>
                  {i === 0 ? (
                    <Label strong>Thinking about the last week...</Label>
                  ) : null}
                  {i === Object.keys(KIDSCREEN_QUESTIONS).length - 1 ? (
                    <Label strong>In general...</Label>
                  ) : null}
                  {question}
                </>
              }
              steps={steps}
              value={kidscreen[i]}
              onChange={(value) =>
                onChange({ kidscreen: { ...kidscreen, [i]: value } })
              }
            />
          );
        })}
      </Field>
    </>
  );
};
