import { FC, useEffect, useRef, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, isFirebaseError } from "_shared/firebase";
import Button from "./Button";
import { Field, TextField } from "./Field";
import Wrapper from "./Wrapper";
import { getEmailForSignInLink } from "./Auth";
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  verifyPasswordResetCode,
} from "firebase/auth";
import Text from "./Text";

const urlToPathQueryHash = (url: string): string => {
  const { pathname, search, hash } = new URL(url);
  return pathname + search + hash;
};

/**
 * Handle Firebase account action links: https://firebase.google.com/docs/auth/custom-email-handler
 */
const AuthAction: FC = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const mode = query.get("mode"); // Action
  const oobCode = query.get("oobCode"); // One-time code to validate link
  const continueUrl = query.get("continueUrl"); // URL to redirect to once action is complete
  const continueTo = continueUrl ? urlToPathQueryHash(continueUrl) : "/";

  const [didActionSucceed, setDidActionSucceed] = useState<
    boolean | undefined
  >();

  if (didActionSucceed === true) return <Redirect to={continueTo} />;
  if (didActionSucceed === false) return <InvalidLink />;
  switch (mode) {
    case "resetPassword":
      if (oobCode === null) return <InvalidLink />;
      return (
        <ResetPassword
          onFinish={(didSucceed) => setDidActionSucceed(didSucceed)}
          oobCode={oobCode}
          minLength={15}
        />
      );
    case "signIn":
      return (
        <SignInWithLink
          onFinish={(didSucceed) => setDidActionSucceed(didSucceed)}
        />
      );
    // TODO
    // case 'recoverEmail':
    // case 'verifyEmail':
    default:
      return <InvalidLink />;
  }
};

export default AuthAction;

// Get the top 100k most common passwords from Have I Been Pwned, courtesy of
// https://www.ncsc.gov.uk/blog-post/passwords-passwords-everywhere
const useTop100kPasswords = (): Set<string> | undefined => {
  const [passwords, setPasswords] = useState<Set<string>>();
  useEffect(() => {
    const fetchPasswords = async () => {
      const file = await window.fetch("/assets/PwnedPasswordsTop100k.txt");
      const text = await file.text();
      setPasswords(new Set(text.split("\n")));
    };
    fetchPasswords();
  }, []);
  return passwords;
};

interface ResetPasswordProps {
  oobCode: string;
  minLength: number;
  onFinish: (didSucceed: boolean) => void;
}

const ResetPassword: FC<ResetPasswordProps> = ({
  oobCode,
  onFinish,
  minLength,
}) => {
  const [email, setEmail] = useState<string | undefined>();
  const [newPassword, setNewPassword] = useState<string>("");
  const [isResetting, setIsResetting] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const top100kPasswords = useTop100kPasswords();

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const verifiedEmail = await verifyPasswordResetCode(auth, oobCode);
        setEmail(verifiedEmail);
      } catch (e) {
        console.error(e);
        onFinish(false);
      }
    };
    verifyCode();
  }, [setEmail, oobCode, onFinish]);
  useEffect(() => {
    if (!passwordRef.current) return;
    passwordRef.current.setCustomValidity("");
    const isInputValid = passwordRef.current.checkValidity();
    if (isInputValid) {
      if (top100kPasswords === undefined) {
        passwordRef.current.setCustomValidity("Validating...");
        passwordRef.current.reportValidity();
      } else if (top100kPasswords.has(newPassword)) {
        passwordRef.current.setCustomValidity("This password is too common");
        passwordRef.current.reportValidity();
      }
    }
    setIsValid(passwordRef.current.checkValidity());
  }, [newPassword, passwordRef, top100kPasswords]);

  const onResetPressed = async () => {
    setIsResetting(true);
    setErrorMessage("");
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      await signInWithEmailAndPassword(auth, email!, newPassword);
      onFinish(true);
    } catch (e) {
      if (!isFirebaseError(e)) throw e;
      switch (e.code) {
        case "auth/weak-password":
          setErrorMessage("That password is not strong enough");
          setIsResetting(false);
          break;
        default:
          console.error(e);
          onFinish(false);
      }
    }
  };

  if (!email) return null;

  return (
    <Wrapper pad center>
      <Text variant="h1">Reset Password</Text>
      <TextField type="email" label="Email" value={email} readOnly />
      <TextField
        ref={passwordRef}
        type="password"
        label="New password"
        value={newPassword}
        minLength={minLength}
        required
        onChange={(e) => setNewPassword(e.target.value)}
        disabled={isResetting}
      />
      <Field>
        <Text variant="smallLight">
          Try 5 random words.
          <br />
          Do not choose a password that you've used before.
        </Text>
        {errorMessage && (
          <p style={{ color: "var(--danger)" }}>{errorMessage}</p>
        )}
      </Field>
      <Field>
        <Button onClick={onResetPressed} disabled={!isValid}>
          Reset password
        </Button>
      </Field>
    </Wrapper>
  );
};

interface SignInWithLinkProps {
  onFinish: (didSucceed: boolean) => void;
}

const SignInWithLink: FC<SignInWithLinkProps> = ({ onFinish }) => {
  const [isTryingLocalEmail, setIsTryingLocalEmail] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  useEffect(() => {
    const signInWithLocalEmail = async () => {
      if (!isTryingLocalEmail) return;
      const localEmail = getEmailForSignInLink();
      if (localEmail) {
        try {
          await signInWithEmailLink(auth, localEmail);
          onFinish(true);
        } catch (e) {
          if (!isFirebaseError(e)) throw e;
          if (e.code === "auth/invalid-email") {
            setIsTryingLocalEmail(false);
          } else {
            console.error(e);
            onFinish(false);
          }
        }
      } else {
        setIsTryingLocalEmail(false);
      }
    };
    signInWithLocalEmail();
  }, [isTryingLocalEmail, setIsTryingLocalEmail, onFinish]);
  const submit = async () => {
    if (!isValid) return;
    setErrorMessage("");
    try {
      await signInWithEmailLink(auth, email);
      onFinish(true);
    } catch (e) {
      if (!isFirebaseError(e)) throw e;
      if (e.code === "auth/invalid-email") {
        setIsValid(false);
        setErrorMessage("That email was not correct");
      } else {
        console.error(e);
        onFinish(false);
      }
    }
  };
  if (isTryingLocalEmail) return null;
  return (
    <Wrapper pad center>
      <Text variant="h1">Sign in</Text>
      <p>Please confirm your email address</p>
      <TextField
        type="email"
        label="Email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setIsValid(e.target.checkValidity());
        }}
        onKeyUp={(e) => e.key === "Enter" && submit()}
      />
      {errorMessage && <p style={{ color: "var(--danger)" }}>{errorMessage}</p>}
      <Field>
        <Button onClick={submit} disabled={!isValid}>
          Sign in
        </Button>
      </Field>
    </Wrapper>
  );
};

const InvalidLink = () => {
  return (
    <Wrapper center pad>
      <p>That link was not valid</p>
      <Link to="/">
        <Button>Go home</Button>
      </Link>
    </Wrapper>
  );
};
