import { ComponentProps } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScaleUnbalanced,
  faSignsPost,
  faSmile,
  faMobile,
  faUserFriends as falUserFriends,
} from "@fortawesome/pro-light-svg-icons";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { useUrl } from "_shared/hooks";
import { AssigneeReport } from "./AssigneeReport";
import { ReferralsReport } from "./ReferralsReport";
import Wrapper from "_shared/components/Wrapper";
import Text from "_shared/components/Text";
import { useMetaReportQuery } from "./Reports.queries";
import { MycawReport } from "./MycawReport";
import { AnalyticsReport } from "./AnalyticsReport";
import { AllPatientsReport } from "./AllPatientsReport";

const Reports = () => {
  const { path, params } = useRouteMatch<{ groupId: string }>();
  const url = useUrl();
  const { groupId } = params;
  const [report, queryState] = useMetaReportQuery(groupId);

  if (queryState === "loading") return null;

  return (
    <div className="reports">
      <Switch>
        <Route path={`${path}/patients`}>
          {report ? <AllPatientsReport report={report} /> : null}
        </Route>
        <Route path={`${path}/assignees`}>
          {report ? <AssigneeReport report={report} /> : null}
        </Route>
        <Route path={`${path}/referrals`}>
          {report ? <ReferralsReport report={report} /> : null}
        </Route>
        <Route path={`${path}/mycaw`}>
          {report ? <MycawReport report={report} /> : null}
        </Route>
        <Route path={`${path}/analytics`}>
          <AnalyticsReport groupId={groupId} />
        </Route>
        <Route>
          <Wrapper pad className="flex flex-col gap-4 py-8">
            <Text variant="h1">Reports</Text>
            <nav>
              <Link
                to={`${url}/patients`}
                className={
                  report === undefined ? "pointer-events-none opacity-60" : ""
                }
              >
                <p className="flex items-center gap-3 py-3 px-4 text-interactive hover:bg-gray-96">
                  <ReportsIcon icon={falUserFriends} fixedWidth />
                  <span className="text-xl">All Patients</span>
                </p>
              </Link>
              <Link to={`${url}/analytics`}>
                <p className="flex items-center gap-3 py-3 px-4 text-interactive hover:bg-gray-96">
                  <ReportsIcon icon={faMobile} fixedWidth />
                  <span className="text-xl">App Analytics</span>
                </p>
              </Link>
              <Link
                to={`${url}/assignees`}
                className={
                  report === undefined ? "pointer-events-none opacity-60" : ""
                }
              >
                <p className="flex items-center gap-3 py-3 px-4 text-interactive hover:bg-gray-96">
                  <ReportsIcon icon={faScaleUnbalanced} fixedWidth />
                  <span className="text-xl">Case Load</span>
                </p>
              </Link>
              <Link
                to={`${url}/mycaw`}
                className={
                  report === undefined ? "pointer-events-none opacity-60" : ""
                }
              >
                <p className="flex items-center gap-3 py-3 px-4 text-interactive hover:bg-gray-96">
                  <ReportsIcon icon={faSmile} fixedWidth />
                  <span className="text-xl">MYCaW®</span>
                </p>
              </Link>
              <Link
                to={`${url}/referrals`}
                className={
                  report === undefined ? "pointer-events-none opacity-60" : ""
                }
              >
                <p className="flex items-center gap-3 py-3 px-4 text-interactive hover:bg-gray-96">
                  <ReportsIcon icon={faSignsPost} fixedWidth />
                  <span className="text-xl">Referral Reasons</span>
                </p>
              </Link>
            </nav>
          </Wrapper>
        </Route>
      </Switch>
    </div>
  );
};
export default Reports;

const ReportsIcon = (props: ComponentProps<typeof FontAwesomeIcon>) => {
  return <FontAwesomeIcon className="reports__icon" {...props} />;
};
