import { ComponentProps, FC } from "react";
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import { STRINGS } from "_shared/localization/strings";

type Props = ComponentProps<typeof FormattedMessage> & {
  id: keyof typeof STRINGS;
};

export const LocalizedMessage: FC<Props> = (props) => {
  return (
    <FormattedMessage
      id={props.id}
      values={{ ...props.values, em: (...chunks) => <em>{chunks}</em> }}
    />
  );
};
