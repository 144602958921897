import { FC, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { setup, setUserPermissionsOnGroup } from "_shared/api";
import { IGroup } from "_shared/models/Group";
import { UserContext } from "_shared/components/Auth";
import Button from "_shared/components/Button";
import Text from "_shared/components/Text";
import Users from "_shared/components/Users";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";

interface IGroupUsersProps {
  group: IGroup;
}

const GroupUsers: FC<IGroupUsersProps> = ({ group }) => {
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const user = useContext(UserContext);

  const handleAddEmails = async (emails: ReadonlyArray<string>) => {
    await setUserPermissionsOnGroup({
      groupId,
      emails: emails,
      action: "add",
    });
  };
  const handleRemoveEmail = async (email: string) => {
    await setUserPermissionsOnGroup({
      groupId,
      emails: [email],
      action: "remove",
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <Text variant="h2">Users</Text>
      <div>
        <LocalizedMessage id="settings_users" />
        &nbsp;
        <Link to="../help#work-with-colleagues">
          <Text variant="link">What happens when I add a user?</Text>
        </Link>
      </div>
      <Users
        onAddEmails={handleAddEmails}
        onRemoveEmail={handleRemoveEmail}
        userProfiles={group._userProfiles}
      />
      {user.email === "xander@hand.community" && (
        <div>
          <hr />
          <Text variant="h2">Admins</Text>
          <Button secondary kind="danger" onClick={() => setup()}>
            Run setup
          </Button>
        </div>
      )}
    </div>
  );
};

export default GroupUsers;
