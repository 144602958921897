import { collection, getDocs, query, where } from "firebase/firestore";
import { FC, useContext, useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { firestore } from "_shared/firebase";
import { IS_STAGING, STAGING_GROUP_IDS } from "_shared/utils";
import { UserContext } from "_shared/components/Auth";
import { SelectField } from "_shared/components/Field";

export const getPreferredGroupId = () => window.localStorage.getItem("groupId");
export const setPreferredGroupId = (id: string) =>
  window.localStorage.setItem("groupId", id);
export const deletePreferredGroupId = () =>
  window.localStorage.removeItem("groupId");

const ChooseGroup: FC = () => {
  const user = useContext(UserContext);
  const uid = user?.uid;
  const history = useHistory();
  const { groupId } = useParams<{ groupId: string | undefined }>();
  const [groupNamesById, setGroupNamesById] = useState<
    Record<string, string> | undefined
  >();

  useEffect(() => {
    if (!uid) return;
    const fetchGroups = async () => {
      const snapshot = await getDocs(
        query(
          collection(firestore, "groups"),
          where("users", "array-contains", uid)
        )
      );
      const newGroupNamesById: Record<string, string> = {};
      snapshot.docs.forEach((doc) => {
        const { name } = doc.data();
        if (IS_STAGING && !STAGING_GROUP_IDS.has(doc.id)) return;
        newGroupNamesById[doc.id] = name;
      });
      setGroupNamesById(newGroupNamesById);
    };
    fetchGroups();
  }, [uid]);

  const changeGroupId = (id: string) => {
    history.push(`/groups/${id}`);
    setPreferredGroupId(id);
  };

  if (!uid || !groupNamesById) return null;

  return (
    <SelectField
      label="Group"
      value={groupId}
      onChange={(e) => e.target.value && changeGroupId(e.target.value)}
    >
      <option value="">Choose a group...</option>
      {Object.entries(groupNamesById)
        .sort(([_, aName], [__, bName]) => aName.localeCompare(bName))
        .map(([id, name]) => {
          return (
            <option key={id} value={id}>
              {name}
            </option>
          );
        })}
    </SelectField>
  );
};

export default ChooseGroup;

export const RedirectToPreferredGroup: FC = () => {
  const preferredGroupId = getPreferredGroupId();
  if (preferredGroupId) {
    return <Redirect to={`/groups/${preferredGroupId}`} />;
  }
  return <Redirect to="/groups" />;
};
