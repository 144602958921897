import { FC } from "react";
import { IPatientEvent } from "_shared/models/Patient";
import Text from "_shared/components/Text";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "_shared/chart";
import Button, { ButtonGroup } from "_shared/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { encodeCsv } from "_shared/utils";
import { DownloadLink } from "_shared/components/DownloadLink";
import { useLocalize } from "_shared/localization/useLocalize";

interface MycawChartProps {
  events: IPatientEvent[];
  name?: string;
}

export const MycawChart: FC<MycawChartProps> = ({ events, name }) => {
  const mycawData: Array<{ x: Date; y: number }> = [];
  const mycawSpreadsheet: Array<Record<string, string>> = [];
  const localize = useLocalize();
  mycawSpreadsheet.push({
    date: localize("patients_events_mycaw_date"),
    respondent: localize("patients_events_mycaw_respondent"),
    location: localize("patients_events_mycaw_location"),
    concern1: localize("patients_events_mycaw_concern1"),
    concern1Rating: localize("patients_events_mycaw_concern1Rating"),
    concern2: localize("patients_events_mycaw_concern2"),
    concern2Rating: localize("patients_events_mycaw_concern2Rating"),
    wellbeingRating: localize("patients_events_mycaw_wellbeing"),
    otherThings: localize("patients_events_mycaw_otherThings"),
    mostImportant: localize("patients_events_mycaw_mostImportant"),
  });
  events.forEach((event) => {
    if (!event.mycaw) return;
    const {
      concern1,
      concern1Rating,
      concern2,
      concern2Rating,
      wellbeingRating,
      location,
      respondent,
      otherThings,
      mostImportant,
    } = event.mycaw ?? {};
    const recordedRatings = [
      concern1Rating,
      concern2Rating,
      wellbeingRating,
    ].filter((rating): rating is number => rating !== undefined);
    if (recordedRatings.length === 0) return;
    let sum = 0;
    for (const rating of recordedRatings) {
      sum += rating;
    }
    mycawData.push({
      x: new Date(event.date),
      y: sum / recordedRatings.length,
    });
    mycawSpreadsheet.push({
      date: event.date,
      respondent: respondent ?? "",
      location: location ?? "",
      concern1: concern1 ?? "",
      concern1Rating: concern1Rating?.toString() ?? "",
      concern2: concern2 ?? "",
      concern2Rating: concern2Rating?.toString() ?? "",
      wellbeingRating: wellbeingRating?.toString() ?? "",
      otherThings: otherThings ?? "",
      mostImportant: mostImportant ?? "",
    });
  });

  return (
    <>
      <Text variant="h2" id="mycaw">
        Measure Yourself Concerns and Wellbeing (MYCaW)
      </Text>
      <Line
        options={{
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: "week",
                tooltipFormat: "Do MMMM yyyy",
              },
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              reverse: true,
              suggestedMin: 0,
              suggestedMax: 6,
              ticks: {
                stepSize: 1.0,
                callback: (value) => {
                  switch (value) {
                    case 6:
                      return "6 (bad)";
                    case 0:
                      return "0 (good)";
                    default:
                      return value;
                  }
                },
              },
            },
          },
          plugins: {
            legend: {
              position: "top",
              align: "start",
            },
          },
        }}
        data={{
          datasets: [
            {
              label: "Average rating",
              borderColor: CHART_COLORS[0],
              pointBackgroundColor: CHART_COLORS[0],
              backgroundColor: "transparent",
              data: mycawData,
            },
          ],
        }}
      />
      <ButtonGroup>
        <DownloadLink
          filename={`${name ?? "U"} MYCaW.csv`}
          getDataUrl={() => {
            const csv = encodeCsv(mycawSpreadsheet, { header: false });
            return `data:text/csv,${encodeURIComponent(csv)}`;
          }}
        >
          <Button secondary>
            <FontAwesomeIcon icon={faFileCsv} />
            Download spreadsheet
          </Button>
        </DownloadLink>
      </ButtonGroup>
    </>
  );
};
