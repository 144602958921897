import {
  faDirections,
  faEnvelope,
  faExternalLinkAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import "_shared/css/PreviewService.css";
import { IService, SocialNetwork } from "_shared/models/Service";
import { ICONS_BY_NETWORK } from "_shared/components/Icons";
import Text from "_shared/components/Text";

interface PreviewServiceProps {
  service?: IService;
}

const PreviewService: FC<PreviewServiceProps> = ({ service }) => {
  if (!service) return null;
  const {
    name = "",
    description = "",
    link = "",
    number = "",
    contactName = "",
    email = "",
    address = "",
    pricing = "",
    social = {},
  } = service;
  return (
    <div className="preview-service">
      <Text variant="h2" className="preview-service__name">
        {name}
      </Text>
      <div className="preview-service__text">
        {description.split("\n").map((paragraph, i) => (
          <p key={i}>{paragraph}</p>
        ))}
      </div>
      <Text variant="h4">Pricing</Text>
      <div className="preview-service__text">
        {pricing === "" ? (
          <p>Free</p>
        ) : (
          pricing.split("\n").map((paragraph, i) => <p key={i}>{paragraph}</p>)
        )}
      </div>
      <Text variant="h4">Contact</Text>
      <ul className="preview-service__contact">
        {address && (
          <li>
            <a
              className="preview-service__contact-link"
              href={directionsLink(address)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faDirections}
                className="preview-service__contact-icon"
                fixedWidth
              />
              {address}
            </a>
          </li>
        )}
        {link && (
          <li>
            <a
              className="preview-service__contact-link"
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                className="preview-service__contact-icon"
                fixedWidth
              />
              {domainFromUrl(link)}
            </a>
          </li>
        )}
        {email && (
          <li>
            <a
              className="preview-service__contact-link"
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="preview-service__contact-icon"
                fixedWidth
              />
              {email}
            </a>
          </li>
        )}
        {number && (
          <li>
            <a
              className="preview-service__contact-link"
              href={telLink(number)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faPhone}
                className="preview-service__contact-icon"
                fixedWidth
              />
              {contactName && `${contactName}: `}
              {number}
            </a>
          </li>
        )}
        {Object.entries(social).map(([network, socialLink]) => (
          <li key={network}>
            <a
              className="preview-service__contact-link"
              href={socialLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={ICONS_BY_NETWORK[network as SocialNetwork]}
                className="preview-service__contact-icon"
                fixedWidth
              />
              {socialLink}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
export default PreviewService;

const telLink = (phoneNumber: string): string => {
  let link = phoneNumber.replace(/\s+/g, "");
  link = link.charAt(0) === "0" ? link.substring(1) : link;
  return "tel://+44" + link;
};

const directionsLink = (address: string): string => {
  return (
    "https://www.google.com/maps/dir/?api=1&destination=" +
    encodeURIComponent(address)
  );
};

const domainFromUrl = (text: string): string => {
  try {
    const url = new URL(text).hostname;
    return url;
  } catch (e) {
    return text;
  }
};
