import {
  ComponentProps,
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { useId } from "_shared/hooks";
import "_shared/css/Accordion.css";
import Button from "./Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

export const AccordionGroupContext = createContext<{
  readonly openId?: string;
  readonly onToggle: (id: string) => void;
}>({
  openId: undefined,
  onToggle: () => {
    throw new Error(
      "AccordionGroupContext.onToggle() was called outside <AccordionGroup>"
    );
  },
});

export const AccordionContext = createContext<{
  readonly isOpen: boolean;
  readonly toggle: () => void;
}>({
  isOpen: false,
  toggle: () => {
    throw new Error("AccordionContext.toggle() was called outside <Accordion>");
  },
});

export const AccordionGroup: FC = ({ children }) => {
  const [openId, setOpenId] = useState<string | undefined>();

  const onToggle = useCallback(
    (id: string) => {
      if (id === openId) {
        setOpenId(undefined);
      } else {
        setOpenId(id);
      }
    },
    [openId]
  );

  return (
    <AccordionGroupContext.Provider value={{ openId, onToggle }}>
      <div>{children}</div>
    </AccordionGroupContext.Provider>
  );
};

interface AccordionProps extends ComponentProps<"div"> {
  readonly summary?: ReactNode;
  readonly disabled?: boolean;
}

const Accordion: FC<AccordionProps> = ({
  summary,
  disabled,
  children,
  className,
  ...otherProps
}) => {
  const id = useId();
  const { openId, onToggle } = useContext(AccordionGroupContext);
  const isOpen = id === openId;

  const classes = ["accordion"];
  if (isOpen) classes.push(`${classes[0]}--open`);
  if (className) classes.push(className);

  const titleId = `${id}__title`;
  const detailsId = `${id}__details`;

  return (
    <AccordionContext.Provider value={{ isOpen, toggle: () => onToggle(id) }}>
      <div className={classes.join(" ")} {...otherProps}>
        <div className="accordion__summary">
          <Tippy content={<span>{isOpen ? "Close" : "Open"}</span>}>
            <Button
              className="accordion__toggle"
              secondary
              onClick={() => onToggle(id)}
              disabled={disabled}
              aria-labelledby={titleId}
              aria-expanded={isOpen}
              aria-controls={detailsId}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Button>
          </Tippy>
          <div className="accordion__title" id={titleId}>
            {summary}
          </div>
        </div>
        {isOpen && (
          <div className="accordion__details" id={detailsId}>
            {children}
          </div>
        )}
      </div>
    </AccordionContext.Provider>
  );
};

export default Accordion;
