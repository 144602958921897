import { FC } from "react";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "_shared/chart";

interface BMIChartProps {
  dataset: Array<{ x: Date; y: number }>;
}

export const BMIChart: FC<BMIChartProps> = ({ dataset }) => {
  return (
    <Line
      options={{
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: "day",
              tooltipFormat: "Do MMMM yyyy",
            },
            grid: {
              display: false,
            },
          },
          y: {
            type: "linear",
            suggestedMin: 15,
            suggestedMax: 40,
            ticks: {
              stepSize: 0.5,
              autoSkip: false,
              callback: (value) => {
                const num = parseFloat(value as string);
                switch (num) {
                  case 40:
                    return `Morbidly obese ${num}`;
                  case 30:
                    return `Obese ${num}`;
                  case 25:
                    return `Overweight ${num}`;
                  case 18.5:
                    return `Normal ${num}`;
                  default:
                    if (num % 5 === 0) return num;
                    return null;
                }
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
        },
      }}
      data={{
        datasets: [
          {
            label: "BMI",
            borderColor: CHART_COLORS[0],
            pointBackgroundColor: CHART_COLORS[0],
            backgroundColor: "transparent",
            data: dataset,
          },
        ],
      }}
    />
  );
};
