import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import { config as fontawesomeConfig } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "tippy.js/dist/tippy.css";
import Groups from "./groups/Groups";
import Group from "./groups/Group";
import Auth from "_shared/components/Auth";
import reportWebVitals from "./reportWebVitals";
import AuthAction from "_shared/components/AuthAction";
import "_shared/css/index.css";
import PublicHeader from "./public/PublicHeader";
import PublicGroup from "./public/PublicGroup";
import { IS_STAGING, STAGING_GROUP_IDS } from "_shared/utils";
import { RedirectToPreferredGroup } from "./groups/ChooseGroup";
import ShelfServiceManagement from "./services/ShelfServiceManagement";
import "_shared/chart";
import { DynamicLinksProvider } from "_shared/components/DynamicLinks";
import { LocalizationProvider as MuiLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "_shared/localization/LocalizationProvider";
import { FeatureFlagsProvider } from "_shared/FeatureFlagsContext";

fontawesomeConfig.autoAddCss = false;

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider locale="en">
      <DynamicLinksProvider>
        <MuiLocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Switch>
              <Route path="/auth-action">
                <PublicHeader />
                <AuthAction />
              </Route>
              <Route path="/public/groups/:groupId">
                <PublicGroup />
              </Route>
              <Route>
                <Auth>
                  <Switch>
                    <Route path="/services">
                      <ShelfServiceManagement />
                    </Route>
                    <Route
                      path="/groups/:groupId"
                      render={({ match }) => {
                        const { groupId } = match.params;
                        if (IS_STAGING && !STAGING_GROUP_IDS.has(groupId)) {
                          return (
                            <p>
                              Unavailable on staging site.{" "}
                              <Link to="/groups">Choose group</Link>
                            </p>
                          );
                        }
                        return (
                          <FeatureFlagsProvider groupId={groupId}>
                            <LocalizationProvider locale="en">
                              <Group />
                            </LocalizationProvider>
                          </FeatureFlagsProvider>
                        );
                      }}
                    />
                    <Route path="/groups">
                      <PublicHeader />
                      <Groups />
                    </Route>
                    <Route path="/">
                      <RedirectToPreferredGroup />
                    </Route>
                  </Switch>
                </Auth>
              </Route>
            </Switch>
          </BrowserRouter>
        </MuiLocalizationProvider>
      </DynamicLinksProvider>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
