import { forwardRef, HTMLAttributes } from "react";
import "_shared/css/Text.css";

interface TextProps extends HTMLAttributes<unknown> {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "link"
    | "small"
    | "smallLight"
    | "smallDanger"
    | "body";
}

const Text = forwardRef<HTMLElement, TextProps>(
  ({ variant, className, children, ...otherProps }, ref) => {
    const classes = ["text"];
    classes.push(`text--${variant}`);
    if (className) classes.push(className);

    let Tag: keyof JSX.IntrinsicElements = "span";
    let tagProps = {};
    switch (variant) {
      case "link":
        Tag = "button";
        tagProps = { type: "button" };
        break;
      case "small":
      case "smallLight":
      case "smallDanger":
        Tag = "small";
        break;
      case "h1":
        Tag = "h1";
        break;
      case "h2":
        Tag = "h2";
        break;
      case "h3":
        Tag = "h3";
        break;
      case "h4":
        Tag = "h4";
        break;
      case "body":
        Tag = "p";
    }

    return (
      <Tag
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={ref as any}
        {...tagProps}
        className={classes.join(" ")}
        {...otherProps}
      >
        {children}
      </Tag>
    );
  }
);

export default Text;
