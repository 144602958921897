import { FC, ReactNode } from "react";
import "_shared/css/ListTile.css";

interface ListTileProps {
  icon?: ReactNode;
  title?: ReactNode;
}

/**
 * Usually wrapped in a `NavLink`
 */
const ListTile: FC<ListTileProps> = ({ icon, title }) => {
  return (
    <div className="list-tile">
      <div className="list-tile__icon">{icon}</div>
      <div className="list-tile__title">{title}</div>
    </div>
  );
};

export default ListTile;
