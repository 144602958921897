import { FC, memo, ReactNode, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { Label, Input, Select } from "_shared/components/Field";
import TagSelect, {
  OptionGroup,
  useDemographicsServiceFilters,
  useDraftServiceFilters,
  usePricingServiceFilters,
} from "_shared/components/TagSelect";
import Dot from "_shared/components/Dot";
import "_shared/css/ServicesSidebar.css";
import { useUrl } from "_shared/hooks";
import { isServiceStale } from "./Services";
import { IService } from "_shared/models/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBooks as falBooks,
  faPenToSquare,
} from "@fortawesome/pro-light-svg-icons";
import { localeSort } from "_shared/utils";
import ListTile from "_shared/components/ListTile";
import { faBookArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { Pagination, usePagination } from "_shared/components/Pagination";
import Text from "_shared/components/Text";

interface ServicesSidebarProps {
  servicesById?: Record<string, IService>;
  selectedIds?: Set<string>;
  onSelectedIdsChange?: (selectedIds: Set<string>) => void;
  renderService?: (service: IService, id: string) => ReactNode;
  disabled?: boolean;
  filterOptionGroups?: ReadonlyArray<OptionGroup>;
  matchFilters?: (service: IService, selectedFilters: Set<string>) => boolean;
}

const SORT = ["name", "old"] as const;
type Sort = (typeof SORT)[number];

const ServicesSidebar: FC<ServicesSidebarProps> = ({
  servicesById = {},
  selectedIds = new Set(),
  onSelectedIdsChange,
  renderService,
  disabled = false,
  filterOptionGroups = [],
  matchFilters = () => true,
}) => {
  const url = useUrl();
  const history = useHistory();
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(
    new Set()
  );
  const [rawQuery, setRawQuery] = useState<string>("");
  const [sort, setSort] = useState<Sort>("name");
  const [results, setResults] = useState<
    ReadonlyArray<IService & { id: string }>
  >([]);
  const pagination = usePagination({ items: results, pageSize: 50 });

  // Search, filter, and sort services after debouncing
  useEffect(() => {
    const debounceTimer = window.setTimeout(() => {
      const query = rawQuery.toLowerCase();
      setResults(
        Object.entries(servicesById)
          .filter(([_, service]) => {
            const matchesSearch =
              !query ||
              (service.name ?? "").toLowerCase().includes(query) ||
              (service.description ?? "").toLowerCase().includes(query);
            return matchesSearch && matchFilters(service, selectedFilters);
          })
          .map(([id, service]) => {
            return { ...service, id };
          })
          .sort((a, b) => {
            if (sort === "name") return localeSort(a.name, b.name);
            if (!a.lastEdited) return -1;
            if (!b.lastEdited) return 1;
            return a.lastEdited.getTime() - b.lastEdited.getTime();
          })
      );
    }, 250);
    return () => window.clearTimeout(debounceTimer);
  }, [matchFilters, rawQuery, selectedFilters, servicesById, sort]);

  const handleQueryChanged = (newQuery: string) => {
    if (!renderService) history.replace(url); // Clear current service ID from URL params
    setRawQuery(newQuery);
  };
  const handleFiltersChanged = (newFilters: Set<string>) => {
    if (!renderService) history.replace(url); // Clear current service ID from URL params
    setSelectedFilters(newFilters);
  };
  const toggleSelected = (serviceId: string) => {
    if (!onSelectedIdsChange) return;
    const selected = new Set(selectedIds);
    selected.has(serviceId)
      ? selected.delete(serviceId)
      : selected.add(serviceId);
    onSelectedIdsChange(selected);
  };

  return (
    <ServicesSidebarLayout
      disabled={disabled}
      filters={
        <div className="grid grid-cols-[min-content,auto] items-center gap-2">
          <Label htmlFor="services-search-filter">
            <small className="text text--smallLight">Search</small>
          </Label>
          <Input
            id="services-search-filter"
            value={rawQuery ?? ""}
            onChange={(e) => handleQueryChanged(e.target.value)}
          />
          <Label id="services-tag-filter">
            <small className="text text--smallLight">Filter</small>
          </Label>
          <TagSelect
            aria-labelledby="services-tag-filter"
            value={selectedFilters}
            optionGroups={filterOptionGroups}
            onChange={handleFiltersChanged}
            isDisabled={disabled}
          />
          <Label id="services-sort">
            <small className="text text--smallLight">Sort</small>
          </Label>
          <Select
            aria-labelledby="services-sort"
            value={sort}
            onChange={(e) => setSort(e.target.value as Sort)}
          >
            <option value="name">Name</option>
            <option value="old">Date edited</option>
          </Select>
        </div>
      }
      list={
        <>
          {pagination.page.map((service) => {
            const { id, name } = service;
            if (renderService)
              return renderService(
                { ...service, name: name ?? "New Service" },
                id
              );
            return (
              <NavLink key={id} to={`${url}/${id}`}>
                <ListTile
                  icon={<ServiceStatusIcon service={service} />}
                  title={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {onSelectedIdsChange && (
                        <div
                          className="mr-2 p-1"
                          onClick={(e) => {
                            e.preventDefault(); // Don't trigger enclosing link
                            toggleSelected(id);
                          }}
                        >
                          <Input
                            type="checkbox"
                            aria-label={`Select service: ${name}`}
                            checked={selectedIds.has(id)}
                            onChange={() => {}}
                          />
                        </div>
                      )}
                      <div style={{ flex: "1" }}>{name}</div>
                    </div>
                  }
                />
              </NavLink>
            );
          })}
          <ServicesSidebarStickyFooter>
            <Pagination {...pagination} />
          </ServicesSidebarStickyFooter>
        </>
      }
    />
  );
};

export default ServicesSidebar;

interface ServicesSidebarLayoutProps {
  disabled?: boolean;
  filters?: ReactNode;
  list?: ReactNode;
}

export const ServicesSidebarLayout: FC<ServicesSidebarLayoutProps> = ({
  disabled,
  filters,
  list = [],
}) => {
  return (
    <fieldset className="services-sidebar" disabled={disabled}>
      <div className="services-sidebar__filters">{filters}</div>
      <div className="services-sidebar__list">{list}</div>
    </fieldset>
  );
};

export const ServicesSidebarStickyFooter: FC = ({ children }) => {
  return (
    <div className="services-sidebar__sticky-footer">
      <hr style={{ alignSelf: "stretch", margin: 0 }} />
      {children}
    </div>
  );
};

export const ServicesSidebarWithAllFilters: FC<
  Omit<ServicesSidebarProps, "filterOptionGroups" | "matchFilters">
> = memo((props) => {
  const [demographicsFilterOptionGroups, matchDemographicsFilters] =
    useDemographicsServiceFilters();
  const [draftFilterOptions, matchDraftFilters] = useDraftServiceFilters();
  const [pricingFilterOptions, matchPricingFilters] =
    usePricingServiceFilters();

  const matchFilters = (service: IService, selectedFilters: Set<string>) => {
    return (
      matchDemographicsFilters(service, selectedFilters) &&
      matchDraftFilters(service, selectedFilters) &&
      matchPricingFilters(service, selectedFilters)
    );
  };

  return (
    <ServicesSidebar
      matchFilters={matchFilters}
      filterOptionGroups={[
        {
          label: "Is...",
          options: [...pricingFilterOptions, ...draftFilterOptions],
        },
        ...demographicsFilterOptionGroups,
      ]}
      {...props}
    />
  );
});

interface ServiceStatusIconProps {
  service?: IService;
}

const ServiceStatusIcon: FC<ServiceStatusIconProps> = ({ service }) => {
  const isHelpShelfService = service?._globalId !== undefined;
  const hiddenAfterUpdate = service?._hiddenAfterUpdate === true;
  return (
    <div className="services-status-icon">
      {hiddenAfterUpdate ? (
        <Tippy content="HelpShelf update available">
          <span style={{ color: "var(--danger)" }}>
            <FontAwesomeIcon icon={faBookArrowUp} />
          </span>
        </Tippy>
      ) : isHelpShelfService ? (
        <Tippy content="HelpShelf service">
          <span style={{ color: "var(--hotpink)" }}>
            <FontAwesomeIcon icon={falBooks} />
          </span>
        </Tippy>
      ) : service?.isHidden ? (
        <Tippy content="Draft">
          <Text variant="smallLight">
            <FontAwesomeIcon icon={faPenToSquare} />
          </Text>
        </Tippy>
      ) : service && isServiceStale(service) ? (
        <Tippy content="Edited over 3 months ago">
          <Dot color="red" className="services-status-icon__stale-dot" />
        </Tippy>
      ) : null}
    </div>
  );
};
