import {
  faChevronLeft,
  faFileCsv,
  faFileImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl, InputLabel, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { FC, useContext, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { CHART_COLORS } from "_shared/chart";
import { Report } from "_shared/models/_meta";
import { encodeCsv, localeSort } from "_shared/utils";
import Button, { ButtonGroup } from "_shared/components/Button";
import { GroupContext } from "../Group";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { DownloadLink } from "_shared/components/DownloadLink";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";

interface AssigneeReportProps {
  report: Report;
}

export const AssigneeReport: FC<AssigneeReportProps> = ({ report }) => {
  const localize = useLocalize();
  const group = useContext(GroupContext);
  const chartRef = useRef<ChartJSOrUndefined<"bar">>();
  const [filterFlag, setFilterFlag] = useState<string>();
  const [filterMonth, setFilterMonth] = useState<string>();
  const userProfiles = group?._userProfiles ?? {};
  const filterMonthDate = filterMonth ? new Date(filterMonth) : undefined;
  const patientCountByAssigneeEmail: Record<string, number> = {};
  for (const patient of Object.values(report.patients)) {
    const { assignee, flags, referralDate } = patient;
    if (!assignee || assignee in userProfiles === false) continue;
    if (filterFlag && !flags?.has(filterFlag)) continue;
    if (filterMonthDate) {
      if (!referralDate) continue;
      const date = new Date(referralDate);
      if (date.getFullYear() !== filterMonthDate?.getFullYear()) continue;
      if (date.getMonth() !== filterMonthDate?.getMonth()) continue;
    }
    const { email } = userProfiles[assignee];
    patientCountByAssigneeEmail[email] ??= 0;
    patientCountByAssigneeEmail[email]++;
  }
  const labels = Object.keys(patientCountByAssigneeEmail).sort(localeSort);

  return (
    <Wrapper wide pad>
      <div className="flex flex-col gap-8">
        <div className="my-6 flex flex-col gap-2">
          <Link className="self-start" to=".">
            <Button secondary>
              <FontAwesomeIcon icon={faChevronLeft} /> Reports
            </Button>
          </Link>
          <div className="flex items-center">
            <div className="flex-1">
              <Text variant="h1">Case Load</Text>
              <p>
                <LocalizedMessage id="reports_number" />
              </p>
            </div>
            <div className="flex items-center gap-2">
              {(filterFlag || filterMonth) && (
                <Button
                  secondary
                  onClick={() => {
                    setFilterFlag(undefined);
                    setFilterMonth(undefined);
                  }}
                >
                  Reset
                </Button>
              )}
              <FormControl className="w-52">
                <InputLabel htmlFor="tag">Tag</InputLabel>
                <Select
                  native
                  id="tag"
                  label="Tag"
                  value={filterFlag ?? ""}
                  onChange={(e) => setFilterFlag(e.target.value)}
                >
                  <option value=""></option>
                  {Object.entries(group?.patientFlags ?? {})
                    .sort(([_, aName], [__, bName]) => localeSort(aName, bName))
                    .map(([id, name]) => {
                      return (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl className="w-52">
                <DatePicker
                  label="Referral Date"
                  views={["year", "month"]}
                  openTo="year"
                  slotProps={{
                    actionBar: {
                      actions: ["clear"],
                    },
                  }}
                  value={filterMonth ?? ""}
                  onChange={(value) => setFilterMonth(value ?? undefined)}
                />
              </FormControl>
            </div>
          </div>
        </div>
        <Bar
          ref={chartRef}
          options={{
            indexAxis: "y",
            scales: {
              x: {
                beginAtZero: true,
                title: {
                  display: true,
                  text: localize("reports_number_of_patients"),
                },
                ticks: {
                  precision: 0,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
          data={{
            labels,
            datasets: [
              {
                label: localize("reports_number_of_patients"),
                backgroundColor: CHART_COLORS[0],
                data: labels.map((email) => patientCountByAssigneeEmail[email]),
              },
            ],
          }}
        />
        <ButtonGroup>
          <DownloadLink
            filename="case_load.png"
            getDataUrl={() =>
              chartRef.current?.toBase64Image("image/png") ?? ""
            }
          >
            <Button secondary>
              <FontAwesomeIcon icon={faFileImage} />
              Download image
            </Button>
          </DownloadLink>
          <DownloadLink
            filename="case_load.csv"
            getDataUrl={() => {
              const csv = encodeCsv(
                Object.entries(patientCountByAssigneeEmail)
              );
              return `data:text/csv,${encodeURIComponent(csv)}`;
            }}
          >
            <Button secondary>
              <FontAwesomeIcon icon={faFileCsv} />
              Download spreadsheet
            </Button>
          </DownloadLink>
        </ButtonGroup>
      </div>
    </Wrapper>
  );
};
