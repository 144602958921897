import { FC, useContext } from "react";
import {
  ONS4_STATEMENTS,
  ONS4_STEPS,
  calculateBmi,
  LONELINESS_STATEMENTS,
  KIDSCREEN_QUESTIONS,
  KIDSCREEN_STEPS,
  IPatientEvent,
} from "_shared/models/Patient";
import { BMIChart } from "./BMIChart";
import { GroupContext } from "../Group";
import Timeline, { TimelineEntry } from "_shared/components/Timeline";
import { useDayFormatter } from "_shared/hooks";
import Text from "_shared/components/Text";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "_shared/chart";
import { WellbeingChart } from "./WellbeingChart";
import { MycawChart } from "./MycawChart";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";
import { WarwickEdinburghChart } from "./WarwickEdinburghChart";
import { WarwickEdinburghTotalsChart } from "./WarwickEdinburghTotalsChart";

interface ProgressProps {
  eventsById: {
    [id: string]: IPatientEvent;
  };
  isKidscreenAvailable?: boolean;
  name?: string;
}

const Progress: FC<ProgressProps> = ({
  eventsById,
  isKidscreenAvailable = true,
  name,
}) => {
  const { isMycawEnabled } = useFeatureFlags();
  const dayFormatter = useDayFormatter();
  const group = useContext(GroupContext);
  const eventTypeNamesById = group?.patientEvents ?? {};
  //Sort events by oldest date first
  const events = Object.entries(eventsById || {})
    .map(([id, event]) => {
      return { ...event, id };
    })
    .sort((a, b) => Date.parse(a.date) - Date.parse(b.date));
  //Graph BMI over time
  const bmiDataset: Array<{ x: Date; y: number }> = [];
  let height: number;
  let weight: number;
  events.forEach((event) => {
    if (!event.height && !event.weight) return; //No point showing a data point where neither height nor weight changed
    if (event.height) height = event.height;
    if (event.weight) weight = event.weight;
    if (!height || !weight) return;
    bmiDataset.push({
      x: new Date(event.date),
      y: calculateBmi(height, weight),
    });
  });
  //Graph loneliness over time
  const lonelinessDataset: Array<{ x: Date; y: number }> = [];
  events.forEach((event) => {
    if (!event.hasOwnProperty("loneliness")) return;
    let score = 0;
    for (let i in LONELINESS_STATEMENTS) {
      if (event.loneliness?.[i] === 5) continue; //When the patient answers "Don't know", this shouldn't affect the score
      score += parseInt(`${event.loneliness?.[i] ?? 0}`);
    }
    lonelinessDataset.push({
      x: new Date(event.date),
      y: score,
    });
  });

  return (
    <div className="progress">
      <Text variant="h2" id="consultations">
        Consultations
      </Text>
      <Timeline>
        {[...events].reverse().map(({ id, date, type, notes }) => {
          return (
            <TimelineEntry
              key={id}
              overline={date && dayFormatter.format(new Date(date))}
              title={(type && eventTypeNamesById[type]) ?? "New Event"}
            >
              <p>{notes || "-"}</p>
            </TimelineEntry>
          );
        })}
      </Timeline>
      <hr />
      <Text variant="h2" id="bmi">
        BMI
      </Text>
      <BMIChart dataset={bmiDataset} />
      <hr />
      {isMycawEnabled ? (
        <>
          <MycawChart events={events} name={name} />
          <hr />
        </>
      ) : null}
      <Text variant="h2" id="warwick-edinburgh">
        Warwick-Edinburgh Mental Wellbeing
      </Text>
      <WarwickEdinburghTotalsChart
        events={events}
        eventTypeNamesById={eventTypeNamesById}
      />
      <WarwickEdinburghChart events={events} />
      <hr />
      <Text variant="h2" id="ons4">
        ONS4 Wellbeing
      </Text>
      <WellbeingChart
        statements={ONS4_STATEMENTS.map((statement) => statement.short)}
        steps={ONS4_STEPS}
        responsesByDate={Object.values(events)
          .filter((event) => event.hasOwnProperty("ons4"))
          .reduce((responsesByDate, event) => {
            return { ...responsesByDate, [event.date]: event["ons4"] };
          }, {})}
      />
      <hr />
      <Text variant="h2" id="loneliness">
        Loneliness
      </Text>
      <Line
        options={{
          scales: {
            x: {
              type: "time",
              time: {
                minUnit: "week",
                tooltipFormat: "Do MMMM yyyy",
              },
              grid: {
                display: false,
              },
            },
            y: {
              type: "linear",
              reverse: true,
              suggestedMin: 0,
              suggestedMax: 12,
              ticks: {
                stepSize: 0.5,
                callback: (value) => {
                  switch (value) {
                    case 12:
                      return `Most lonely (bad) ${value}`;
                    case 0:
                      return `Least lonely (good) ${value}`;
                    default:
                      return value;
                  }
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          datasets: [
            {
              label: "Loneliness",
              borderColor: CHART_COLORS[0],
              pointBackgroundColor: CHART_COLORS[0],
              backgroundColor: "transparent",
              data: lonelinessDataset,
            },
          ],
        }}
      />
      {isKidscreenAvailable ? (
        <>
          <hr />
          <Text variant="h2" id="kidscreen">
            Kidscreen
          </Text>
          <WellbeingChart
            statements={Object.keys(KIDSCREEN_QUESTIONS).map((question) =>
              question.replace(/(have your?|How would) /i, "").replace("?", "")
            )}
            steps={["bad", "", "", "", "good"]}
            responsesByDate={Object.values(events)
              .filter((event) => "kidscreen" in event)
              .reduce((responsesByDate, { date, kidscreen }) => {
                return {
                  ...responsesByDate,
                  [date]: {
                    ...kidscreen,
                    // Questions 3 & 4 are inverted (seldom lonely = good)
                    2:
                      kidscreen === undefined || kidscreen[2] === undefined
                        ? undefined
                        : KIDSCREEN_STEPS.FREQUENCY.length - 1 - kidscreen[2],
                    3:
                      kidscreen === undefined || kidscreen[3] === undefined
                        ? undefined
                        : KIDSCREEN_STEPS.FREQUENCY.length - 1 - kidscreen[3],
                  },
                };
              }, {})}
          />
        </>
      ) : null}
    </div>
  );
};
export default Progress;
