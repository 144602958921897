import {
  ChangeEvent,
  ComponentProps,
  FC,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { nanoid } from "nanoid";
import ReactSelect, { GroupBase, Props } from "react-select";
import "_shared/css/Field.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { localeSort } from "_shared/utils";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";

export interface IOption {
  value: string;
  label: string;
}

//Converts map like { key: value } to array of options like: [ { 'value': key, 'label': value } ]
export const optionsFromMap = (
  record: Record<string, string>
): Array<IOption> => {
  return Object.entries(record).map<IOption>(([value, label]) => {
    return { value, label };
  });
};

//Converts array of strings like [ option1, option2 ] to array of options like : [ { 'value': option1, 'label': option1 }, ... ]
export const optionsFromArray = (
  array: ReadonlyArray<string>
): Array<IOption> => {
  return array.map((value) => {
    return { value, label: value };
  });
};

export const sortOptionsByLabel = (
  a: { label: string },
  b: { label: string }
) => {
  return localeSort(a.label, b.label);
};

/**
 * Allows an input to render changes locally, before committing the changes
 * to its `onChange` handler on blur or after a delay.
 *
 * @returns props to pass to an <input>
 */
export const useDebouncedInputProps = (
  value: string,
  onChange: (e: ChangeEvent<HTMLInputElement>) => void,
  wait = 1000
) => {
  const [delayedEvent, setDelayedEvent] = useState<
    ChangeEvent<HTMLInputElement> | undefined
  >();
  const timer = useRef<number | undefined>();

  // When value changes, clear local value
  useEffect(() => {
    setDelayedEvent(undefined);
  }, [value]);

  // Show changes to the user immediately, but debounce call to onChange
  const localOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDelayedEvent(e);
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(() => {
        onChange(e);
        setDelayedEvent(undefined);
      }, wait);
    },
    [onChange, wait]
  );

  // Flush delayed event immediately on blur
  const onBlur = useCallback(() => {
    if (delayedEvent) {
      window.clearTimeout(timer.current);
      onChange(delayedEvent);
      setDelayedEvent(undefined);
    }
  }, [delayedEvent, onChange]);

  return {
    value: delayedEvent?.target?.value ?? value ?? "",
    onChange: localOnChange,
    onBlur,
  };
};

export interface FieldProps {
  className?: string;
  short?: boolean;
  wide?: boolean;
}

export const Field: FC<FieldProps> = (props) => {
  const { children, className, short = false, wide = false } = props;
  const classes = ["field"];
  if (className) classes.push(className);
  if (wide) classes.push(`${classes[0]}--wide`);
  if (short) classes.push(`${classes[0]}--short`);
  return <div className={classes.join(" ")}>{children}</div>;
};

interface LabelProps extends ComponentProps<"label"> {
  strong?: boolean;
}

export const Label: FC<LabelProps> = (props) => {
  const { children, strong = false, className, ...otherProps } = props;
  const classes = ["label"];
  if (strong) classes.push(`${classes[0]}--strong`);
  if (className) classes.push(className);
  return (
    <label className={classes.join(" ")} {...otherProps}>
      {children}
    </label>
  );
};

interface InputProps
  extends Omit<ComponentProps<"input">, "value" | "checked"> {
  isSilent?: boolean;
}
export interface StringInputProps extends InputProps {
  value: string;
}

export interface CheckableInputProps extends InputProps {
  checked: boolean;
}

export interface FileInputProps extends InputProps {
  type: "file";
}

export const Input = forwardRef<
  HTMLInputElement,
  StringInputProps | CheckableInputProps | FileInputProps
>((props, ref) => {
  const {
    type = "text",
    className,
    placeholder = " ",
    isSilent,
    ...otherProps
  } = props;
  const classes = ["input"];
  if (className) classes.push(className);
  if (isSilent) classes.push(`${classes[0]}--silent`);
  return (
    <input
      ref={ref}
      type={type}
      className={classes.join(" ")}
      placeholder={placeholder}
      {...otherProps}
    />
  );
});

export interface SelectProps extends ComponentProps<"select"> {
  stretch?: boolean;
}

export const Select: FC<SelectProps> = (props) => {
  const { children, className, stretch, ...otherProps } = props;
  const classes = ["select"];
  if (className) classes.push(className);
  if (stretch) classes.push(`${classes[0]}--stretch`);
  return (
    <span className={classes.join(" ")}>
      <select className="select__select" {...otherProps}>
        {children}
      </select>
      <FontAwesomeIcon className="select__icon" icon={faChevronDown} />
    </span>
  );
};

interface OutputProps extends ComponentProps<"output"> {}

export const Output: FC<OutputProps> = (props) => {
  const { className, children, ...otherProps } = props;
  const classes = ["output"];
  if (className) classes.push(className);
  return (
    <output className={classes.join(" ")} {...otherProps}>
      {children}
    </output>
  );
};

interface TextFieldProps extends ComponentProps<"input">, FieldProps {
  value: string;
  invalidHelp?: string;
  label?: ReactNode;
  leading?: ReactNode;
  trailing?: ReactNode;
}

export const TextField = forwardRef<
  HTMLInputElement,
  Omit<TextFieldProps, "ref">
>((props, ref) => {
  const {
    label,
    type = "text",
    id = nanoid(),
    leading,
    trailing,
    invalidHelp,
    value,
    maxLength,
    minLength,
    short,
    wide,
    className,
    ...otherProps
  } = props;
  const classes = ["textfield"];
  if (leading) classes.push(`${classes[0]}--with-leading`);
  if (trailing) classes.push(`${classes[0]}--with-trailing`);
  if (className) classes.push(className);
  return (
    <Field short={short} wide={wide} className={classes.join(" ")}>
      {label && (
        <Label strong htmlFor={id}>
          {label}
        </Label>
      )}
      {leading && <span className="textfield__leading">{leading}</span>}
      <Input
        ref={ref}
        className="textfield__input"
        id={id}
        type={type}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        {...otherProps}
      />
      {trailing && <span className="textfield__trailing">{trailing}</span>}
      <div className="textfield__hints">
        {invalidHelp && (
          <small className="textfield__invalid-help">{invalidHelp}</small>
        )}
        {(maxLength || minLength) && (
          <small className="textfield__character-count">
            {(value as string)?.length || 0}
            {maxLength && ` / ${maxLength}`}
            {minLength && ` (minimum ${minLength})`}
          </small>
        )}
      </div>
    </Field>
  );
});

interface TextFieldClearButtonProps extends ComponentProps<"button"> {}

export const TextFieldClearButton: FC<TextFieldClearButtonProps> = (props) => {
  return (
    <button className="textfield__clear" type="button" {...props}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );
};

interface DebouncedTextFieldProps extends Omit<TextFieldProps, "ref"> {
  wait?: number;
}

export const DebouncedTextField: FC<DebouncedTextFieldProps> = ({
  wait = 1000,
  value,
  onChange,
  ...otherProps
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const debouncedInputProps = useDebouncedInputProps(
    value,
    (e) => {
      onChange?.(e);
      //Keep element in scroll view after change
      window.setTimeout(
        () =>
          ref.current?.scrollIntoView({
            block: "center",
          }),
        0
      );
    },
    wait
  );
  return <TextField ref={ref} {...debouncedInputProps} {...otherProps} />;
};

interface TextareaProps extends ComponentProps<"textarea"> {}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (props, ref) => {
    const { className, ...otherProps } = props;
    const classes = ["input"];
    if (className) classes.push(className);
    return <textarea ref={ref} className={classes.join(" ")} {...otherProps} />;
  }
);

interface TextareaFieldProps extends Omit<TextareaProps, "ref"> {
  label?: ReactNode;
}

export const TextareaField: FC<TextareaFieldProps> = (props) => {
  const { label, id = nanoid(), ...otherProps } = props;
  return (
    <Field wide>
      {label && (
        <Label strong htmlFor={id}>
          {label}
        </Label>
      )}
      <Textarea className="input" id={id} {...otherProps} />
    </Field>
  );
};

interface RadioFieldProps {
  options: ReadonlyArray<{
    value: string;
    label: ReactNode;
  }>;
  value?: string;
  onChange: (value: string) => void;
  label: ReactNode;
  name?: string;
}

export const RadioField: FC<RadioFieldProps> = (props) => {
  const {
    label,
    value: groupValue,
    options,
    onChange,
    name = nanoid(),
  } = props;
  const labelId = `${name}_label`;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <Field wide className="radiofield">
      <Label strong id={labelId}>
        {label}
      </Label>
      <div
        className="radiofield__options"
        role="radiogroup"
        aria-labelledby={labelId}
      >
        {options.map(({ value, label }) => {
          return (
            <Label key={value} className="radiofield__option">
              <Input
                type="radio"
                className="radiofield__input"
                name={name}
                value={value}
                checked={value === groupValue}
                onChange={handleChange}
              />
              {label}
            </Label>
          );
        })}
      </div>
    </Field>
  );
};

interface CheckboxFieldProps extends Omit<FieldProps, "onChange"> {
  options: ReadonlyArray<{
    value: string;
    label: ReactNode;
  }>;
  value: Set<string>;
  onChange: (value: Set<string>) => void;
  label: ReactNode;
  name?: string;
  onToggleAll?: (value: boolean) => void;
}

export const CheckboxField: FC<CheckboxFieldProps> = (props) => {
  const {
    value: checkedValues,
    label,
    onChange,
    onToggleAll,
    options,
    name = nanoid(),
    ...fieldProps
  } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = e.target;
      const update = new Set<string>(checkedValues);
      checked ? update.add(value) : update.delete(value);
      onChange(update);
    },
    [checkedValues, onChange]
  );

  return (
    <Field className="checkboxfield" {...fieldProps}>
      <Label strong>{label}</Label>
      <div className="checkboxfield__options">
        {onToggleAll && (
          <Label key="all" className="checkboxfield__option">
            <Input
              type="checkbox"
              name={name}
              value="all"
              checked={options.every(({ value }) => checkedValues.has(value))}
              onChange={(e) => onToggleAll(e.target.checked)}
            />
            <small>All</small>
          </Label>
        )}
        {options.map(({ value, label }) => {
          return (
            <Label key={value} className="checkboxfield__option">
              <Input
                type="checkbox"
                name={name}
                value={value}
                checked={checkedValues.has(value)}
                onChange={handleChange}
              />
              {label}
            </Label>
          );
        })}
      </div>
    </Field>
  );
};

interface OutputFieldProps extends ComponentProps<"output"> {
  label?: ReactNode;
}

export const OutputField: FC<OutputFieldProps> = (props) => {
  const { children, label, id = nanoid(), ...otherProps } = props;
  return (
    <Field className="outputfield">
      {label && (
        <Label strong htmlFor={id}>
          {label}
        </Label>
      )}
      <Output className="outputfield__output" id={id} {...otherProps}>
        = {children}
      </Output>
    </Field>
  );
};

interface SelectFieldProps extends Omit<ComponentProps<"select">, "ref"> {
  label?: ReactNode;
}

export const SelectField: FC<SelectFieldProps> = (props) => {
  const { children, label, id = nanoid(), ...otherProps } = props;
  return (
    <Field className="selectfield">
      {label && (
        <Label strong htmlFor={id}>
          {label}
        </Label>
      )}
      <Select stretch id={id} className="selectfield__select" {...otherProps}>
        {children}
      </Select>
    </Field>
  );
};

interface DiscreteRangeInputProps {
  steps: ReadonlyArray<ReactNode>;
  value?: number;
  onChange: (value: number) => void;
  id?: string;
  label?: ReactNode;
}

export const DiscreteRangeInput: FC<DiscreteRangeInputProps> = (props) => {
  const { label, value, steps, onChange, id = nanoid() } = props;
  if (value && (value < 0 || value > steps.length))
    throw new Error(
      `DiscreteRangeInput.value (${value}) is outside range of steps (0 - ${
        steps.length - 1
      })`
    );
  const listId = nanoid();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt(e.target.value, 10));
    },
    [onChange]
  );

  return (
    <div className="discrete-range-input">
      {label && <Label htmlFor={id}>{label}</Label>}
      <div className="discrete-range-input__row">
        <Input
          type="range"
          className="discrete-range-input__input"
          id={id}
          min="0"
          max={steps.length - 1}
          step="1"
          list={listId}
          onChange={handleChange}
          value={value?.toString() ?? ""}
        />
        <small className="discrete-range-input__output">
          <output htmlFor={id}>
            {value === undefined ? "-" : steps[value]}
          </output>
        </small>
        <datalist id={listId}>
          {" "}
          {/* Enables tick marks */}
          {steps.map((_, i) => (
            <option value={i} key={i}></option>
          ))}
        </datalist>
      </div>
    </div>
  );
};

export const SearchableSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  return (
    <ReactSelect
      styles={{
        indicatorsContainer: (provided) => ({
          ...provided,
          // Move chevron-down icon left of clear icon
          ...(props.isClearable ? { flexDirection: "row-reverse" } : {}),
        }),
      }}
      {...props}
    />
  );
};
