import { FC, useEffect, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

interface ScrollableProps {
  behavior?: "auto" | "smooth";
  className?: string;
}

const Scrollable: FC<ScrollableProps> = ({
  behavior = "smooth",
  children,
  className,
}) => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const renderPathLength = path
    .split("/")
    .filter((component) => component).length;
  const ref = useRef<HTMLDivElement | null>(null);

  //Scroll on mount
  useEffect(() => {
    const { hash } = history.location;
    if (hash) scrollToElementByHash(hash);
  }, [history.location]); // history.location does not change

  //Scroll when location changes
  useEffect(() => {
    const unlisten = history.listen((location) => {
      const { hash, pathname } = location;
      if (!ref.current) return;
      //If this is rendered at /foo, we only scroll when we go to /bar — not /foo/bar
      const newPathLength = pathname
        .split("/")
        .filter((component) => component).length;
      if (newPathLength > renderPathLength + 1) return;
      if (hash) {
        scrollToElementByHash(hash);
      } else {
        //Scroll to top
        ref.current.scrollTo(0, 0);
      }
    });
    return unlisten;
  }, [history, renderPathLength, ref]);

  return (
    <div
      ref={ref}
      className={`${className} max-h-full flex-1 overflow-y-auto`}
      style={{ scrollBehavior: behavior }}
    >
      {children}
    </div>
  );
};
export default Scrollable;

const scrollToElementByHash = (hash: string) => {
  //Scroll to element by hash #id after render - https://stackoverflow.com/a/40280486
  setTimeout(() => {
    document.getElementById(hash.substring(1))?.scrollIntoView();
  }, 0);
};
