import { FC, useContext } from "react";
import { GroupContext } from "../Group";
import Timeline, { TimelineEntry } from "_shared/components/Timeline";
import "_shared/css/ActivityLog.css";
import { useDayFormatter } from "_shared/hooks";
import Text from "_shared/components/Text";
import { IPatientEdit, IPatientEvent } from "_shared/models/Patient";
import { IS_DEV } from "_shared/utils";

interface ActivityLogProps {
  edits: Map<string, IPatientEdit>;
  eventsById: Record<string, IPatientEvent>;
}

const ActivityLog: FC<ActivityLogProps> = ({ edits, eventsById }) => {
  const dayFormatter = useDayFormatter();
  const group = useContext(GroupContext);
  const eventTypeNamesById = group?.patientEvents || {};
  //Combine edits made on the same day into single objects
  const editsByDay = new Map<string, IPatientEdit>(); //maps maintain insertion order, so the edits will remain sorted
  edits.forEach((edit) => {
    const day = dayFormatter.format(edit._time);
    const priorEdits = editsByDay.get(day) || {};
    editsByDay.set(day, { ...priorEdits, ...edit });
  });
  //Add the relevant titles to days with events
  const titles: Record<string, string> = {};
  Object.values(eventsById).forEach((event) => {
    const { type, date } = event;
    const eventName = type ? eventTypeNamesById[type] : undefined;
    if (!eventName) return;
    const day = dayFormatter.format(new Date(date));
    titles[day] = eventName;
  });
  //Add 'today' to titles
  const today = dayFormatter.format(new Date());
  titles[today] = `${titles[today] ? titles[today] + " — " : ""}Today`;

  return (
    <Timeline>
      {Array.from(editsByDay)
        .reverse()
        .map(([day, edits]) => {
          //Edit log, newest to oldest
          return (
            <Edits key={day} day={day} edits={edits} title={titles[day]} />
          );
        })}
    </Timeline>
  );
};

export default ActivityLog;

interface EditsProps {
  day: string;
  edits: IPatientEdit;
  title: string;
}

const Edits: FC<EditsProps> = ({ day, edits, title }) => {
  const group = useContext(GroupContext);
  const userProfiles = group?._userProfiles || {};
  const userEmail = userProfiles[edits._uid]?.email ?? "Unknown user";
  const fields = Object.keys(edits).filter((field) => !field.startsWith("_")); // Ignore _time, _uid
  fields.sort(new Intl.Collator("en").compare);
  return (
    <TimelineEntry overline={day} title={title || "Edits"}>
      <ul className="activity-log__fields">
        {fields
          .filter((field) => {
            //Ignore nested goals and events for now
            if (field.startsWith("goals.")) return false;
            if (field.startsWith("events.")) return false;
            return true;
          })
          .map((field) => {
            return (
              <li key={field} className="activity-log__field">
                <HumanReadableField
                  field={field}
                  value={edits[field] as string}
                />
              </li>
            );
          })}
        <li>
          <Text variant="smallLight">Last edit by {userEmail}</Text>
        </li>
      </ul>
    </TimelineEntry>
  );
};

interface HumanReadableFieldProps {
  field: string;
  value: string; // Non-string values are ignored
}

const HumanReadableField: FC<HumanReadableFieldProps> = ({ field, value }) => {
  const group = useContext(GroupContext);
  const userProfiles = group?._userProfiles || {};
  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  function format(timestamp: string): string {
    let date;
    try {
      date = formatter.format(Date.parse(timestamp));
    } catch (e) {
      date = "Invalid date";
    }
    return date;
  }
  switch (field) {
    case "nhsNumber":
      return (
        <>
          <strong>NHS number</strong>: <em>{value}</em>
        </>
      );
    case "firstName":
      return (
        <>
          <strong>First name</strong>: <em>{value}</em>
        </>
      );
    case "surname":
      return (
        <>
          <strong>Surname:</strong> <em>{value}</em>
        </>
      );
    case "knownAs":
      return (
        <>
          <strong>Known As:</strong> <em>{value}</em>
        </>
      );
    case "dateOfBirth":
      return (
        <>
          <strong>Date of birth:</strong> <em>{format(value)}</em>
        </>
      );
    case "dateOfDeath":
      return (
        <>
          <strong>Date of death:</strong> <em>{format(value)}</em>
        </>
      );
    case "height":
      return (
        <>
          <strong>Height:</strong> <em>{value} m</em>
        </>
      );
    case "weight":
      return (
        <>
          <strong>Weight:</strong> <em>{value} kg</em>
        </>
      );
    case "gender":
      return (
        <>
          <strong>Gender:</strong> <em>{value}</em>
        </>
      );
    case "ethnicity":
      return (
        <>
          <strong>Ethnicity:</strong> <em>{value}</em>
        </>
      );
    case "ethnicityDetails":
      return (
        <>
          <strong>Ethnicity description:</strong> <em>{value}</em>
        </>
      );
    case "address":
      return (
        <>
          <strong>Address:</strong> <em>{value}</em>
        </>
      );
    case "employment":
      return (
        <>
          <strong>Employment status:</strong> <em>{value}</em>
        </>
      );
    case "housing":
      return (
        <>
          <strong>Housing:</strong> <em>{value}</em>
        </>
      );
    case "livesWith":
      return (
        <>
          <strong>Living:</strong> <em>{value}</em>
        </>
      );
    case "alcohol":
      return (
        <>
          <strong>Alcohol consumption:</strong> <em>{value}</em>
        </>
      );
    case "smoking":
      return (
        <>
          <strong>Smoking:</strong> <em>{value}</em>
        </>
      );
    case "notes":
      return (
        <>
          <strong>Notes:</strong>
          <br />
          <em>{value}</em>
        </>
      );
    case "hopes":
      return (
        <>
          <strong>Changes:</strong>
          <br />
          <em>{value}</em>
        </>
      );
    case "barriers":
      return (
        <>
          <strong>Barriers:</strong>
          <br />
          <em>{value}</em>
        </>
      );
    case "support":
      return (
        <>
          <strong>Support:</strong>
          <br />
          <em>{value}</em>
        </>
      );
    case "warwickEdinburgh":
      return (
        <>
          <strong>Warwick-Edinburgh</strong> scores were changed
        </>
      );
    case "ons4":
      return (
        <>
          <strong>ONS4</strong> scores were changed
        </>
      );
    case "loneliness":
      return (
        <>
          <strong>Loneliness</strong> scores were changed
        </>
      );
    case "referralDate":
      return (
        <>
          <strong>Referral date:</strong> <em>{value}</em>
        </>
      );
    case "referrerType":
      return (
        <>
          <strong>Referrer</strong> was changed
        </>
      );
    case "referrerName":
      return (
        <>
          <strong>Name of referrer:</strong> <em>{value}</em>
        </>
      );
    case "referralReasons":
      return (
        <>
          <strong>Referral reasons</strong> were changed
        </>
      );
    case "referralSupport":
      return (
        <>
          <strong>Level of support:</strong> <em>{value}</em>
        </>
      );
    case "referralNotes":
      return (
        <>
          <strong>Referral notes:</strong> <em>{value}</em>
        </>
      );
    case "reminder":
      return (
        <>
          <strong>Reminder:</strong>{" "}
          <em>{new Date(value).toLocaleDateString()}</em>
        </>
      );
    case "assignee":
      const email = userProfiles[value]?.email;
      if (email)
        return (
          <>
            <strong>Assignee:</strong> <em>{email}</em>
          </>
        );
      return (
        <>
          <strong>Assignee</strong> was changed
        </>
      );
    case "lat":
    case "lng":
    case "postcode":
      break; //ignore
    case "phone":
      return (
        <>
          <strong>Phone:</strong> <em>{value}</em>
        </>
      );
    case "email":
      return (
        <>
          <strong>Email:</strong> <em>{value}</em>
        </>
      );
    default:
      if (IS_DEV)
        console.error(`<HumanReadableField> does not support field: ${field}`);
  }
  return <>Other fields were changed</>;
};
