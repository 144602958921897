export { unparse as encodeCsv } from "papaparse";

export const IS_DEV = process.env.REACT_APP_ENV === "development";
export const IS_STAGING = process.env.REACT_APP_ENV === "staging";
/**
 * Groups which are accessible in staging environment
 */
export const STAGING_GROUP_IDS = new Set([
  "mQAY932TNj63gm5b7P72",
  "VQnq1EU2HeFzBzdzM8q4",
]);

export const localeSort = (a?: string, b?: string) => {
  // Send undefined items to the end
  if (!a) return 1;
  if (!b) return -1;
  return a.localeCompare(b, "en-GB", { numeric: true });
};

export type Nullable<T> = { [K in keyof T]: T[K] | null };

/**
 * @example
 * Given a number in range 0-3 (length 4), inverts the number such that:
 *
 * 0 -> 3
 * 1 -> 2
 * 2 -> 1
 * 3 -> 0
 *
 * @param value a number in range `0` - {@link length} (exclusive)
 * @param length the number of values in range
 * @returns the inverted number
 */
export const invertInRange = (value: number, length: number): number => {
  return length - value - 1; // minus 1 because range is exclusive of `length`
};

export function intersection<T>(a: Set<T>, b: Set<T>): Set<T> {
  const _intersection = new Set<T>();
  b.forEach((item) => {
    if (a.has(item)) {
      _intersection.add(item);
    }
  });
  return _intersection;
}

export function formatDate(date: Date): string {
  const formatter = new Intl.DateTimeFormat("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  return formatter.format(date);
}

export const getMonthNames = () => {
  const months = [];
  for (let i = 0; i < 12; i++) {
    months.push(new Date(1970, i).toLocaleString("en-GB", { month: "long" }));
  }
  return months;
};

export const addOpacity = (hexColor: string, opacity: number) => {
  // Clamp to range 0->1
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return hexColor + _opacity.toString(16).toUpperCase();
};
