import {
  faChevronLeft,
  faFileCsv,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchAnalytics } from "_shared/api";
import Button from "_shared/components/Button";
import { DownloadLink } from "_shared/components/DownloadLink";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import { encodeCsv } from "_shared/utils";
import { GroupContext } from "groups/Group";
import { useLocationsQuery } from "groups/Group.hooks";
import { FC, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { sub } from "date-fns";

interface Analytics {
  metricsByLocationId?: {
    pageviews: number;
    visitors: number;
    locationId: string;
  }[];
  metricsByServiceId?: {
    pageviews: number;
    visitors: number;
    serviceId: string;
  }[];
}

interface Props {
  groupId: string;
}

export const AnalyticsReport: FC<Props> = ({ groupId }) => {
  const locations = useLocationsQuery(groupId);
  const group = useContext(GroupContext);
  const servicesById = group?.services ?? {};
  const analytics = useAnalyticsQuery(groupId);
  const locationNamesById =
    Object.fromEntries(
      locations?.map((location) => [location.id, location.name]) ?? []
    ) ?? {};
  const currentDate = new Date();
  const startDate = new Date(sub(currentDate, { years: 1 }));

  return (
    <Wrapper wide pad>
      <div className="flex flex-col gap-6 py-6">
        <div className="flex flex-col gap-2">
          <Link className="self-start" to=".">
            <Button secondary>
              <FontAwesomeIcon icon={faChevronLeft} /> Reports
            </Button>
          </Link>
          <Text variant="h1">App Analytics</Text>
        </div>
        <p>
          From {startDate.toLocaleDateString("en-CA")} to{" "}
          {currentDate.toLocaleDateString("en-CA")}
        </p>
        {analytics !== null ? (
          <>
            <Text variant="h2">Locations</Text>
            {analytics?.metricsByLocationId?.map((location) => {
              return (
                <div key={location.locationId}>
                  <Link
                    to={`/groups/${groupId}/settings/locations/${location.locationId}`}
                  >
                    <Text variant="link">
                      {locationNamesById[location.locationId] ??
                        "Deleted location"}
                    </Text>
                  </Link>
                  <ul className="list-disc pl-5">
                    <li>{location.pageviews} views</li>
                    <li>{location.visitors} unique users</li>
                  </ul>
                </div>
              );
            })}
            {analytics?.metricsByLocationId === undefined ? (
              <div>
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            ) : null}
            <Text variant="h2">Top Services</Text>
            {analytics?.metricsByServiceId?.map((service) => {
              return (
                <div key={service.serviceId}>
                  <Link to={`/groups/${groupId}/services/${service.serviceId}`}>
                    <Text variant="link">
                      {servicesById[service.serviceId]?.name ??
                        "Deleted service"}
                    </Text>
                  </Link>
                  <ul className="list-disc pl-5">
                    <li>{service.pageviews} views</li>
                    <li>{service.visitors} unique users</li>
                  </ul>
                </div>
              );
            })}
            {analytics?.metricsByServiceId === undefined ? (
              <div>
                <FontAwesomeIcon icon={faSpinner} spin />
              </div>
            ) : null}
            <DownloadLink
              filename="AppAnalytics.csv"
              getDataUrl={() => {
                const data: (Number | String | undefined)[][] = [
                  ["Location", "Page Views", "Unique Users"],
                ];
                analytics?.metricsByLocationId?.forEach((location) =>
                  data.push([
                    locationNamesById[location.locationId],
                    location.pageviews,
                    location.visitors,
                  ])
                );
                data.push(["Service", "Page Views", "Unique Users"]);
                analytics?.metricsByServiceId?.forEach((service) =>
                  data.push([
                    servicesById[service.serviceId]?.name,
                    service.pageviews,
                    service.visitors,
                  ])
                );
                const csv = encodeCsv(data);
                return `data:text/csv,${encodeURIComponent(csv)}`;
              }}
            >
              <Button>
                <FontAwesomeIcon icon={faFileCsv} />
                Download spreadsheet
              </Button>
            </DownloadLink>
          </>
        ) : (
          <>No App Analytics data available.</>
        )}
      </div>
    </Wrapper>
  );
};

const useAnalyticsQuery = (groupId: string): Analytics | undefined | null => {
  const [analytics, setAnalytics] = useState<Analytics | undefined | null>();

  useEffect(() => {
    const fetch = async () => {
      try {
        const fetchedAnalytics = await fetchAnalytics({ groupId });
        const formattedAnalytics = fetchedAnalytics.data as Analytics;
        setAnalytics(formattedAnalytics);
      } catch (e) {
        setAnalytics(null);
      }
    };
    fetch();
  }, [groupId]);

  return analytics;
};
