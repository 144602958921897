import { faCloud, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { InfoIcon } from "_shared/components/Icons";
import SideNav, { SideNavLink, SideNavSub } from "_shared/components/SideNav";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import "_shared/css/Help.css";
import Dot from "_shared/components/Dot";
import { faBookArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useGroup } from "groups/Group";

const Help: FC = () => {
  const group = useGroup();
  return (
    <div className="help">
      <header className="help__header">
        <Wrapper pad>
          <Text variant="h1" className="help__title">
            Help
          </Text>
        </Wrapper>
      </header>
      <main className="help__main">
        <Wrapper pad>
          <div className="prose-a:text-blue-600 prose max-w-none prose-a:no-underline">
            <h2 id="basics">Basics</h2>
            <p>
              <LocalizedMessage id="help_info" />
            </p>
            <h3 id="sign-in">Sign in</h3>
            <ol>
              <li>
                Go to{" "}
                <a
                  href="https://clinical.hand.community"
                  target="_blank"
                  rel="noreferrer"
                >
                  clinical.hand.community
                </a>
                .
              </li>
              <li>
                Check the link! It must match exactly, and you should see a lock{" "}
                <span className="not-prose">
                  <FontAwesomeIcon icon={faLock} />
                </span>{" "}
                next to it. This means you are securely connected to Help at
                Hand.
              </li>
              <li>
                Enter your email address and press <em>Send email</em>.
              </li>
              <li>
                You will receive a link to your inbox. Press the link to finish
                signing in.
              </li>
            </ol>
            <p>
              <em>Tip: Drag this shortcut to your Desktop for easy access!</em>
            </p>
            <div className="not-prose">
              <a
                className="inline-flex cursor-grab flex-col items-center gap-2"
                href="https://clinical.hand.community"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="pointer-events-none m-0 w-[56px]"
                  src="/logo192.png"
                  alt="Help at Hand icon"
                />
                <small>Help at Hand Clinical</small>
              </a>
            </div>
            <h3 id="get-started">Get started</h3>
            <ol>
              <li>
                Add your organisation's address(es) and contact information at{" "}
                <Link to="./settings/locations">{"Settings > Locations"}</Link>.
                This will be visible to the public on the Help at Hand app.
              </li>
              <li>
                Manage your list of local support services under{" "}
                <Link to="./services">Services</Link>. Choose from a range of
                ready-made national support services in{" "}
                <Link to="./helpshelf">HelpShelf</Link>.
              </li>
              <li>
                <LocalizedMessage
                  id="help_link"
                  values={{
                    link: (
                      <Link to="./patients">
                        <LocalizedMessage id="help_patients_title" />
                      </Link>
                    ),
                  }}
                />
              </li>
            </ol>
            <h3 id="work-with-colleagues">Work with your team</h3>
            <p>
              <LocalizedMessage
                id="help_share"
                values={{
                  link: <Link to="./settings/users">{"Settings > Users"}</Link>,
                }}
              />
            </p>
            <p>
              New users can <Link to="#sign-in">sign in</Link> with just their
              email address — no password needed!
            </p>
            <h3 id="save-to-the-cloud">Save to the Cloud</h3>
            <p>
              Keep an eye out for the <FontAwesomeIcon icon={faCloud} /> cloud
              icon. Changes are saved automatically, but this will turn red if
              there's a problem.
            </p>
            <h3 id="get-help">Get help</h3>
            <ul>
              <li>
                Press <em>Help</em> in the left sidebar to view this page.
              </li>
              <li>
                Mouse over
                <span className="not-prose">
                  <Tippy content={<span>You did it! 🎉</span>}>
                    <InfoIcon />
                  </Tippy>
                </span>
                info icons for more details.
              </li>
              <li>
                Email our friendly support team at{" "}
                <a href="mailto:help@hand.community">help@hand.community</a>.
              </li>
            </ul>
            <hr />

            <h2 id="services">Services</h2>
            <h3 id="create-a-service">Create a service</h3>
            <p>
              Go to <Link to="./services">Services</Link> and press the{" "}
              <em>Create service</em> button.
            </p>
            <h3 id="edit-and-approve-a-service">Edit and approve a service</h3>
            <p>
              When you edit a service, a draft will be automatically saved and
              hidden from the public. Press <em>Approve</em> to make it publicly
              available.
            </p>
            <h3 id="publish-services">Publish services</h3>
            <p>
              Changes to any service are not publicy visible until you press{" "}
              <em>Publish</em>
            </p>
            <h3 id="update-services">Update services</h3>
            <ol>
              <li>
                Go to <Link to="./services">Services</Link>.
              </li>
              <li>
                Look for services with a{" "}
                <span className="not-prose text-red">
                  <Dot color="red" /> red dot
                </span>{" "}
                (these haven't been updated for 6 months).
              </li>
              <li>
                Check the service's information is still current and then press{" "}
                <em>Approve</em>.
              </li>
            </ol>
            <h3 id="find-a-service">Find a service</h3>
            <p>
              Under <Link to="./services">Services</Link>, you can search for
              services:
            </p>
            <ul>
              <li>by name or description</li>
              <li>by tag</li>
            </ul>
            <hr />

            <h2 id="helpshelf">HelpShelf</h2>
            <p>
              HelpShelf is our collection of services gathered directly from,
              and maintained by, the support services themselves.
            </p>
            <h3 id="link-to-a-helpshelf-service">
              Link to a HelpShelf service
            </h3>
            <ol>
              <li>
                Go to <Link to="./helpshelf">HelpShelf</Link>.
              </li>
              <li>
                Select a HelpShelf service — you can filter by the demographics
                your community needs most.
              </li>
              <li>
                Press <em>Link service</em>.
              </li>
              <li>
                The HelpShelf service is now included in your list at{" "}
                <Link to="./services">Services</Link>.
              </li>
            </ol>
            <h3 id="update-a-helpshelf-service">Update a HelpShelf service</h3>
            <p>
              Support services themselves are responsible for keeping their
              information up to date on HelpShelf.
            </p>
            <ol>
              <li>
                Go to <Link to="./services">Services</Link>.
              </li>
              <li>
                Look for services with a{" "}
                <span className="not-prose text-red">
                  <FontAwesomeIcon icon={faBookArrowUp} /> red book
                </span>
                ; this means a HelpShelf update is available.
              </li>
              <li>Select a service to see the new version.</li>
              <li>
                Press <em>Approve</em> to make the new version available to your
                community.
              </li>
            </ol>
            <h3 id="report-a-helpshelf-service">Report a HelpShelf service</h3>
            <p>
              We work hard to ensure the services we collect from the public are
              of the highest quality.
            </p>
            <p>
              If you notice anything wrong with a service, please select it and
              press <em>Report</em>.
            </p>
            <p>
              You can also email us at{" "}
              <a href="mailto:help@hand.community">help@hand.community</a>.
            </p>
            <hr />

            <h2 id="patients">
              <LocalizedMessage id="help_patients_title" />
            </h2>
            <h3 id="add-a-patient">
              <LocalizedMessage id="help_patients_add_a_patient" />
            </h3>
            <ol>
              <li>
                <LocalizedMessage
                  id="help_patients_add_patient"
                  values={{
                    link: (
                      <Link to="./patients">
                        <LocalizedMessage id="help_patients_title" />
                      </Link>
                    ),
                  }}
                />
              </li>
              <li>
                Enter their personal details, along with any referral info,
                under the <em>Identity</em> tab.
              </li>
            </ol>
            <h3 id="find-a-patient">
              <LocalizedMessage id="help_patients_find_a_patient" />
            </h3>
            <p>
              <LocalizedMessage
                id="help_patients_search"
                values={{
                  link: (
                    <Link to="./patients">
                      <LocalizedMessage id="help_patients_title" />
                    </Link>
                  ),
                }}
              />
            </p>
            <ul>
              <li>
                <LocalizedMessage id="help_patients_by_name" />
              </li>
              <li>
                ...by assignee: enter your or a colleague's email address;
              </li>
              <li>
                <LocalizedMessage
                  id="help_patients_by_status"
                  values={{
                    link: (
                      <Link to="./settings/patients#status">
                        <LocalizedMessage id="help_patients_by_status_link" />
                      </Link>
                    ),
                  }}
                />
              </li>
            </ul>
            <h3 id="log-a-consultation">Log a consultation</h3>
            <ol>
              <li>
                <LocalizedMessage id="help_consultations_tab" />
              </li>
              <li>
                <LocalizedMessage
                  id="help_consultations_add_event"
                  values={{
                    link: (
                      <Link to="./settings/patients#history">
                        <LocalizedMessage id="help_consultations_add_event_link" />
                      </Link>
                    ),
                  }}
                />
              </li>
              <li>
                <LocalizedMessage id="help_consultations_questions" />
              </li>
            </ol>
            <h3 id="make-a-plan">Make a plan</h3>
            <p>
              <LocalizedMessage id="help_make_a_plan" />
            </p>
            <h3 id="track-progress">Track progress</h3>
            <p>
              <LocalizedMessage id="help_track_progress" />
            </p>
            <h3 id="stay-organised">Stay organised</h3>
            <ol>
              <li>
                <LocalizedMessage id="help_stay_organised_select" />
              </li>
              <li>
                <LocalizedMessage id="help_stay_organised_assign" />
              </li>
              <li>
                <LocalizedMessage
                  id="help_stay_organised_status"
                  values={{
                    link: (
                      <Link to="./settings/patients#status">
                        <LocalizedMessage id="help_patients_by_status_link" />
                      </Link>
                    ),
                  }}
                />
              </li>
              <li>Set a reminder for the next time you need to follow up.</li>
            </ol>
            <hr />

            <h2 id="promotion">Promotion</h2>
            <h3 id="send-a-notification">Send a notification</h3>
            <p>
              You can send a push notification to every app users' device at{" "}
              <Link to="./promotion/notifications">
                {"Promotion > Notifications"}
              </Link>
              .
            </p>
            <p>
              <LocalizedMessage id="help_notification_requirements" />
            </p>
            <ol>
              <li>Download the Help at Hand app</li>
              <li>
                Choose a <em>{group.name}</em> location
              </li>
              <li>Turn on notifications in the Help at Hand app</li>
              <li>Turn on notifications in their device settings</li>
            </ol>
            <hr />

            <h2 id="contact-us">Contact Us</h2>
            <p>
              If you have any questions, email us at{" "}
              <a href="mailto:help@hand.community">help@hand.community</a> and
              one of our friendly team will get back to you.
            </p>
          </div>
        </Wrapper>
        <SideNav className="help__side-nav">
          <SideNavLink href="#basics">Basics</SideNavLink>
          <SideNavSub>
            <SideNavLink href="#sign-in">Sign in</SideNavLink>
            <SideNavLink href="#get-started">Get started</SideNavLink>
            <SideNavLink href="#work-with-colleagues">
              Work with colleagues
            </SideNavLink>
            <SideNavLink href="#save-to-the-cloud">
              Save to the cloud
            </SideNavLink>
            <SideNavLink href="#get-help">Get help</SideNavLink>
          </SideNavSub>
          <SideNavLink href="#services">Services</SideNavLink>
          <SideNavSub>
            <SideNavLink href="#create-a-service">Create a service</SideNavLink>
            <SideNavLink href="#edit-and-approve-a-service">
              Edit and approve a service
            </SideNavLink>
            <SideNavLink href="#publish-services">Publish services</SideNavLink>
            <SideNavLink href="#update-services">Update services</SideNavLink>
            <SideNavLink href="#find-a-service">Find a service</SideNavLink>
          </SideNavSub>
          <SideNavLink href="#helpshelf">HelpShelf</SideNavLink>
          <SideNavSub>
            <SideNavLink href="#link-to-a-helpshelf-service">
              Link to a HelpShelf service
            </SideNavLink>
            <SideNavLink href="#update-a-helpshelf-service">
              Update a HelpShelf service
            </SideNavLink>
            <SideNavLink href="#report-a-helpshelf-service">
              Report a HelpShelf service
            </SideNavLink>
          </SideNavSub>
          <SideNavLink href="#patients">
            <LocalizedMessage id="help_patients_title" />
          </SideNavLink>
          <SideNavSub>
            <SideNavLink href="#add-a-patient">
              <LocalizedMessage id="help_patients_add_a_patient" />
            </SideNavLink>
            <SideNavLink href="#find-a-patient">
              <LocalizedMessage id="help_patients_find_a_patient" />
            </SideNavLink>
            <SideNavLink href="#log-a-consultation">
              Log a consultation
            </SideNavLink>
            <SideNavLink href="#make-a-plan">Make a plan</SideNavLink>
            <SideNavLink href="#track-progress">Track progress</SideNavLink>
            <SideNavLink href="#stay-organised">Stay organised</SideNavLink>
          </SideNavSub>
          <SideNavLink href="#promotion">Promotion</SideNavLink>
          <SideNavSub>
            <SideNavLink href="#send-a-notification">
              Send a notification
            </SideNavLink>
          </SideNavSub>
          <SideNavLink href="#contact-us">Contact us</SideNavLink>
        </SideNav>
      </main>
    </div>
  );
};

export default Help;
