import { useContext, FC } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { firestore } from "_shared/firebase";
import { useUrl } from "_shared/hooks";
import Button from "_shared/components/Button";
import { GroupContext } from "../../Group";
import "_shared/css/Locations.css";
import { useLocationsQuery } from "../../Group.hooks";

const Locations: FC = () => {
  const url = useUrl();
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const group = useContext(GroupContext);
  const locations = useLocationsQuery(groupId);

  const onCreate = async () => {
    const name = "New Location";
    const locationRef = await addDoc(
      collection(firestore, `groups/${groupId}/locations`),
      { name }
    );
    await updateDoc(doc(firestore, `_meta/locations`), {
      [`${groupId}.${locationRef.id}`]: { name },
      _latestEdit: {
        groupId,
      },
    });
  };

  if (!group) return null;
  if (locations === undefined) return null;

  return (
    <>
      <nav className="locations">
        {locations.length === 0 && (
          <p>
            <em>No locations have been added yet.</em>
          </p>
        )}
        {locations.map((location) => {
          return (
            <Link
              key={location.id}
              className="locations__location"
              to={`${url}/${location.id}`}
            >
              <p className="locations__name">{location.name}</p>
              <small className="locations__address">
                {location.address || "-"}
              </small>
            </Link>
          );
        })}
      </nav>
      <Button secondary onClick={onCreate}>
        <FontAwesomeIcon icon={faPlus} /> Add location
      </Button>
    </>
  );
};
export default Locations;
