import { FC, ReactNode, useMemo, useRef, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { CHART_COLORS } from "_shared/chart";
import { Report } from "_shared/models/_meta";
import {
  addOpacity,
  encodeCsv,
  getMonthNames,
  localeSort,
} from "_shared/utils";
import { CheckboxField } from "_shared/components/Field";
import { useGroup } from "../Group";
import SideNav, { SideNavLink } from "_shared/components/SideNav";
import Wrapper from "_shared/components/Wrapper";
import Text from "_shared/components/Text";
import { FormControl, InputLabel, Select } from "@mui/material";
import { GENDERS } from "_shared/models/Patient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faFileCsv,
  faFileImage,
} from "@fortawesome/free-solid-svg-icons";
import Button, { ButtonGroup } from "_shared/components/Button";
import { DownloadLink } from "_shared/components/DownloadLink";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useLocalize } from "_shared/localization/useLocalize";

interface ReferralsReportProps {
  report: Report;
}

export const ReferralsReport: FC<ReferralsReportProps> = ({ report }) => {
  const group = useGroup();
  const { patientReferralReasons = {} } = group;
  const getAllReasonIds = () => new Set(Object.keys(patientReferralReasons));
  const [selectedReasonIds, setSelectedReasons] =
    useState<Set<string>>(getAllReasonIds);
  const reasonColorsById = Object.fromEntries(
    Object.entries(patientReferralReasons)
      .sort(([_, aLabel], [__, bLabel]) => localeSort(aLabel, bLabel))
      .map(([id], i) => [id, CHART_COLORS[i % CHART_COLORS.length]])
  );
  const selectedReasonLabelsById = Object.fromEntries(
    Object.entries(patientReferralReasons)
      .filter(([id, label]) => selectedReasonIds.has(id))
      .sort(([_, aLabel], [__, bLabel]) => localeSort(aLabel, bLabel))
  );

  return (
    <div className="flex items-start gap-8 pb-8">
      <Wrapper wide pad className="min-w-0">
        <div className="my-6 flex flex-col gap-2">
          <Link className="self-start" to=".">
            <Button secondary>
              <FontAwesomeIcon icon={faChevronLeft} /> Reports
            </Button>
          </Link>
          <Text variant="h1">Referral Reasons</Text>
        </div>
        <AllTime
          report={report}
          reasonColorsById={reasonColorsById}
          reasonLabelsById={selectedReasonLabelsById}
        />
        <hr />
        <ByMonth
          report={report}
          reasonLabelsById={selectedReasonLabelsById}
          reasonColorsById={reasonColorsById}
          selectedReasonIds={selectedReasonIds}
        />
        <hr />
        <ByGender
          report={report}
          reasonColorsById={reasonColorsById}
          reasonLabelsById={selectedReasonLabelsById}
        />
      </Wrapper>
      <SideNav>
        <SideNavLink href="#all-time">All-Time</SideNavLink>
        <SideNavLink href="#by-month">By Month</SideNavLink>
        <SideNavLink href="#by-gender">By Gender</SideNavLink>
        <CheckboxField
          label={<Text variant="smallLight">Referral Reasons</Text>}
          value={selectedReasonIds}
          onToggleAll={() => {
            const allReasonIds = getAllReasonIds();
            if (selectedReasonIds.size === allReasonIds.size) {
              setSelectedReasons(new Set());
            } else {
              setSelectedReasons(getAllReasonIds());
            }
          }}
          options={Object.entries(patientReferralReasons)
            .sort(([_, aLabel], [__, bLabel]) => localeSort(aLabel, bLabel))
            .map(([id, label]) => {
              return {
                value: id,
                label: (
                  <div className="flex items-center gap-2">
                    <div
                      className="aspect-square w-2 rounded-full"
                      style={{ backgroundColor: reasonColorsById[id] }}
                    />
                    <Text variant="smallLight">{label}</Text>
                  </div>
                ),
              };
            })}
          onChange={setSelectedReasons}
        />
      </SideNav>
    </div>
  );
};

interface AllTimeProps {
  report: Report;
  reasonColorsById: Record<string, string>;
  reasonLabelsById: Record<string, string>;
}

const AllTime: FC<AllTimeProps> = ({
  report,
  reasonColorsById,
  reasonLabelsById,
}) => {
  const localize = useLocalize();
  const chartRef = useRef<ChartJSOrUndefined<"bar">>();

  const patientCountByReasonId: Record<string, number> = {};
  for (const patientSummary of Object.values(report.patients)) {
    const { referralReasons } = patientSummary;
    if (referralReasons !== undefined) {
      referralReasons.forEach((id) => {
        patientCountByReasonId[id] ??= 0;
        patientCountByReasonId[id]++;
      });
    }
  }
  const data: Array<number> = [];
  const labels: Array<string> = [];
  const backgroundColor: Array<string> = [];
  Object.entries(reasonLabelsById)
    .sort(([_, aLabel], [__, bLabel]) => localeSort(aLabel, bLabel))
    .forEach(([id, label]) => {
      data.push(patientCountByReasonId[id] ?? 0);
      labels.push(label);
      backgroundColor.push(reasonColorsById[id]);
    });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <Text variant="h2" id="all-time">
          All-Time
        </Text>
        <p>
          <LocalizedMessage id="reports_per_referral" />
        </p>
      </div>
      <Bar
        ref={chartRef}
        options={{
          animation: false,
          indexAxis: "y",
          scales: {
            x: {
              beginAtZero: true,
              title: {
                display: true,
                text: localize("reports_number_of_patients"),
              },
              ticks: {
                precision: 0,
              },
              grid: {
                display: false,
              },
            },
            y: {
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              backgroundColor,
              data,
            },
          ],
        }}
      />
      <ButtonGroup>
        <DownloadLink
          filename="referrals_all_time.png"
          getDataUrl={() => chartRef.current?.toBase64Image("image/png") ?? ""}
        >
          <Button secondary>
            <FontAwesomeIcon icon={faFileImage} />
            Download image
          </Button>
        </DownloadLink>
        <DownloadLink
          filename="referrals_all_time.csv"
          getDataUrl={() => {
            const csv = encodeCsv(labels.map((label, i) => [label, data[i]]));
            return `data:text/csv,${encodeURIComponent(csv)}`;
          }}
        >
          <Button secondary>
            <FontAwesomeIcon icon={faFileCsv} />
            Download spreadsheet
          </Button>
        </DownloadLink>
      </ButtonGroup>
    </div>
  );
};

interface ByMonthProps {
  report: Report;
  reasonLabelsById: Record<string, string>;
  reasonColorsById: Record<string, string>;
  selectedReasonIds: Set<string>;
}

const ByMonth: FC<ByMonthProps> = ({
  report,
  reasonLabelsById,
  selectedReasonIds,
  reasonColorsById,
}) => {
  const localize = useLocalize();
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const [selectedYear, setSelectedYear] = useState<string>(
    currentYear.toString()
  );
  const chartRef = useRef<ChartJSOrUndefined<"bar">>();

  const earliestYear = useMemo(() => {
    if (!Object.keys(report.patients).length) return currentYear;
    const years = Object.values(report.patients)
      .filter(({ referralDate }) => referralDate !== undefined)
      .map(({ referralDate }) => new Date(referralDate!).getFullYear())
      .sort();
    return years[0];
  }, [currentYear, report.patients]);
  const yearOptions = [];
  for (let year = currentYear; year >= earliestYear; year--) {
    yearOptions.push(year);
  }
  const monthlyPatientCountsByReasonId: Record<string, Array<number>> = {};
  for (const patientSummary of Object.values(report.patients)) {
    const { referralDate, referralReasons } = patientSummary;
    if (referralDate === undefined || referralReasons === undefined) continue;
    const date = new Date(referralDate);
    if (date.getFullYear().toString() !== selectedYear) continue;
    const month = date.getMonth();
    referralReasons.forEach((id) => {
      monthlyPatientCountsByReasonId[id] ??= [];
      monthlyPatientCountsByReasonId[id][month] ??= 0;
      monthlyPatientCountsByReasonId[id][month]++;
    });
  }
  const datasets = Object.entries(reasonLabelsById)
    .filter(([reasonId]) => selectedReasonIds.has(reasonId))
    .sort(([_, aLabel], [__, bLabel]) => localeSort(aLabel, bLabel))
    .map(([reasonId, _]) => {
      const monthlyPatientCounts = monthlyPatientCountsByReasonId[reasonId];
      return {
        label: reasonLabelsById[reasonId],
        backgroundColor: reasonColorsById[reasonId],
        data: monthlyPatientCounts,
      };
    });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center">
        <div className="flex-1">
          <Text variant="h2" id="by-month">
            By Month
          </Text>
          <p>
            <LocalizedMessage id="reports_per_referral_month" />
          </p>
        </div>
        <FormControl className="w-52">
          <InputLabel htmlFor="year">Year</InputLabel>
          <Select
            native
            id="year"
            label="Year"
            value={selectedYear ?? ""}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {yearOptions.map((year) => {
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <Bar
        ref={chartRef}
        options={{
          animation: false,
          scales: {
            y: {
              beginAtZero: true,
              title: {
                display: true,
                text: localize("reports_number_of_patients"),
              },
              ticks: {
                precision: 0,
              },
              grid: {
                display: false,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        data={{
          labels: getMonthNames(),
          datasets,
        }}
      />
      <ButtonGroup>
        <DownloadLink
          filename={`referrals_by_month_${selectedYear}.png`}
          getDataUrl={() => chartRef.current?.toBase64Image("image/png") ?? ""}
        >
          <Button secondary>
            <FontAwesomeIcon icon={faFileImage} />
            Download image
          </Button>
        </DownloadLink>
      </ButtonGroup>
    </div>
  );
};

interface ByGenderProps {
  report: Report;
  reasonColorsById: Record<string, string>;
  reasonLabelsById: Record<string, string>;
}

const ByGender: FC<ByGenderProps> = ({
  report,
  reasonColorsById,
  reasonLabelsById,
}) => {
  const options: Array<ReactNode> = [];
  let firstOptionValue: string | undefined;
  Object.entries(reasonLabelsById)
    .sort(([_, aLabel], [__, bLabel]) => localeSort(aLabel, bLabel))
    .forEach(([id, label]) => {
      firstOptionValue ??= id;
      options.push(
        <option key={id} value={id}>
          {label}
        </option>
      );
    });
  const [selectedReasonId, setSelectedReasonId] = useState<string>(
    firstOptionValue ?? ""
  );
  const chartRef = useRef<ChartJSOrUndefined<"pie">>();
  const data = GENDERS.map((gender) => {
    let count = 0;
    for (const patient of Object.values(report.patients)) {
      if (patient.gender !== gender) continue;
      if (!patient.referralReasons) continue;
      if (!patient.referralReasons.has(selectedReasonId)) continue;
      count++;
    }
    return count;
  });
  const color = reasonColorsById[selectedReasonId];
  const label = reasonLabelsById[selectedReasonId];

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center">
        <div className="flex-1">
          <Text variant="h2" id="by-gender">
            By Gender
          </Text>
          <p>
            <LocalizedMessage id="reports_per_referral_gender" />
          </p>
        </div>
        <FormControl className="w-52">
          <InputLabel htmlFor="by-gender-reason">Referral Reason</InputLabel>
          <Select
            native
            id="by-gender-reason"
            label="Referral Reason"
            value={selectedReasonId}
            onChange={(e) => setSelectedReasonId(e.target.value)}
          >
            {options}
          </Select>
        </FormControl>
      </div>
      <Pie
        ref={chartRef}
        className="max-h-96"
        data={{
          labels: GENDERS,
          datasets: [
            {
              label,
              data,
              backgroundColor: GENDERS.map((_, i) => {
                return addOpacity(color, (GENDERS.length - i) / GENDERS.length);
              }),
            },
          ],
        }}
      />
      <ButtonGroup>
        <DownloadLink
          filename={`referrals_by_gender_${label}.png`}
          getDataUrl={() => chartRef.current?.toBase64Image("image/png") ?? ""}
        >
          <Button secondary>
            <FontAwesomeIcon icon={faFileImage} />
            Download image
          </Button>
        </DownloadLink>
        <DownloadLink
          filename={`referrals_by_gender_${label}.csv`}
          getDataUrl={() => {
            const csv = encodeCsv(
              GENDERS.map((gender, i) => [gender, data[i]])
            );
            return `data:text/csv,${encodeURIComponent(csv)}`;
          }}
        >
          <Button secondary>
            <FontAwesomeIcon icon={faFileCsv} />
            Download spreadsheet
          </Button>
        </DownloadLink>
      </ButtonGroup>
    </div>
  );
};
