import { FC } from "react";
import "_shared/css/SideNav.css";

interface SideNavProps {
  className?: string;
}

const SideNav: FC<SideNavProps> = ({ children, className }) => {
  const classes = ["side-nav"];
  if (className) classes.push(className);

  return <div className={classes.join(" ")}>{children}</div>;
};
export default SideNav;

interface SideNavLinkProps {
  href: string;
  className?: string;
}

export const SideNavLink: FC<SideNavLinkProps> = ({
  children,
  className,
  ...otherProps
}) => {
  const classes = ["side-nav__link"];
  if (className) classes.push(className);

  return (
    <small>
      <a className={classes.join(" ")} {...otherProps}>
        {children}
      </a>
    </small>
  );
};

export const SideNavSub: FC = ({ children, ...otherProps }) => {
  return (
    <div className="side-nav__sub" {...otherProps}>
      {children}
    </div>
  );
};
