import { GeoPoint } from "firebase/firestore";
import { IService } from "./Service";

export const LOCATION_TYPES = [
  "Surgery",
  "Hospice",
  "Office",
  "College",
] as const;

export interface ILocation {
  readonly name?: string;
  readonly address?: string;
  readonly position?: GeoPoint;
  readonly links?: Record<string, string>;
  readonly numbers?: ReadonlyArray<IKeyNumber>;
  readonly images?: {
    logo?: string;
    header?: string;
  };
  readonly openingTimes?: ReadonlyArray<{
    day?: string;
    times?: string;
  }>;
  readonly notice?: {
    message?: string;
    link?: string;
  };
  readonly type?: (typeof LOCATION_TYPES)[number];
  readonly services?: ReadonlyArray<IService & { id: string }>;
}

export interface IKeyNumber {
  label?: string;
  number?: string;
}
