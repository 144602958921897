import { FC } from "react";
import Text from "_shared/components/Text";
import { Nullable } from "_shared/utils";
import { IPatientEvent } from "_shared/models/Patient";
import { RadioField } from "_shared/components/Field";
import Tippy from "@tippyjs/react";
import { InfoIcon } from "_shared/components/Icons";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";

interface LifestyleQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const LifestyleQuestionnaire: FC<LifestyleQuestionnaireProps> = ({
  event,
  onChange,
}) => {
  const { alcohol, smoking } = event;

  return (
    <>
      <hr />
      <Text variant="h2" id="lifestyle">
        Lifestyle
      </Text>
      <RadioField
        label="Alcohol consumption"
        value={alcohol}
        onChange={(value) => onChange({ alcohol: value })}
        options={[
          { value: "None", label: "None" },
          {
            value: "Within limits",
            label: (
              <>
                Within limits
                <Tippy
                  content={
                    <span>
                      The NHS advises no more than 14 units a week on a regular
                      basis
                    </span>
                  }
                >
                  <InfoIcon />
                </Tippy>
              </>
            ),
          },
          { value: "Above limits", label: "Above limits" },
        ]}
      />
      <RadioField
        label="Smoking"
        value={smoking}
        onChange={(value) => onChange({ smoking: value })}
        options={[
          { value: "Non-smoker", label: "Non-smoker" },
          {
            value: "Advised to quit",
            label: (
              <>
                Advised to quit
                <Tippy
                  content={
                    <span>
                      <LocalizedMessage id="patients_events_advised_quit" />
                    </span>
                  }
                >
                  <InfoIcon />
                </Tippy>
              </>
            ),
          },
          {
            value: "Trying to quit",
            label: (
              <>
                Trying to quit
                <Tippy
                  content={
                    <span>
                      <LocalizedMessage id="patients_events_already_began_quit" />
                    </span>
                  }
                >
                  <InfoIcon />
                </Tippy>
              </>
            ),
          },
          { value: "Ex-smoker", label: "Ex-smoker" },
        ]}
      />
    </>
  );
};
