/**
 * Grouped by page.
 * Strings used within the `patients` folder are prefixed with `patients_`.
 */
export const STRINGS = {
  patients_title: "Patients",
  patients_limit:
    "You've added {count}/100 free patients. Upgrade to remove this limit.",
  patients_add_patient: "Add patient",
  patients_new_patient: "New Patient",
  patients_archive_patient: "Archive patient",
  patients_restore_archived_patient: "Unarchive patient",
  patients_show_archived_patient: "Show archived patients",
  patients_show_ongoing_patient: "Show ongoing patients",
  patients_consent_reminder: "You must have the patient's consent",
  patients_delete_patient: "Delete patient",
  patients_known_as:
    "If the patient prefers a different name, add it here (optional)",
  patients_discover:
    "Discover a new way to help patients, and report on outcomes.",
  patients_events_dependent:
    "A dependent is a child, friend, or other relative that the patient cares for",
  patients_events_advised_quit:
    "You advised the patient to quit during this consultation",
  patients_events_already_began_quit:
    "The patient began quitting prior to this consultation",
  patients_events_mycaw_date: "Date",
  patients_events_mycaw_respondent: "I am a",
  patients_events_mycaw_location: "This was completed",
  patients_events_mycaw_concern1: "1.",
  patients_events_mycaw_concern1Rating: "Concern or Problem 1",
  patients_events_mycaw_concern2: "2.",
  patients_events_mycaw_concern2Rating: "Concern or Problem 2",
  patients_events_mycaw_wellbeing: "Wellbeing",
  patients_events_mycaw_otherThings: "Other things affecting your health",
  patients_events_mycaw_mostImportant: "What has been most important for you?",

  reports_number: "Number of patients per assignee",
  reports_number_of_patients: "Number of patients",
  reports_per_referral: "Number of (non-unique) patients per referral reason",
  reports_per_referral_month:
    "Number of (non-unique) patients per referral reason, broken down by month",
  reports_per_referral_gender:
    "Number of (non-unique) patients per referral reason and gender",

  help_info:
    "Help at Hand connects your community with the support services you trust, and enables you to track and report on patient outcomes.",
  help_link:
    "Record an individual patient's social prescribing journey under {link}.",
  help_share:
    "Share access with your team under {link}. When you add a user, they can see and change everything, including all services, patients, and other users.",
  help_patients_title: "Patients",
  help_patients_add_a_patient: "Add a Patient",
  help_patients_add_patient:
    "Go to {link} and press the <em>Add Patient</em> button.",
  help_patients_find_a_patient: "Find a Patient",
  help_patients_search: "Under {link}, you can search for patients...",
  help_patients_by_name: "...by the patient's name;",
  help_patients_by_status:
    "...by status: select a status from the dropdown — customisable at {link}.",
  help_patients_by_status_link: "Settings > Patients > Status",
  help_consultations_tab:
    "Select a patient, then choose the <em>Consultations</em> tab.",
  help_consultations_add_event:
    "Press <em>Add event</em>. You can customise events at {link}.",
  help_consultations_add_event_link: "Settings > Patients > History",
  help_consultations_questions:
    "You'll see a range of questions about the patient's physical and mental health.",
  help_make_a_plan:
    "Select a patient. On the <em>Action Plan</em> tab, you can set goals, recommend services to help the patient acheive them, and record the date they were achieved.",
  help_track_progress:
    "Select a patient, then choose the <em>Progress</em> tab to see an overview of all your consultations. You'll also see graphs of the patients wellbeing metrics over time.",
  help_stay_organised_select:
    "Select a patient, then choose the <em>Identity</em> tab.",
  help_stay_organised_assign:
    "Assign the patient to their main point of contact, either yourself or a colleague.",
  help_stay_organised_status:
    "Set the patient's status — customisable at {link}.",
  help_notification_requirements:
    "In order to receive your message, a patient must have followed these steps:",

  settings_users:
    "Users can see and change everything, including all services, patients, and other users.",
};

export const EDUCATION_STRINGS: typeof STRINGS = {
  ...STRINGS,
  patients_title: "Students",
  patients_limit:
    "You've added {count}/100 free students. Upgrade to remove this limit.",
  patients_add_patient: "Add student",
  patients_new_patient: "New Student",
  patients_archive_patient: "Archive student",
  patients_restore_archived_patient: "Unarchive student",
  patients_show_archived_patient: "Show archived students",
  patients_show_ongoing_patient: "Show ongoing students",
  patients_consent_reminder: "You must have the student's consent",
  patients_delete_patient: "Delete student",
  patients_known_as:
    "If the student prefers a different name, add it here (optional)",
  patients_discover:
    "Discover a new way to help students, and report on outcomes.",
  patients_events_dependent:
    "A dependent is a child, friend, or other relative that the student cares for",
  patients_events_advised_quit:
    "You advised the student to quit during this consultation",
  patients_events_already_began_quit:
    "The student began quitting prior to this consultation",

  reports_number: "Number of students per assignee",
  reports_number_of_patients: "Number of students",
  reports_per_referral: "Number of (non-unique) students per referral reason",
  reports_per_referral_month:
    "Number of (non-unique) students per referral reason, broken down by month",
  reports_per_referral_gender:
    "Number of (non-unique) students per referral reason and gender",

  help_info:
    "Help at Hand connects your community with the support services you trust, and enables you to track and report on student outcomes.",
  help_link:
    "Record an individual student's social prescribing journey under {link}.",
  help_share:
    "Share access with your team under {link}. When you add a user, they can see and change everything, including all services, students, and other users.",
  help_patients_title: "Students",
  help_patients_add_a_patient: "Add a Student",
  help_patients_add_patient:
    "Go to {link} and press the <em>Add Student</em> button.",
  help_patients_find_a_patient: "Find a Student",
  help_patients_search: "Under {link}, you can search for students...",
  help_patients_by_name: "...by the student's name;",
  help_patients_by_status:
    "...by status: select a status from the dropdown — customisable at {link}.",
  help_patients_by_status_link: "Settings > Students > Status",
  help_consultations_tab:
    "Select a student, then choose the <em>Consultations</em> tab.",
  help_consultations_add_event:
    "Press <em>Add event</em>. You can customise events at {link}.",
  help_consultations_add_event_link: "Settings > Students > History",
  help_consultations_questions:
    "You'll see a range of questions about the student's physical and mental health.",
  help_make_a_plan:
    "Select a student. On the <em>Action Plan</em> tab, you can set goals, recommend services to help the student acheive them, and record the date they were achieved.",
  help_track_progress:
    "Select a student, then choose the <em>Progress</em> tab to see an overview of all your consultations. You'll also see graphs of the students wellbeing metrics over time.",
  help_stay_organised_select:
    "Select a student, then choose the <em>Identity</em> tab.",
  help_stay_organised_assign:
    "Assign the student to their main point of contact, either yourself or a colleague.",
  help_stay_organised_status:
    "Set the student's status — customisable at {link}.",
  help_notification_requirements:
    "In order to receive your message, a student must have followed these steps:",

  settings_users:
    "Users can see and change everything, including all services, students, and other users.",
};
