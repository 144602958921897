import { FC, ReactNode } from "react";

interface DownloadLinkProps {
  filename: string;
  getDataUrl: () => string;
  children?: ReactNode;
}

export const DownloadLink: FC<DownloadLinkProps> = ({
  filename,
  getDataUrl,
  children,
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      download={filename}
      // Only populate href while being clicked to save memory
      onClick={(e) => (e.currentTarget.href = getDataUrl())}
      onMouseLeave={(e) => (e.currentTarget.href = "")}
      onBlur={(e) => (e.currentTarget.href = "")}
    >
      {children}
    </a>
  );
};
