import { ComponentProps, FC, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { Link, Route, useParams, useRouteMatch } from "react-router-dom";
import { getDefaultLocation } from "_shared/api";
import Wrapper from "_shared/components/Wrapper";
import "_shared/css/PublicGroup.css";
import ServicesSidebar from "../groups/services/ServicesSidebar";
import { usePrinter } from "_shared/hooks";
import Scrollable from "_shared/components/Scrollable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button, { ButtonGroup } from "_shared/components/Button";
import { useEmailServices } from "../groups/services/Services";
import PublicHeader from "./PublicHeader";
import PreviewService from "../groups/services/PreviewService";
import Copyable from "_shared/components/Copyable";
import {
  faLink,
  faPaperPlane,
  faPrint,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare as falCheckSquare } from "@fortawesome/pro-light-svg-icons";
import { ILocation } from "_shared/models/Location";
import { IService } from "_shared/models/Service";
import {
  useDemographicsServiceFilters,
  usePricingServiceFilters,
} from "_shared/components/TagSelect";
import Text from "_shared/components/Text";

const PublicGroup: FC = () => {
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const { path } = useRouteMatch();
  const emailServices = useEmailServices();
  const [locationId, setLocationId] = useState<string | null>();
  const [location, setLocation] = useState<ILocation | null>();
  const [selectedServiceIds, setSelectedServiceIds] = useState<Set<string>>(
    new Set()
  );
  const [isSelectionBusy, setIsSelectionBusy] = useState(false);
  const { isPrinting, print, printable } = usePrinter();
  const isLoading = location === undefined;
  const locationExists = !isLoading && location !== null;
  const servicesById: { [id: string]: IService } = {};
  for (const { id, ...service } of location?.services ?? []) {
    servicesById[id] = { ...service };
  }
  const linkToThisPage = `https://${window.location.host}/public/groups/${groupId}`;

  // Load any location in the group so we can show public services
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const [locationId, location] = await getDefaultLocation(groupId);
        setLocationId(locationId);
        setLocation(location);
      } catch (e) {
        setLocationId(null);
        setLocation(null);
      }
    };
    fetchLocation();
  }, [groupId]);

  // Selection
  const clearSelected = () => setSelectedServiceIds(new Set());
  // Share via email
  const emailSelected = async () => {
    setIsSelectionBusy(true);
    await emailServices(
      groupId,
      locationId!,
      Array.from(selectedServiceIds),
      servicesById
    );
    setIsSelectionBusy(false);
  };
  // Share link to this page
  const onShareLink = () => {
    window.open(`mailto:?body=${linkToThisPage}`);
  };

  if (isLoading) return null;
  if (!locationExists)
    return (
      <Wrapper pad center>
        <Text variant="h1">Not found</Text>
        <Link to="/">Go home</Link>
      </Wrapper>
    );

  // Render fullscreen portal when printing
  if (isPrinting)
    return ReactDOM.createPortal(
      <>
        {Array.from(selectedServiceIds).map((id) => {
          return <PreviewService key={id} service={servicesById[id]} />;
        })}
      </>,
      printable
    );

  return (
    <div className="public-group">
      <PublicHeader
        trailing={
          <div className="public-group__share-link">
            <ButtonGroup className="public-group__share-link-buttons">
              <Copyable
                tooltip={(isCopied) => (isCopied ? "Copied" : "Copy link")}
              >
                {linkToThisPage}
              </Copyable>
              <Button onClick={onShareLink}>
                <FontAwesomeIcon icon={faLink} /> Share
              </Button>
            </ButtonGroup>
            <small className="public-group__share-link-description">
              Anyone with this link can view, but not edit, public services
            </small>
          </div>
        }
      />
      <div className="public-group__services">
        <Sidebar
          servicesById={servicesById}
          selectedIds={selectedServiceIds}
          onSelectedIdsChange={setSelectedServiceIds}
        />
        <Scrollable className="public-group__services-detail">
          {selectedServiceIds.size > 0 ? (
            <div>
              <h3>
                <FontAwesomeIcon icon={falCheckSquare} />
                &nbsp; With {selectedServiceIds.size} selected service
                {selectedServiceIds.size === 1 ? "" : "s"}...
              </h3>
              <Text variant="h4">Share</Text>
              <Button secondary onClick={print} disabled={isSelectionBusy}>
                <FontAwesomeIcon icon={faPrint} /> Print
              </Button>
              <Button
                secondary
                onClick={emailSelected}
                disabled={isSelectionBusy}
              >
                <FontAwesomeIcon icon={faPaperPlane} /> Email
              </Button>
              <hr />
              <Button
                secondary
                onClick={clearSelected}
                disabled={isSelectionBusy}
              >
                <FontAwesomeIcon icon={faTimes} /> Clear selection
              </Button>
            </div>
          ) : (
            <Route
              path={`${path}/:serviceId`}
              render={({ match }) => {
                const { serviceId } = match.params;
                const service = servicesById[serviceId];
                if (!service) return null;
                return <PreviewService service={service} />;
              }}
            />
          )}
        </Scrollable>
      </div>
    </div>
  );
};

export default PublicGroup;

const Sidebar: FC<
  Omit<
    ComponentProps<typeof ServicesSidebar>,
    "filterOptionGroups" | "matchFilters"
  >
> = (props) => {
  const [demographicsFilterOptionGroups, matchDemographicsFilters] =
    useDemographicsServiceFilters();
  const [pricingFilterOptions, matchPricingFilters] =
    usePricingServiceFilters();

  const matchFilters = (service: IService, selectedFilters: Set<string>) => {
    return (
      matchDemographicsFilters(service, selectedFilters) &&
      matchPricingFilters(service, selectedFilters)
    );
  };

  return (
    <ServicesSidebar
      matchFilters={matchFilters}
      filterOptionGroups={[
        {
          label: "Is...",
          options: [...pricingFilterOptions],
        },
        ...demographicsFilterOptionGroups,
      ]}
      {...props}
    />
  );
};
