// eslint-disable-next-line no-restricted-imports
import { PrimitiveType, useIntl } from "react-intl";
import { STRINGS } from "./strings";

export const useLocalize = () => {
  const intl = useIntl();
  const localize = (
    id: keyof typeof STRINGS,
    values?: Record<string, PrimitiveType>
  ): string => {
    return intl.formatMessage({ id }, values);
  };
  return localize;
};
