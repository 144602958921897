import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import {
  ComponentProps,
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { doc, DocumentData, onSnapshot } from "firebase/firestore";
import { firestore } from "_shared/firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPlus, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  faBullhorn as falBullhorn,
  faCog as falCog,
  faHandsHelping as falHandsHelping,
  faQuestion as falQuestion,
  faUserCircle as falUserCircle,
  faUserFriends as falUserFriends,
  faBooks as falBooks,
  faChartLine as falChartLine,
} from "@fortawesome/pro-light-svg-icons";
import * as api from "_shared/api";
import { DEFAULT_GROUP, IGroup } from "_shared/models/Group";
import { UserContext } from "_shared/components/Auth";
import { deletePreferredGroupId } from "./ChooseGroup";
import Patients from "./patients/Patients";
import Settings from "./settings/Settings";
import Wrapper from "_shared/components/Wrapper";
import Help from "./help/Help";
import { useUrl } from "_shared/hooks";
import "_shared/css/Group.css";
import Scrollable from "_shared/components/Scrollable";
import PublicHeader from "../public/PublicHeader";
import Services from "./services/Services";
import Button, { ButtonGroup } from "_shared/components/Button";
import { convertServiceFromFirestore } from "_shared/models/Service";
import HelpShelf from "./helpshelf/HelpShelf";
import Text from "_shared/components/Text";
import { Promotion } from "./promotion/Promotion";
import Reports from "./reports/Reports";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";

/**
 * @deprecated use `useGroupContext()` or `GroupContextProvider`
 */
export const GroupContext = createContext<IGroup | undefined>(undefined);

export const GroupContextProvider = GroupContext.Provider;

export const useGroup = () => {
  const group = useContext(GroupContext);
  if (!group) throw new Error("useGroup called from outside GroupContext");
  return group;
};

const useGroupQuery = (groupId: string): IGroup | undefined | null => {
  const [group, setGroup] = useState<IGroup | undefined | null>();
  const user = useContext(UserContext);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `groups/${groupId}`),
      (doc) => {
        const group = doc.data();
        if (group) {
          // Convert services
          for (const [id, service] of Object.entries<DocumentData>(
            group?.services ?? {}
          )) {
            group.services[id] = convertServiceFromFirestore(service);
          }
          if (group._globalServiceIds)
            group._globalServiceIds = new Set(group._globalServiceIds);
          //Hide @hand.community users from other users
          const isOneOfUs =
            user?.email && user.email.endsWith("@hand.community");
          if (!isOneOfUs) {
            for (let uid in group?._userProfiles) {
              if (group?._userProfiles[uid].email.endsWith("@hand.community")) {
                delete group?._userProfiles[uid];
              }
            }
          }
          setGroup(group as IGroup);
        } else {
          // Not found
          setGroup(null);
        }
      },
      (e) => {
        console.error(e);
        setGroup(null);
      }
    );
    return unsubscribe;
  }, [groupId, user.email]);

  return group;
};

const Group = () => {
  const match = useRouteMatch<{ groupId: string }>();
  const { groupId } = match.params;
  const { path } = match;
  const url = useUrl();
  const history = useHistory();
  const user = useContext(UserContext);
  const group = useGroupQuery(groupId);
  if (group === null) deletePreferredGroupId();
  const [isCreatingGroup, setIsCreatingGroup] = useState(false);

  const createGroup = async (
    data: IGroup = DEFAULT_GROUP,
    name = window.prompt(`Adding a new group...\n\nGroup name:`)
  ) => {
    if (name === "") window.alert("Cannot create a group without a name"); // prompt() returns '' if user clicks OK without typing
    if (!name) return;
    setIsCreatingGroup(true);
    const newGroupId = await api.createGroup(
      {
        ...data,
        name: name.trim(),
      },
      user
    );
    setIsCreatingGroup(false);
    history.push(`/groups/${newGroupId}`);
  };
  const duplicateGroup = async () => {
    if (!group) throw new Error("Group is not defined");
    const name = window.prompt(
      `Duplicating group '${group.name}'...\n\nGroup name:`
    );
    createGroup(group, name);
  };

  if (group === undefined)
    return (
      <div style={{ textAlign: "center", margin: "4rem" }}>
        <FontAwesomeIcon icon={faSpinner} spin />
      </div>
    );
  if (group === null)
    return (
      <>
        <PublicHeader />
        <Wrapper pad center>
          <Text variant="h1">Not found</Text>
          <Link to="/">Go home</Link>
        </Wrapper>
      </>
    );

  return (
    <>
      <GroupContext.Provider value={group}>
        <div className="md:flex md:items-stretch">
          <nav className="sidebar">
            <Link className="user" to={`${url}/settings/account`}>
              <FontAwesomeIcon icon={falUserCircle} className="user__avatar" />
              <div className="user__info">
                <small className="user__email">{user.email}</small>
                {group && <small className="user__group">{group.name}</small>}
              </div>
            </Link>
            <SidebarLink to={`${url}/patients`}>
              <SidebarIcon icon={falUserFriends} fixedWidth />
              <LocalizedMessage id="patients_title" />
            </SidebarLink>
            <SidebarLink to={`${url}/services`}>
              <SidebarIcon icon={falHandsHelping} fixedWidth /> Services
            </SidebarLink>
            <SidebarLink to={`${url}/helpshelf`}>
              <SidebarIcon icon={falBooks} fixedWidth /> HelpShelf
            </SidebarLink>
            <SidebarLink to={`${url}/promotion`}>
              <SidebarIcon icon={falBullhorn} fixedWidth /> Promotion
            </SidebarLink>
            <SidebarLink to={`${url}/reports`}>
              <SidebarIcon icon={falChartLine} fixedWidth /> Reports
            </SidebarLink>
            <SidebarLink to={`${url}/settings`}>
              <SidebarIcon icon={falCog} fixedWidth /> Settings
            </SidebarLink>
            <SidebarLink to={`${url}/help`}>
              <SidebarIcon icon={falQuestion} fixedWidth /> Help
            </SidebarLink>
            <div className="sidebar__footer">
              {user.isAdmin && (
                <ButtonGroup className="sidebar__manage-groups">
                  <Button
                    secondary
                    onClick={() => createGroup()}
                    disabled={isCreatingGroup}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Create group
                  </Button>
                  <Button
                    secondary
                    onClick={duplicateGroup}
                    disabled={isCreatingGroup}
                  >
                    <FontAwesomeIcon icon={faCopy} /> Duplicate group
                  </Button>
                </ButtonGroup>
              )}
              <img
                className="sidebar__logo"
                src="/assets/logo-horizontal.svg"
                alt="Help @ Hand"
              />
            </div>
          </nav>
          <Scrollable className="main">
            <Switch>
              <Route exact path={`${path}`}>
                <Redirect to={`${url}/services`} />
              </Route>
              <Route path={`${path}/patients`}>
                <Patients />
              </Route>
              <Route path={`${path}/services`}>
                <Services />
              </Route>
              <Route path={`${path}/helpshelf`}>
                <HelpShelf />
              </Route>
              <Route path={`${path}/reports`}>
                <Reports />
              </Route>
              <Route path={`${path}/promotion`}>
                <Promotion />
              </Route>
              <Route path={`${path}/settings`}>
                <Settings />
              </Route>
              <Route path={`${path}/help`}>
                <Help />
              </Route>
            </Switch>
          </Scrollable>
        </div>
      </GroupContext.Provider>
    </>
  );
};

export default Group;

const SidebarLink: FC<ComponentProps<typeof NavLink>> = (props) => {
  return (
    <NavLink
      className="sidebar__link"
      activeClassName="sidebar__link--active"
      {...props}
    />
  );
};

const SidebarIcon: FC<ComponentProps<typeof FontAwesomeIcon>> = (props) => {
  return <FontAwesomeIcon className="sidebar-icon" fixedWidth {...props} />;
};
