import { FC } from "react";
import Text from "_shared/components/Text";
import { Nullable } from "_shared/utils";
import { DiscreteRangeInput, Field, Label } from "_shared/components/Field";
import {
  ONS4_STATEMENTS,
  ONS4_STEPS,
  IPatientEvent,
} from "_shared/models/Patient";

interface Ons4QuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const Ons4Questionnaire: FC<Ons4QuestionnaireProps> = ({
  event,
  onChange,
}) => {
  const { ons4 } = event;

  return (
    <>
      <hr />
      <Text variant="h2" id="ons4">
        ONS4
      </Text>
      <Field wide>
        <p>
          I would like to ask you four questions about your feelings on aspects
          of your life. There are no right or wrong answers.
        </p>
        <p>
          For each of these questions I'd like you to give an answer on a scale
          of 0 to 10, where 0 is 'not at all' and 10 is 'completely'.
        </p>
      </Field>
      <Field wide>
        <Label strong>Overall...</Label>
        {ONS4_STATEMENTS.map((statement, i) => {
          return (
            <DiscreteRangeInput
              key={statement.short}
              label={statement.long.replace("Overall, ", "")}
              steps={ONS4_STEPS}
              value={ons4 ? ons4[i] : undefined}
              onChange={(value) => onChange({ ons4: { ...ons4, [i]: value } })}
            />
          );
        })}
      </Field>
    </>
  );
};
