import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { FC } from "react";
import { IService } from "_shared/models/Service";
import Button from "_shared/components/Button";

interface ReportShelfServiceProps {
  service: IService;
  serviceId: string;
}

const ReportShelfService: FC<ReportShelfServiceProps> = ({
  service,
  serviceId,
}) => {
  const url = new URL("mailto:help@hand.community");
  url.searchParams.set("subject", `Report Service: ${service.name}`);
  url.searchParams.set(
    "body",
    `https://clinical.hand.community/services/${serviceId}`
  );
  return (
    <Tippy content="Report this service if it is outdated, incorrect, or inappropriate">
      <a href={url.toString()} target="_blank" rel="noopener noreferrer">
        <Button secondary>
          <FontAwesomeIcon icon={faFlag} /> Report
        </Button>
      </a>
    </Tippy>
  );
};

export default ReportShelfService;
