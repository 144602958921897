import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tippy from "@tippyjs/react";
import { FC } from "react";
import "_shared/css/FlagIcon.css";

interface FlagIconProps {
  description: string;
  icon: IconProp;
}

export const FlagIcon: FC<FlagIconProps> = ({ description, icon }) => {
  return (
    <Tippy content={description}>
      <span className="flag-icon">
        <FontAwesomeIcon icon={icon} fixedWidth />
      </span>
    </Tippy>
  );
};
