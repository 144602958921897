import { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  Prompt,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import { merge, set, unset } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faTag } from "@fortawesome/free-solid-svg-icons";
import {
  faClipboardCheck as falClipboardCheck,
  faHeartRate as falHeartRate,
  faSeedling as falSeedling,
  faUser as falUser,
} from "@fortawesome/pro-light-svg-icons";
import { faIdCard } from "@fortawesome/pro-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { nanoid } from "nanoid";
import {
  deleteField,
  doc,
  onSnapshot,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { convertToFirestore, firestore } from "_shared/firebase";
import { useGroup } from "../Group";
import Button from "_shared/components/Button";
import Events from "./Events";
import Goals from "./Goals";
import { DueIcon, InfoIcon } from "_shared/components/Icons";
import Progress from "./Progress";
import SaveState from "_shared/components/SaveState";
import {
  Field,
  Label,
  TextareaField,
  TextField,
  RadioField,
  SearchableSelect,
  SelectField,
  TextFieldClearButton,
  optionsFromArray,
} from "_shared/components/Field";
import UnloadPrompt from "_shared/components/UnloadPrompt";
import { AddressSearchField } from "_shared/components/AddressSearchInput";
import Wrapper from "_shared/components/Wrapper";
import Scrollable from "_shared/components/Scrollable";
import {
  ETHNICITIES,
  GENDERS,
  IPatient,
  IPatientEdit,
  IPatientEvent,
  IPatientGoal,
  MAX_FLAGS,
} from "_shared/models/Patient";
import "_shared/css/Patient.css";
import { useUrl } from "_shared/hooks";
import Tab, { TabBar, TabIcon } from "_shared/components/Tab";
import UserField from "_shared/components/UserField";
import ActivityLog from "./ActivityLog";
import SideNav, { SideNavLink } from "_shared/components/SideNav";
import { UserContext } from "_shared/components/Auth";
import { FlagIcon } from "_shared/components/FlagIcon";
import { Nullable } from "_shared/utils";
import Text from "_shared/components/Text";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { useFeatureFlags } from "_shared/FeatureFlagsContext";

const usePatientQuery = (
  groupId: string,
  patientId: string
): IPatient | undefined | null => {
  const [patient, setPatient] = useState<IPatient | undefined | null>();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `/groups/${groupId}/patients/${patientId}`),
      (doc) => {
        if (!doc.exists()) {
          setPatient(null);
        } else {
          const data = doc.data();
          const _edits = new Map<string, IPatientEdit>();
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries<any>(data._edits ?? {})
            //Ignore edits that we are waiting for Firestore to complete (_time is null)
            .filter(([_, edit]) => edit._time)
            //Convert Firestore Timestamp to Date object
            .map(([id, edit]) => {
              return [id, { ...edit, _time: edit._time.toDate() }];
            })
            //Sort oldest to most recent
            .sort(([_, a], [__, b]) => a._time - b._time)
            .forEach(([id, edit]) => {
              _edits.set(id, edit);
            });
          const events: Record<string, IPatientEvent> = {};
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries<any>(data.events ?? {}).forEach(([id, event]) => {
            events[id] = {
              ...event,
              tags: event.tags ? new Set(event.tags) : undefined,
            };
          });
          const goals: Record<string, IPatientGoal> = {};
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Object.entries<any>(data.goals ?? {}).forEach(([id, goal]) => {
            goals[id] = {
              ...goal,
              tags: goal.tags ? new Set(goal.tags) : undefined,
              serviceIds: goal.serviceIds
                ? new Set(goal.serviceIds)
                : undefined,
            };
          });
          setPatient({
            ...data,
            _edits,
            events,
            flags: data.flags ? new Set(data.flags) : undefined,
            goals,
            referralReasons: data.referralReasons
              ? new Set(data.referralReasons)
              : undefined,
          });
        }
      },
      (e) => {
        console.error(e);
        setPatient(null);
      }
    );
    return unsubscribe;
  }, [groupId, patientId]);

  return patient;
};

const Patient = () => {
  const { isMycawEnabled } = useFeatureFlags();
  const user = useContext(UserContext);
  //TODO: get groupId from context
  const match = useRouteMatch<{ groupId: string; patientId: string }>();
  const { patientId, groupId } = match.params;
  const { path } = match;
  const url = useUrl();
  const history = useHistory();
  const group = useGroup();
  const formRef = useRef<HTMLFormElement | null>(null);
  const dbPatient = usePatientQuery(groupId, patientId);
  const [pendingEdit, setPendingEdit] = useState<Record<string, {} | null>>({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const isSaved = Object.keys(pendingEdit).length === 0;
  const patientDocPath = `/groups/${groupId}/patients/${patientId}`;
  const patientsIndexPath = `/groups/${groupId}/_meta/patients`;

  //Save to database
  useEffect(() => {
    if (isSaved) return;
    //Don't retry if there's a permissions or other network error
    if (isError) return;
    //Invalid changes are not saved
    if (!isValid) return;
    const save = async () => {
      const batch = writeBatch(firestore);
      const update: Record<string, {}> = {};
      //Store, at most, 1 edit every 15 minutes.
      const [mostRecentEditId, mostRecentEdit] =
        Array.from(dbPatient?._edits ?? []).pop() ?? [];
      const fifteenMinutesAgo = new Date(
        new Date().setMinutes(new Date().getMinutes() - 15)
      );
      const loggablePendingEdit: Record<string, {}> = {};
      Object.entries(pendingEdit).forEach(([path, value]) => {
        // Preserve `null` in edits because we want to log when fields were deleted.
        loggablePendingEdit[path] =
          value === null ? null : convertToFirestore(value);
      });
      if (
        mostRecentEdit &&
        mostRecentEdit._time > fifteenMinutesAgo &&
        mostRecentEdit?._uid === user.uid
      ) {
        //If we're deleting a nested item, remove all changes to it from the most recent edit
        const edit = { ...mostRecentEdit };
        const deletedPaths = Object.entries(pendingEdit)
          .filter(([_, value]) => value === null)
          .map(([path, _]) => path);
        Object.keys(edit).forEach((path) => {
          deletedPaths.forEach((deletedPath) => {
            if (path.startsWith(deletedPath)) delete edit[path];
          });
        });
        update[`_edits.${mostRecentEditId}`] = {
          ...edit,
          ...loggablePendingEdit,
        };
      } else {
        //Save a new edit in edit history
        update[`_edits.${nanoid()}`] = {
          _time: serverTimestamp(),
          _uid: user.uid,
          ...loggablePendingEdit,
        };
      }
      //Apply edit to patient state in database
      Object.entries(pendingEdit).forEach(([path, value]) => {
        update[path] = convertToFirestore(value);
      });
      batch.update(doc(firestore, patientDocPath), update);
      //If searchable fields are being edited
      const indexFields = [
        "firstName",
        "surname",
        "reminder",
        "assignee",
        "flags",
      ] as const;
      const editedFields = new Set(Object.keys(update));
      const editedIndexFields = indexFields.filter((field) =>
        editedFields.has(field)
      );
      if (editedIndexFields.length !== 0) {
        //Update the search index
        const indexUpdate: Record<string, {}> = {};
        editedIndexFields
          .filter((field) => field !== "firstName" && field !== "surname")
          .forEach(
            (field) => (indexUpdate[`${patientId}.${field}`] = update[field])
          );
        if (editedFields.has("firstName") || editedFields.has("surname")) {
          const firstName = update.firstName ?? dbPatient?.firstName;
          const surname = update.surname ?? dbPatient?.surname;
          const name = firstName && surname ? `${surname}, ${firstName}` : "";
          indexUpdate[`${patientId}.name`] = name;
        }
        batch.update(doc(firestore, patientsIndexPath), indexUpdate);
      }
      try {
        await batch.commit();
        setPendingEdit({});
      } catch (e) {
        console.error(e);
        setIsError(true);
        window.alert("Changes could not be saved! Please clear your cache");
      }
    };
    //If user has signalled intent to leave the page...
    if (isLeaving) {
      setIsLeaving(false);
      //Save immediately
      save();
    } else {
      //Otherwise save after debouncing for a few seconds
      const saveTimer = window.setTimeout(save, 6000);
      //Cleanup timer if relevant variables change before it executes
      return () => window.clearTimeout(saveTimer);
    }
  }, [
    isError,
    isSaved,
    pendingEdit,
    isValid,
    isLeaving,
    patientDocPath,
    patientsIndexPath,
    patientId,
    user,
    dbPatient,
  ]);

  // Validate
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!formRef.current) return;
    // React is smart enough to only re-render if `isValid` changes
    setIsValid(formRef.current.checkValidity());
  });

  //Handle edits to top-level fields
  const onChanged = (update: Nullable<Partial<IPatient>>) => {
    //The Firstore update must only include modified fields to allow two editors to work simultaneously
    setPendingEdit({ ...pendingEdit, ...update });
  };

  //When path is e.g. 'goals', this will create a new goal with a random ID and populate it with $initialData
  const onNestedItemCreated = (
    path: string,
    initialData: Partial<IPatientEvent | IPatientGoal>
  ) => {
    const id = nanoid();
    const edit = { ...pendingEdit };
    //Convert initial data into dot.notation so that it doesn't clobber subsequent edits
    Object.entries(initialData).forEach(([key, value]) => {
      edit[`${path}.${id}.${key}`] = value;
    });
    setPendingEdit(edit);
  };

  //When path is e.g. 'goals', this will apply $update to the goal with $id
  const onNestedItemUpdated = (
    path: string,
    id: string,
    update: Nullable<Partial<IPatientEvent | IPatientGoal>>
  ) => {
    const edit = { ...pendingEdit };
    Object.entries(update).forEach(([key, value]) => {
      edit[`${path}.${id}.${key}`] = value;
    });
    setPendingEdit(edit);
  };

  //When path is e.g. 'goals', this will delete the goal with $id
  const onNestedItemDeleted = (path: string, id: string) => {
    const edit: Record<string, {} | null> = {};
    Object.entries(pendingEdit).forEach(([key, value]) => {
      //If the user, for example, edited the name of a goal just before deleting it, we only want to store the deletion (otherwise the order of each change matters)
      if (key.startsWith(`${path}.${id}`)) return;
      edit[key] = value;
    });
    edit[`${path}.${id}`] = null; //Converted to deleteField() in save()
    setPendingEdit(edit);
  };

  if (dbPatient === undefined) return null;
  if (dbPatient === null) {
    return (
      <Wrapper pad>
        <Text variant="h1">Not found</Text>
        <Link to="/">Go home</Link>
      </Wrapper>
    );
  }

  //Build current patient state data by applying pending edits to database state
  const patient = merge({}, dbPatient);
  Object.entries(pendingEdit).forEach(([path, value]) => {
    //null is used to imply a subfield (e.g. a goal) should be deleted
    if (value === null) {
      unset(patient, path);
    } else {
      set(patient, path, value); //use deep setter as pendingEdit may contain updates to subfields stored in dot.notation
    }
  });
  const {
    nhsNumber,
    firstName,
    surname,
    knownAs,
    dateOfBirth,
    dateOfDeath,
    gender,
    ethnicity,
    ethnicityDetails,
    address,
    events = {},
    goals = {},
    referralDate,
    referrerType,
    referrerName,
    referralReasons,
    referralSupport,
    referralNotes,
    reminder,
    hopes,
    barriers,
    support,
    assignee,
    phone,
    email,
    flags = new Set(),
    _edits = new Map(),
  } = patient;
  const name =
    [firstName, surname].filter((name) => name).join(" ") || "New Patient";
  const tomorrow = new Date();
  tomorrow.setDate(new Date().getDate() + 1);
  const isReminderDue = reminder && new Date(reminder) < tomorrow;
  const age = calculateAge(dateOfBirth);
  const isKidscreenAvailable = age !== undefined && age < 18;

  const deletePatient = async () => {
    if (!window.confirm(`Delete patient: ${name}?`)) return;
    const batch = writeBatch(firestore);
    batch.delete(doc(firestore, patientDocPath));
    batch.update(doc(firestore, patientsIndexPath), {
      [patientId]: deleteField(),
    });
    await batch.commit();
    history.replace("..");
  };

  return (
    <form ref={formRef} className="patient">
      <UnloadPrompt when={!isSaved} onBeforeUnload={() => setIsLeaving(true)} />
      <Prompt
        message={(location) => {
          //Alert when navigating away from this patient with unsaved changes
          if (!isSaved && !location.pathname.startsWith(url)) {
            setIsLeaving(true);
            return "Unsaved changes! If you leave now, your changes will not be saved.";
          }
          return true;
        }}
      />
      <header className="patient__header">
        <Wrapper pad>
          <div className="patient__header-row">
            <small className="patient__breadcrumbs">
              <Link to={`/groups/${groupId}/patients`}>
                <LocalizedMessage id="patients_title" />
              </Link>{" "}
              /
            </small>
            <SaveState
              state={
                !isValid || isError ? "invalid" : !isSaved ? "saving" : "saved"
              }
            >
              {isError ? "Unsaved changes: connection error" : undefined}
            </SaveState>
          </div>
          <div
            className="patient__header-row"
            style={{ justifyContent: "flex-start" }}
          >
            <Text variant="h1" className="patient__name">
              {name}
            </Text>
            <div
              style={{
                display: "flex",
                marginLeft: "0.75rem",
                transform: "translateY(-0.125rem)",
              }}
            >
              {knownAs !== undefined && knownAs.length > 0 ? (
                <FlagIcon
                  description={`Known as '${knownAs}'`}
                  icon={faIdCard}
                />
              ) : undefined}
              {flags.size > 0 && (
                <FlagIcon description="Check tags" icon={faTag} />
              )}
            </div>
          </div>
        </Wrapper>
        <TabBar>
          <Tab to={`${url}/identity`} disabled={!isValid}>
            <TabIcon icon={falUser} /> Identity
          </Tab>
          <Tab to={`${url}/consultations`} disabled={!isValid}>
            <TabIcon icon={falClipboardCheck} /> Consultations
          </Tab>
          <Tab to={`${url}/plan`} disabled={!isValid}>
            <TabIcon icon={falSeedling} /> Action Plan
          </Tab>
          <Tab to={`${url}/progress`} disabled={!isValid}>
            <TabIcon icon={falHeartRate} /> Progress
          </Tab>
        </TabBar>
      </header>
      <Scrollable className="flex">
        <Switch>
          <Route path={`${path}`} exact>
            <Redirect to={`${url}/identity`} />
          </Route>
          <Route path={`${path}/identity`}>
            <Wrapper pad className="patient__main">
              <Text variant="h2" id="status">
                Status
              </Text>
              <TextField
                type="date"
                label={
                  <>
                    Reminder
                    {isReminderDue && (
                      <Tippy content={<span>Due</span>}>
                        <DueIcon />
                      </Tippy>
                    )}
                  </>
                }
                value={reminder || ""}
                onChange={(e) => onChanged({ reminder: e.target.value })}
                trailing={
                  <TextFieldClearButton
                    onClick={() => onChanged({ reminder: null })}
                  />
                }
              />
              <Field>
                <Label strong id="flags">
                  Tags
                </Label>
                {group.patientFlags &&
                Object.keys(group.patientFlags).length > 0 ? (
                  <>
                    <SearchableSelect
                      aria-labelledby="flags"
                      options={Object.entries(group.patientFlags)
                        .map(([value, label]) => {
                          return { value, label };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))}
                      value={Array.from(flags).map((flag) => {
                        return {
                          value: flag,
                          label: group.patientFlags?.[flag] ?? (
                            <em>Deleted tag</em>
                          ),
                        };
                      })}
                      onChange={(options) =>
                        onChanged({
                          flags: new Set(options.map(({ value }) => value)),
                        })
                      }
                      isOptionDisabled={() => flags.size >= MAX_FLAGS}
                      placeholder=""
                      menuPlacement="auto"
                      isMulti
                      isClearable={false}
                    />
                    <Text variant="small">
                      {flags.size}/{MAX_FLAGS}
                    </Text>
                  </>
                ) : (
                  <em>
                    You can configure tags in{" "}
                    <Link to={`/groups/${groupId}/settings/patients`}>
                      Settings
                    </Link>
                  </em>
                )}
              </Field>
              <UserField
                uid={assignee}
                onChange={(value) => onChanged({ assignee: value })}
              />
              <hr />
              <Text variant="h2" id="identity">
                Identity
              </Text>
              <TextField
                label="NHS Number"
                value={nhsNumber || ""}
                onChange={(e) => onChanged({ nhsNumber: e.target.value })}
                onPaste={(e) => {
                  //Format pasted NHS number (stripping whitespace etc.)
                  const number = e.clipboardData
                    .getData("text/plain")
                    .replace(/\D/g, "");
                  const previousValue = e.currentTarget.value.slice(
                    0,
                    e.currentTarget.selectionStart ?? undefined
                  );
                  e.currentTarget.value = previousValue + number;
                  onChanged({ nhsNumber: e.currentTarget.value });
                }}
                pattern="\d{10}"
                maxLength={10}
              />
              <TextField
                label="First Name"
                type="text"
                value={firstName || ""}
                onChange={(e) => onChanged({ firstName: e.target.value })}
              />
              <TextField
                label="Surname"
                type="text"
                value={surname || ""}
                onChange={(e) => onChanged({ surname: e.target.value })}
              />
              <TextField
                label={
                  <>
                    Known As
                    <Tippy
                      content={
                        <span>
                          <LocalizedMessage id="patients_known_as" />
                        </span>
                      }
                    >
                      <InfoIcon />
                    </Tippy>
                  </>
                }
                onChange={(e) => onChanged({ knownAs: e.target.value })}
                placeholder={firstName}
                value={knownAs || ""}
              />
              <TextField
                label="Date of Birth"
                type="date"
                value={dateOfBirth || ""}
                onChange={(e) => {
                  onChanged({ dateOfBirth: e.target.value || null });
                }}
                max={new Date().toLocaleDateString("en-CA")}
              />
              <TextField
                label="Date of Death"
                type="date"
                value={dateOfDeath || ""}
                onChange={(e) => {
                  onChanged({ dateOfDeath: e.target.value || null });
                }}
                max={new Date().toLocaleDateString("en-CA")}
              />
              <RadioField
                label="Gender"
                value={gender || ""}
                onChange={(gender) => onChanged({ gender })}
                options={optionsFromArray(GENDERS)}
              />
              <SelectField
                label="Ethnicity"
                value={ethnicity}
                onChange={(e) => onChanged({ ethnicity: e.target.value })}
              >
                <option value="">-</option>
                {Object.entries(ETHNICITIES).map(([group, options]) => {
                  return (
                    <optgroup key={group} label={group}>
                      {options.map((option) => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </SelectField>
              {(ethnicity || "").toLowerCase().startsWith("other") && (
                <TextField
                  label={`Describe Ethnicity: ${ethnicity}`}
                  type="text"
                  value={ethnicityDetails || ""}
                  onChange={(e) =>
                    onChanged({ ethnicityDetails: e.target.value })
                  }
                  required
                />
              )}
              <hr />
              <Text variant="h2" id="contact">
                Contact
              </Text>
              <AddressSearchField
                value={address || ""}
                onChange={({ address, postcode, lat, lng }) =>
                  onChanged({ address, postcode, lat, lng })
                }
              />
              <TextField
                label="Email"
                type="email"
                value={email || ""}
                onChange={(e) => onChanged({ email: e.target.value })}
              />
              <TextField
                label="Phone"
                type="tel"
                value={phone || ""}
                onChange={(e) => onChanged({ phone: e.target.value })}
              />
              <hr />
              <Text variant="h2" id="referral">
                Referral
              </Text>
              <TextField
                label="Referral date"
                type="date"
                value={referralDate || ""}
                onChange={(e) => onChanged({ referralDate: e.target.value })}
                max={new Date().toLocaleDateString("en-CA")}
              />
              <Field>
                <Label strong id="referrer_type">
                  Referrer
                </Label>
                {Object.keys(group?.patientReferrers ?? {}).length === 0 ? (
                  <em>
                    You can configure referrers in{" "}
                    <Link to={`/groups/${groupId}/settings/patients`}>
                      Settings
                    </Link>
                  </em>
                ) : (
                  <SearchableSelect
                    aria-labelledby="referrer_type"
                    options={Object.entries(group!.patientReferrers!)
                      .map(([value, label]) => {
                        return { value, label };
                      })
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    value={
                      referrerType
                        ? {
                            value: referrerType,
                            label: group!.patientReferrers![referrerType],
                          }
                        : undefined
                    }
                    onChange={(option) =>
                      onChanged({
                        referrerType: option?.value || null,
                      })
                    }
                    placeholder=""
                    menuPlacement="auto"
                    isClearable
                  />
                )}
              </Field>
              <TextField
                label="Name of referrer"
                value={referrerName || ""}
                onChange={(e) => onChanged({ referrerName: e.target.value })}
              />
              <Field>
                <Label strong id="referral_reasons">
                  Reasons for referral
                </Label>
                {Object.keys(group?.patientReferralReasons || {}).length ===
                0 ? (
                  <em>
                    You can configure reasons in{" "}
                    <Link to={`/groups/${groupId}/settings/patients`}>
                      Settings
                    </Link>
                  </em>
                ) : (
                  <SearchableSelect
                    aria-labelledby="referral_reasons"
                    options={Object.entries(group!.patientReferralReasons!)
                      .map(([value, label]) => {
                        return { value, label };
                      })
                      .sort((a, b) => a.label.localeCompare(b.label))}
                    value={Array.from(referralReasons ?? []).map((id) => {
                      return {
                        value: id,
                        label: group!.patientReferralReasons![id],
                      };
                    })}
                    onChange={(options) =>
                      onChanged({
                        referralReasons: new Set(
                          options.map(({ value }) => value)
                        ),
                      })
                    }
                    placeholder=""
                    menuPlacement="auto"
                    isMulti
                  />
                )}
              </Field>
              <RadioField
                label="Level of support"
                value={referralSupport}
                onChange={(referralSupport) => onChanged({ referralSupport })}
                options={optionsFromArray([
                  "One-to-one",
                  "Signposting",
                  "Triage",
                ])}
              />
              <TextareaField
                label="Referral notes"
                rows={3}
                value={referralNotes || ""}
                onChange={(e) => onChanged({ referralNotes: e.target.value })}
              />
              <hr />
              <Text variant="h2" id="manage">
                Manage
              </Text>
              <Field>
                <Button secondary kind="danger" onClick={deletePatient}>
                  <FontAwesomeIcon icon={faMinusCircle} />{" "}
                  <LocalizedMessage id="patients_delete_patient" />
                </Button>
              </Field>
            </Wrapper>
            <SideNav>
              <SideNavLink href="#status">Status</SideNavLink>
              <SideNavLink href="#identity">Identity</SideNavLink>
              <SideNavLink href="#contact">Contact</SideNavLink>
              <SideNavLink href="#referral">Referral</SideNavLink>
              <SideNavLink href="#manage">Manage</SideNavLink>
            </SideNav>
          </Route>
          <Route path={`${path}/consultations`}>
            <Wrapper pad wide className="patient__main">
              <Events
                eventsById={events}
                onCreate={(initialData) =>
                  onNestedItemCreated("events", initialData)
                }
                onUpdate={(id, update) =>
                  onNestedItemUpdated("events", id, update)
                }
                onDelete={(id) => onNestedItemDeleted("events", id)}
                isOuterFormValid={isValid}
                isKidscreenAvailable={isKidscreenAvailable}
              />
            </Wrapper>
          </Route>
          <Route path={`${path}/plan`}>
            <Wrapper pad className="patient__main">
              <Text variant="h2" id="introduction">
                Introduction
              </Text>
              <TextareaField
                label="What areas of life would you like to change?"
                value={hopes || ""}
                onChange={(e) => onChanged({ hopes: e.target.value })}
                rows={3}
              />
              <hr />
              <Field wide className="patient__goals">
                <Goals
                  goals={goals}
                  onCreate={(initialData) =>
                    onNestedItemCreated("goals", initialData)
                  }
                  onUpdate={(id, update) =>
                    onNestedItemUpdated("goals", id, update)
                  }
                  onDelete={(id) => onNestedItemDeleted("goals", id)}
                  isOuterFormValid={isValid}
                />
              </Field>
              <hr />
              <Text variant="h2" id="support">
                Support
              </Text>
              <TextareaField
                label="What barriers do you see in achieving these goals?"
                value={barriers || ""}
                onChange={(e) => onChanged({ barriers: e.target.value })}
                rows={3}
              />
              <TextareaField
                label="What support might you need to achieve these goals?"
                value={support || ""}
                onChange={(e) => onChanged({ support: e.target.value })}
                rows={3}
              />
            </Wrapper>
            <SideNav>
              <SideNavLink href="#introduction">Introduction</SideNavLink>
              <SideNavLink href="#goals">Goals</SideNavLink>
              <SideNavLink href="#support">Support</SideNavLink>
            </SideNav>
          </Route>
          <Route path={`${path}/progress`}>
            <Wrapper pad className="patient__main">
              <Progress
                eventsById={events}
                isKidscreenAvailable={isKidscreenAvailable}
                name={name}
              />
              <hr />
              <Text variant="h2" id="activity">
                Activity Log
              </Text>
              <ActivityLog edits={_edits} eventsById={events} />
            </Wrapper>
            <SideNav>
              <SideNavLink href="#consultations">Consultations</SideNavLink>
              <SideNavLink href="#bmi">BMI</SideNavLink>
              {isMycawEnabled && <SideNavLink href="#mycaw">MYCaW</SideNavLink>}
              <SideNavLink href="#warwick-edinburgh">
                Warwick-Edinburgh
              </SideNavLink>
              <SideNavLink href="#ons4">ONS4</SideNavLink>
              <SideNavLink href="#loneliness">Loneliness</SideNavLink>
              {isKidscreenAvailable && (
                <SideNavLink href="#kidscreen">Kidscreen</SideNavLink>
              )}
              <SideNavLink href="#activity">Activity Log</SideNavLink>
            </SideNav>
          </Route>
        </Switch>
      </Scrollable>
    </form>
  );
};
export default Patient;

const calculateAge = (dateOfBirth?: string): number | undefined => {
  if (!dateOfBirth) return undefined;
  const now = new Date();
  const birth = new Date(dateOfBirth);
  if (isNaN(birth.getTime())) return undefined;
  const diffYears = now.getFullYear() - birth.getFullYear();
  const diffMonths = now.getMonth() - birth.getMonth();
  if (diffMonths < 0 || (diffMonths === 0 && now.getDate() < birth.getDate())) {
    return diffYears - 1;
  } else {
    return diffYears;
  }
};
