import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "_shared/css/SaveState.css";
import {
  faCloud,
  faCloudUploadAlt,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FC } from "react";

interface SaveStateProps {
  state: "saving" | "saved" | "invalid";
}

const SaveState: FC<SaveStateProps> = ({ state, children }) => {
  const classes = ["save-state"];
  if (state) classes.push(`${classes[0]}--${state}`);
  const icon =
    state === "saving" ? (
      <FontAwesomeIcon icon={faCloudUploadAlt} />
    ) : state === "invalid" ? (
      <FontAwesomeIcon icon={faExclamationTriangle} />
    ) : (
      <FontAwesomeIcon icon={faCloud} />
    );
  const message =
    state === "saving"
      ? "Saving..."
      : state === "invalid"
      ? "Unsaved changes: fix errors"
      : "Changes saved";
  return (
    <small className={classes.join(" ")}>
      {icon} {children || message}
    </small>
  );
};

export default SaveState;
