import {
  BarController,
  BarElement,
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { enGB } from "date-fns/locale";

Chart.register(
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

//Set global defaults
Chart.defaults.font.family =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
Chart.defaults.font.size = 14;
Chart.defaults.plugins.legend.position = "left";
Chart.defaults.plugins.legend.labels.usePointStyle = true;
Chart.defaults.plugins.legend.labels.boxWidth = 7;
Chart.defaults.plugins.legend.labels.boxHeight = 7;
Chart.defaults.scales.time.adapters.date = {
  locale: enGB,
};
Chart.defaults.elements.line.tension = 0.3;

//Maximally differentiable colors
export const CHART_COLORS = [
  "#E692B6",
  "#E7DB2A",
  "#549688",
  "#371C50",
  "#D44320",
  "#A8E1DD",
  "#CF1E25",
  "#78797B",
  "#7A161A",
  "#6FC847",
  "#4B66BA",
  "#EBC38A",
  "#DAC4F7",
  "#5243A3",
  "#DD991E",
  "#96187F",
];
