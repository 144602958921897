import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "_shared/firebase";
import { ILocation } from "_shared/models/Location";
import { LocationsIndex } from "_shared/models/_meta";
import { localeSort } from "_shared/utils";

export type LocationPreview = Pick<ILocation, "name" | "address"> & {
  id: string;
};

export const useLocationsQuery = (
  groupId: string
): ReadonlyArray<LocationPreview> | undefined => {
  const [locations, setLocations] = useState<
    ReadonlyArray<LocationPreview> | undefined
  >();

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, `_meta/locations`),
      (doc) => {
        const data: LocationsIndex = doc.data() ?? {};
        const freshLocations = Object.entries(data[groupId] ?? {})
          .map(([id, location]) => {
            return { id, ...location };
          })
          .sort((a, b) => localeSort(a.name, b.name));
        setLocations(freshLocations);
      },
      (error) => {
        console.error(error);
      }
    );
    return unsubscribe;
  }, [groupId]);

  return locations;
};
