import { set, unset } from "lodash";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Link, Prompt, useHistory, useParams } from "react-router-dom";
import ngeohash from "ngeohash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faGlobeAfrica,
  faMinusCircle,
  faPaperPlane,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRight as falArrowRight } from "@fortawesome/pro-light-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  deleteField,
  doc,
  GeoPoint,
  onSnapshot,
  writeBatch,
} from "firebase/firestore";
import { firestore } from "_shared/firebase";
import { AddressSearchField } from "_shared/components/AddressSearchInput";
import {
  TextField,
  Field,
  Input,
  Label,
  SelectField,
} from "_shared/components/Field";
import * as api from "_shared/api";
import Button, { ButtonGroup } from "_shared/components/Button";
import UnloadPrompt from "_shared/components/UnloadPrompt";
import "_shared/css/Location.css";
import Wrapper from "_shared/components/Wrapper";
import Scrollable from "_shared/components/Scrollable";
import SaveState from "_shared/components/SaveState";
import { IKeyNumber, ILocation, LOCATION_TYPES } from "_shared/models/Location";
import Text from "_shared/components/Text";
import { useDynamicLinks } from "_shared/components/DynamicLinks";

const Location: FC = () => {
  //TODO: get groupId from context
  const { groupId, locationId } = useParams<{
    groupId: string;
    locationId: string;
  }>();
  const history = useHistory();
  const { buildLink } = useDynamicLinks();
  const [dbLocation, setDbLocation] = useState<ILocation | null | undefined>();
  const [pendingEdit, setPendingEdit] = useState<Partial<ILocation>>({});
  const [isValid, setIsValid] = useState<boolean>();
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const [uploads, setUploads] = useState<Record<string, number>>({});
  const formRef = useRef<HTMLFormElement | null>(null);
  const locationDocPath = `groups/${groupId}/locations/${locationId}`;
  const isSaved = Object.keys(pendingEdit).length === 0;
  const location = { ...dbLocation };
  Object.entries(pendingEdit).forEach(([path, value]) => {
    // null is used to imply a subfield should be deleted
    if (value === null) unset(location, path);
    else set(location, path, value); // use deep setter as pendingEdit may contain updates to subfields stored in dot.notation
  });

  // Listen to location
  useEffect(() => {
    const unsubscribe = onSnapshot(doc(firestore, locationDocPath), (doc) => {
      if (!doc.exists()) {
        setDbLocation(null);
      } else {
        setDbLocation(doc.data());
      }
    });
    return unsubscribe;
  }, [locationDocPath]);
  // Validate
  useEffect(() => {
    setIsValid(formRef.current?.checkValidity() === true);
  }, [formRef, pendingEdit, dbLocation]);
  // Save to database
  useEffect(() => {
    if (isSaved) return;
    if (!isValid) return;
    const save = async () => {
      const batch = writeBatch(firestore);
      // Update location
      batch.update(doc(firestore, locationDocPath), pendingEdit);
      // Update index on group if relevant fields changed
      const indexUpdate: Record<string, Record<string, string> | string> = {
        _latestEdit: {
          groupId,
        },
      };
      const indexFields = ["name", "address"] as const;
      for (const field of indexFields) {
        const value = pendingEdit[field];
        if (value) {
          indexUpdate[`${groupId}.${locationId}.${field}`] = value;
        }
      }
      if (Object.keys(indexUpdate).length > 0) {
        batch.update(doc(firestore, `_meta/locations`), indexUpdate);
      }
      await batch.commit();
      setPendingEdit({});
    };
    // If user has signalled intent to leave the page...
    if (isLeaving) {
      setIsLeaving(false);
      // Save immediately
      save();
    } else {
      // Otherwise save after debouncing for a few seconds
      const saveTimer = window.setTimeout(save, 6000);
      // Cleanup timer if relevant variables change before it executes
      return () => window.clearTimeout(saveTimer);
    }
  }, [
    isSaved,
    pendingEdit,
    isValid,
    isLeaving,
    locationDocPath,
    groupId,
    locationId,
  ]);

  const onChanged = useCallback(
    (update: Record<string, unknown>) =>
      setPendingEdit({ ...pendingEdit, ...update }),
    [pendingEdit]
  );

  const uploadImage = useCallback(
    async (fileId: "logo" | "header", file?: File) => {
      if (!file) return;
      const extension = file.name.split(".").pop();
      const path = `${locationDocPath}/${fileId}.${extension}`;
      const downloadUrl = await api.uploadImage(
        path,
        file,
        (progress: number) => {
          // Give the user upload progress feedback
          setUploads({
            ...uploads,
            [fileId]: progress, //percent
          });
        }
      );
      // Record new URL
      onChanged({
        [`images.${fileId}`]: downloadUrl,
      });
      // Clear upload progress
      const newUploads = { ...uploads };
      delete uploads[fileId];
      setUploads(newUploads);
    },
    [locationDocPath, onChanged, uploads]
  );

  const deleteLocation = async () => {
    if (!window.confirm(`Delete location: ${location.name}?`)) return;
    const batch = writeBatch(firestore);
    batch.delete(doc(firestore, locationDocPath));
    batch.update(doc(firestore, `_meta/locations`), {
      [`${groupId}.${locationId}`]: deleteField(),
      _latestEdit: {
        groupId,
      },
    });
    history.replace(".");
    batch.commit();
  };

  const emailLinkToLocation = async () => {
    try {
      const link = await buildLink(
        `/groups/${groupId}/locations/${locationId}`
      );
      const subject = encodeURIComponent(
        `Find ${location.name || "us"} on Help @ Hand`
      );
      window.open(`mailto:?subject=${subject}&body=${link}`);
    } catch (e) {
      alert("Something went wrong. Please try again later.");
      console.error(e);
    }
  };

  if (dbLocation === undefined) return null;
  if (dbLocation === null) return <p>Not found</p>;

  return (
    <form ref={formRef} className="location">
      <UnloadPrompt when={!isSaved} onBeforeUnload={() => setIsLeaving(true)} />
      <Prompt
        when={!isSaved}
        message={() => {
          setIsLeaving(true);
          return "Your changes have not been saved.\n\nPress OK to discard changes.";
        }}
      />

      <header className="location__header">
        <Wrapper pad>
          <small className="location__breadcrumbs">
            <Link to=".">Locations</Link> /
          </small>
          <div className="location__header-row">
            <div>
              <Text variant="h1" className="location__title">
                {location.name}
              </Text>
              {!location.services && (
                <small style={{ color: "var(--danger)" }}>
                  0 services have been published to this location
                </small>
              )}
            </div>
            <SaveState
              state={!isValid ? "invalid" : !isSaved ? "saving" : "saved"}
            />
          </div>
          <ButtonGroup>
            <Button secondary onClick={emailLinkToLocation}>
              <FontAwesomeIcon icon={faPaperPlane} /> Share
            </Button>
          </ButtonGroup>
        </Wrapper>
      </header>

      <Scrollable className="location__main">
        <Wrapper pad>
          <Text variant="h2" id="location">
            Location
          </Text>
          <TextField
            label="Name"
            value={location.name || ""}
            onChange={(e) => onChanged({ name: e.target.value })}
            required
            invalidHelp="Name is required"
          />
          <AddressSearchField
            value={location.address || ""}
            onChange={({ address, lat, lng }) => {
              onChanged({
                address,
                position: {
                  geohash: ngeohash.encode(lat, lng),
                  geopoint: new GeoPoint(lat, lng),
                },
              });
            }}
            requireLatLng
            required
          />
          <SelectField
            label="Type"
            value={location.type || "Surgery"}
            onChange={(e) => onChanged({ type: e.target.value })}
          >
            {LOCATION_TYPES.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </SelectField>
          <Field className="filefield">
            <Label strong htmlFor="images_logo">
              Logo
            </Label>
            {location.images?.logo && (
              <img
                className={`filefield__img ${
                  "logo" in uploads ? "filefield__img--loading" : ""
                }`}
                src={location.images.logo}
                alt="Preview"
              />
            )}
            <Input
              id="images_logo"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => uploadImage("logo", e.target.files?.[0])}
            />
            {"logo" in uploads && <progress value={uploads.logo}></progress>}
            <small>PNG, at least 300 pixels wide.</small>
          </Field>
          <Field className="filefield">
            <Label strong htmlFor="images_header">
              Photo
            </Label>
            {location.images?.header && (
              <img
                className={`filefield__img ${
                  "header" in uploads ? "filefield__img--loading" : ""
                }`}
                src={location.images.header}
                alt="Preview"
              />
            )}
            <Input
              id="images_header"
              type="file"
              accept="image/png, image/jpeg"
              onChange={(e) => uploadImage("header", e.target.files?.[0])}
            />
            {"header" in uploads && (
              <progress max="100" value={uploads.header}></progress>
            )}
            <small>JPEG, at least 1080 pixels wide.</small>
          </Field>
          <hr />

          <Text variant="h2">Opening Times</Text>
          <OpeningTimes
            onChange={(openingTimes) => onChanged({ openingTimes })}
            value={location.openingTimes}
          />
          <hr />

          <Text variant="h2">Links</Text>
          <TextField
            label={
              <>
                <FontAwesomeIcon icon={faGlobeAfrica} /> Website
              </>
            }
            type="url"
            value={location.links?.website || ""}
            onChange={(e) => onChanged({ "links.website": e.target.value })}
            invalidHelp="Enter a valid URL, e.g. https://example.com"
          />
          <TextField
            label={
              <>
                <FontAwesomeIcon icon={faEnvelope} /> Email
              </>
            }
            type="email"
            value={location.links?.email || ""}
            onChange={(e) => onChanged({ "links.email": e.target.value })}
            invalidHelp="Enter a valid email, e.g. jbloggs@example.com"
          />
          <TextField
            label={
              <>
                <FontAwesomeIcon icon={faWhatsapp} /> WhatsApp
              </>
            }
            type="tel"
            value={location.links?.whatsapp || ""}
            onChange={(e) => onChanged({ "links.whatsapp": e.target.value })}
            pattern="(\d+\s?)+\d"
            maxLength={20}
            invalidHelp="Enter one number, using digits 0-9"
          />
          <hr />

          <Text variant="h2">Key Numbers</Text>
          <KeyNumbers
            numbers={location.numbers}
            onChange={(numbers) => onChanged({ numbers })}
          />
          <hr />

          <Text variant="h2">Notice</Text>
          <TextField
            label="Message"
            value={location.notice?.message || ""}
            onChange={(e) => onChanged({ "notice.message": e.target.value })}
          />
          <TextField
            label="Link"
            type="url"
            value={location.notice?.link || ""}
            onChange={(e) => onChanged({ "notice.link": e.target.value })}
            invalidHelp="Enter a valid URL, e.g. https://example.com"
          />
          <hr />

          <Text variant="h2">Manage</Text>
          <Field>
            <Button secondary kind="danger" onClick={deleteLocation}>
              <FontAwesomeIcon icon={faMinusCircle} /> Delete location
            </Button>
          </Field>
        </Wrapper>
      </Scrollable>
    </form>
  );
};
export default Location;

interface OpeningTimesProps {
  onChange: (days: ReadonlyArray<Record<string, string>>) => void;
  value?: ReadonlyArray<Record<string, string>>;
}

const OpeningTimes: FC<OpeningTimesProps> = ({ onChange, value = [] }) => {
  const days = useCallback(() => {
    return [
      { day: "Monday", times: "" },
      { day: "Tuesday", times: "" },
      { day: "Wednesday", times: "" },
      { day: "Thursday", times: "" },
      { day: "Friday", times: "" },
      { day: "Saturday", times: "" },
      { day: "Sunday", times: "" },
    ].map((initial) => {
      return value.find((entry) => entry.day === initial.day) || initial;
    });
  }, [value]);

  const handleChange = useCallback(
    (day: string, times: string) => {
      onChange(
        days().map((d) => {
          if (d.day === day) return { day, times };
          return d;
        })
      );
    },
    [days, onChange]
  );

  return (
    <div className="opening-times">
      {days().map((day) => {
        return (
          <div key={day.day} className="opening-times__time">
            <TextField
              label={day.day}
              value={day.times}
              onChange={(e) => handleChange(day.day, e.target.value)}
            />
          </div>
        );
      })}
    </div>
  );
};

interface KeyNumbersProps {
  onChange: (update: ReadonlyArray<IKeyNumber>) => void;
  numbers?: ReadonlyArray<IKeyNumber>;
}

const KeyNumbers: FC<KeyNumbersProps> = ({ numbers = [], onChange }) => {
  const numbersFromProps = useCallback(
    () =>
      Array.isArray(numbers)
        ? numbers.filter((number) => number.label || number.number) //remove empty
        : [],
    [numbers]
  );

  const handleChange = useCallback(
    (index, update) => {
      let newNumbers = numbersFromProps();
      newNumbers[index] = { ...newNumbers[index], ...update };
      onChange(newNumbers);
    },
    [numbersFromProps, onChange]
  );

  const addNumber = useCallback(() => {
    let newNumbers = numbersFromProps();
    newNumbers.push({ label: "", number: "" });
    onChange(newNumbers);
  }, [numbersFromProps, onChange]);

  const handleDelete = useCallback(
    (index) => {
      let newNumbers = numbersFromProps();
      newNumbers.splice(index, 1);
      onChange(newNumbers);
    },
    [numbersFromProps, onChange]
  );

  return (
    <div>
      {numbers.map((number, i) => (
        <KeyNumber
          key={i}
          label={number.label}
          value={number.number}
          onChange={(update) => handleChange(i, update)}
          onDelete={() => handleDelete(i)}
        />
      ))}
      <Button secondary onClick={addNumber}>
        <FontAwesomeIcon icon={faPlus} /> Add Number
      </Button>
    </div>
  );
};

interface KeyNumberProps {
  label?: string;
  value?: string;
  onChange: (update: Partial<IKeyNumber>) => void;
  onDelete: () => void;
}

const KeyNumber: FC<KeyNumberProps> = ({
  label = "",
  value = "",
  onChange,
  onDelete,
}) => {
  return (
    <div className="key-number">
      <Input
        type="text"
        value={label}
        onChange={(e) => onChange({ label: e.target.value })}
        placeholder="Name"
      />
      <FontAwesomeIcon className="key-number__icon" icon={falArrowRight} />
      <Input
        type="tel"
        value={value}
        onChange={(e) => onChange({ number: e.target.value })}
        placeholder="Number"
        pattern="(\d+\s?)+\d"
        maxLength={20}
      />
      <Button secondary kind="danger" onClick={onDelete}>
        <FontAwesomeIcon className="key-number__icon" icon={faMinusCircle} />
      </Button>
    </div>
  );
};
