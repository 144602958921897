import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { mergeWith } from "lodash";
import { FC, useContext, useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import { convertToFirestore, firestore } from "_shared/firebase";
import { useUrl } from "_shared/hooks";
import { IService, IShelfService } from "_shared/models/Service";
import { Nullable } from "_shared/utils";
import SaveState from "_shared/components/SaveState";
import EditService from "../groups/services/EditService";
import Text from "_shared/components/Text";
import "_shared/css/EditShelfService.css";
import { ServiceTitle } from "../groups/services/ServiceTitle";
import Users from "_shared/components/Users";
import { UserContext } from "_shared/components/Auth";
import { setUserPermissionsOnService } from "_shared/api";
import { useShelfServiceQuery } from "../groups/helpshelf/HelpShelf.hooks";

interface EditShelfServiceProps {
  onValidityChange: (isValid: boolean) => void;
  serviceId: string;
  isCreating: boolean;
  onSave: () => void;
}

const EditShelfService: FC<EditShelfServiceProps> = ({
  onValidityChange,
  serviceId,
  isCreating,
  onSave,
}) => {
  const history = useHistory();
  const url = useUrl();
  const user = useContext(UserContext);
  const dbService = useShelfServiceQuery(serviceId);
  const [pendingEdit, setPendingEdit] = useState<
    Nullable<Omit<IShelfService, "users" | "_userProfiles">>
  >({});
  const [isValid, setIsValid] = useState<boolean>(true);
  const isSaved = Object.keys(pendingEdit).length === 0;
  const service = mergeWith(
    {},
    dbService,
    pendingEdit,
    (_currentValue: unknown, newValue: unknown) => {
      return newValue ?? undefined;
    }
  ) as IShelfService;

  // Save
  useEffect(() => {
    if (isSaved) return;
    if (!isValid) return;
    const save = async () => {
      const update = convertToFirestore({
        ...pendingEdit,
        ...(isCreating
          ? {
              users: [user.uid],
              _userProfiles: {
                [user.uid]: {
                  email: user.email,
                },
              },
            }
          : {}),
      });
      await setDoc(doc(firestore, `services/${serviceId}`), update, {
        merge: true,
      });
      setPendingEdit({});
      onSave();
    };
    // Save after debouncing for a few seconds
    const saveTimer = window.setTimeout(save, 6000);
    return () => window.clearTimeout(saveTimer);
  }, [
    isCreating,
    isSaved,
    isValid,
    onSave,
    pendingEdit,
    serviceId,
    user.email,
    user.uid,
  ]);
  // Reset when service changes
  useEffect(() => {
    setPendingEdit({});
  }, [serviceId]);

  const handleChange = (update: Nullable<IService>) => {
    setPendingEdit({
      ...pendingEdit,
      ...update,
    });
  };
  const handleValidityChange = (isNowValid: boolean) => {
    setIsValid(isNowValid);
    onValidityChange(isNowValid);
  };
  const handleDelete = async () => {
    if (
      !window.confirm(
        "Deleting this service will make it unavailable to all surgeries/support networks."
      )
    )
      return;
    history.replace(".");
    await deleteDoc(doc(firestore, `services/${serviceId}`));
  };
  const handleAddUsersByEmail = async (emails: ReadonlyArray<string>) => {
    await setUserPermissionsOnService({
      serviceId,
      emails: emails,
      action: "add",
    });
  };
  const handleRemoveUserByEmail = async (email: string) => {
    await setUserPermissionsOnService({
      serviceId,
      emails: [email],
      action: "remove",
    });
  };

  if (dbService === null && !isCreating) return <em>Not found</em>;
  if (dbService === undefined) return null;

  return (
    <>
      <Prompt
        message={(location) => {
          //Alert when navigating away from this patient with unsaved changes
          if (!isSaved && !location.pathname.startsWith(url)) {
            return "Unsaved changes! If you leave now, your changes will not be saved.";
          }
          return true;
        }}
      />
      <div className="edit-shelf-service__header">
        <Text variant="h2">
          <ServiceTitle service={service} />
        </Text>
        <SaveState
          state={!isValid ? "invalid" : !isSaved ? "saving" : "saved"}
        />
      </div>
      <EditService
        service={service}
        onChange={handleChange}
        onValidityChange={handleValidityChange}
        onDelete={handleDelete}
      />
      {!isCreating && (
        <>
          <hr />
          <Text variant="h3">Editors</Text>
          <p>
            Editors can change or delete this service, as well as add/remove
            other editors.
          </p>
          <Users
            onAddEmails={handleAddUsersByEmail}
            onRemoveEmail={handleRemoveUserByEmail}
            userProfiles={service._userProfiles}
          />
        </>
      )}
    </>
  );
};

export default EditShelfService;
