import { ComponentProps, FC, ReactNode } from "react";
import "_shared/css/Timeline.css";
import Text from "./Text";

interface TimelineProps extends ComponentProps<"div"> {}

const Timeline: FC<TimelineProps> = ({ children, ...otherProps }) => {
  return (
    <div className="timeline" {...otherProps}>
      {children}
    </div>
  );
};
export default Timeline;

interface TimelineEntryProps extends Omit<ComponentProps<"div">, "title"> {
  title?: ReactNode;
  overline: ReactNode;
}

export const TimelineEntry: FC<TimelineEntryProps> = ({
  title,
  overline,
  children,
  ...otherProps
}) => {
  return (
    <div className="timeline__entry" {...otherProps}>
      {overline && <small className="timeline__overline">{overline}</small>}
      <Text variant="h3" className="timeline__title">
        {title}
      </Text>
      <div className="timeline__content">{children}</div>
    </div>
  );
};
