import { ILocation } from "./Location";
import { IService } from "./Service";

export interface IGroup {
  readonly name?: string;
  readonly address?: string;
  readonly services?: Record<string, IService>;
  readonly patientReferralReasons?: Record<string, string>;
  readonly patientReferrers?: Record<string, string>;
  readonly patientEvents?: Record<string, string>;
  readonly patientEventTags?: Record<string, string>;
  readonly patientFlags?: Record<string, string>;
  readonly locations?: Record<string, Omit<ILocation, "services">>;
  readonly users?: ReadonlyArray<string>;
  readonly _userProfiles?: {
    [uid: string]: {
      email: string;
    };
  };
  /**
   * IDs of HelpShelf services
   */
  readonly _globalServiceIds?: Set<string>;
}

export const DEFAULT_GROUP = {
  patientReferralReasons: {
    "60": "Parenting",
    "72": "Volunteering",
    "4P": "Substance misuse",
    UL: "Physical activity",
    mu: "Caring responsibilities",
    oj: "Training and learning",
    "0L": "Bereavement",
    "1U": "Weight management",
    ee: "Mental health",
    vE: "Relationship support",
    "3S": "Social isolation",
    X_: "Smoking cessation",
    DD: "Work",
    l2: "Housing and environment",
    wk: "Financial advice",
    SE: "Long-term condition",
  },
  patientEventTags: {
    KP: "Text",
    tk: "Phone",
    "0J": "In-person",
    sJ: "Letter",
    DH: "Email",
    Nj: "Video",
  },
  patientReferrers: {
    OF: "External: Occupational Therapist",
    Wf: "External: Physiotherapist",
    Lq: "External: other",
    "7U": "Internal: Physiotherapist",
    R2: "Internal: Link worker",
    IB: "Internal: Nurse",
    pW: "Internal: Pharmacist",
    RB: "Internal: Integration Team",
    tH: "Internal: GP",
    "9I": "Internal: Receptionist",
    j6: "Internal: other",
    iZ: "Self",
  },
  patientEvents: {
    QF: "Initial consultation",
    F5: "Review",
    "2L": "Discharge: unable to contact",
    d1: "Discharge: patient declines service",
    "7F": "Discharge: moved out of area",
    GZ: "Discharge: inappropriate referral",
    u5: "Discharge: signposted",
    Qa: "Completion",
  },
};
