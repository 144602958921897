import Wrapper from "_shared/components/Wrapper";
import "_shared/css/PublicHeader.css";
import { FC, ReactNode } from "react";

interface PublicHeaderProps {
  trailing?: ReactNode;
}

const PublicHeader: FC<PublicHeaderProps> = ({ trailing }) => {
  return (
    <div className="public-header">
      <Wrapper wide pad center className="public-header__wrapper">
        <img
          className="public-header__logo"
          src="/assets/logo-horizontal.svg"
          alt="Help @ Hand"
        />
        <div>{trailing}</div>
      </Wrapper>
    </div>
  );
};

export default PublicHeader;
