import { faChevronLeft, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "_shared/components/Button";
import { DownloadLink } from "_shared/components/DownloadLink";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import { Report } from "_shared/models/_meta";
import { Link } from "react-router-dom";

interface Props {
  report: Report;
}

export function MycawReport(props: Props) {
  const { report } = props;
  const { mycawCsvUrl } = report;
  return (
    <Wrapper wide pad>
      <div className="flex flex-col gap-6 py-6">
        <div className="flex flex-col gap-2">
          <Link className="self-start" to=".">
            <Button secondary>
              <FontAwesomeIcon icon={faChevronLeft} /> Reports
            </Button>
          </Link>
          <Text variant="h1">
            Measure Yourself Concerns and Wellbeing (MYCaW®)
          </Text>
        </div>
        {mycawCsvUrl ? (
          <DownloadLink
            filename="MYCaW All Patients.csv"
            getDataUrl={() => mycawCsvUrl}
          >
            <Button>
              <FontAwesomeIcon icon={faFileCsv} />
              Download spreadsheet
            </Button>
          </DownloadLink>
        ) : (
          <>No MYCaW® data was recorded last month, check again later.</>
        )}
      </div>
    </Wrapper>
  );
}
