import { IService } from "_shared/models/Service";
import { intersection, localeSort } from "_shared/utils";
import { FC } from "react";
import Select from "react-select";
import { IOption } from "./Field";

const SHARED_TAGS = {
  parentBabyToddlerGroups: {
    value: "00",
    label: "Parent, Baby & Toddler Groups",
  },
  walkingGroups: { value: "01", label: "Walking groups" },
  communityProjects: { value: "02", label: "Community & Nature Projects" },
  housingCosts: { value: "03", label: "Housing Costs" },
  sleep: { value: "04", label: "Sleep Concerns" },
  befriending: { value: "05", label: "Befriending Services" },
};

const DEMOGRAPHICS_BY_CATEGORY = [
  {
    label: "Accessibility",
    options: [{ value: "atHome", label: "At-home" }],
  },
  {
    label: "Age",
    options: [{ value: "youth", label: "Youth" }], // Deprecated in favor of multiple "Young Persons X" tags
  },
  {
    label: "Additional Needs",
    options: [
      {
        value: "HF",
        label: "Additional Needs Social Interaction",
      },
      {
        value: "ON",
        label: "Additional Needs Education, Employment, & Training",
      },
      {
        value: "Qt",
        label: "Additional Needs Health & Wellbeing",
      },
      {
        value: "TX",
        label: "Additional Needs Finance & Housing",
      },
      {
        value: "gz",
        label: "Additional Needs Carers & Parenting Support",
      },
      {
        value: "gq",
        label: "Young Persons Additional Needs",
      },
    ],
  },
  {
    label: "Bereavement",
    options: [
      { value: "Ok", label: "Group & Peer Support" },
      { value: "h4", label: "Helplines & Bereavement Counselling" },
      { value: "gf", label: "Bereavement Advice" },
      { value: "gg", label: "Young Persons Bereavement" },
    ],
  },
  {
    label: "Carers & parents",
    options: [
      { value: "Q1", label: "Advice & Support Groups for Carers" },
      { value: "pR", label: "Respite & Care Packages" },
      { value: "cZ", label: "Parenting Support" },
      SHARED_TAGS.parentBabyToddlerGroups,
      { value: "cA", label: "Young Carers" },
    ],
  },
  {
    label: "Elderly & Disabled",
    options: [
      { value: "p0", label: "IT Support" },
      { value: "e-", label: "Volunteer taxi services" },
      { value: "3S", label: "Disability Support" },
      { value: "3l", label: "Home Maintenance" },
      SHARED_TAGS.befriending,
    ],
  },
  {
    label: "Employment & Training",
    options: [
      { value: "zS", label: "Support whilst in Employment" },
      { value: "28", label: "Unemployment" },
      { value: "io", label: "Training" },
      { value: "BG", label: "Volunteering" },
      { value: "BH", label: "Young Persons Employment & Education" },
    ],
  },
  {
    label: "Getting Active",
    options: [
      SHARED_TAGS.walkingGroups,
      { value: "eB", label: "Gyms & GP referral schemes" },
      { value: "0C", label: "Gentle Exercise Classes" },
      { value: "Qk", label: "Fitness Classes" },
      { value: "89", label: "Online/Home workouts" },
      { value: "QS", label: "Swimming" },
      { value: "gM", label: "Sports" },
      SHARED_TAGS.communityProjects,
      { value: "gN", label: "Young Persons Getting Active" },
    ],
  },
  {
    label: "Housing & Home Environment",
    options: [
      { value: "Po", label: "Homelessness" },
      { value: "TK", label: "Housing Advice & Guidance" },
      SHARED_TAGS.housingCosts,
      { value: "lT", label: "Supporting Independent Living" },
    ],
  },
  {
    label: "Medical Conditions",
    options: [
      { value: "sI", label: "Diabetes" },
      { value: "En", label: "COPD" },
      { value: "ZO", label: "Stroke" },
      { value: "an", label: "Dementia" },
      { value: "u3", label: "Parkinsons" },
      { value: "BX", label: "Fibromyalgia" },
      { value: "Yu", label: "ME" },
      { value: "Ao", label: "Menopause" },
      { value: "Wq", label: "Cancer" },
      { value: "4E", label: "IBS" },
      { value: "qW", label: "Cardiovascular" },
      { value: "Xo", label: "Multiple Sclerosis" },
      { value: "re", label: "Arthritis" },
      { value: "yH", label: "Chronic Pain" },
      SHARED_TAGS.sleep,
    ],
  },
  {
    label: "Mental Health",
    options: [
      { value: "5N", label: "Counselling" },
      { value: "sd", label: "Mental Health Support Groups" },
      { value: "Qc", label: "Mental Health Programmes" },
      { value: "9G", label: "Mental Health Resources" },
      { value: "Hy", label: "Mental Health Crisis Support" },
      { value: "Zr", label: "Victim Support" },
      { value: "8i", label: "Addiction Support" },
      { value: "o0", label: "Eating Disorder" },
      SHARED_TAGS.sleep,
      { value: "o1", label: "Young Persons Mental Health" },
    ],
  },
  {
    label: "Money & Welfare",
    options: [
      { value: "Yx", label: "Debt Services" },
      { value: "wl", label: "Benefits Advice & Guidance" },
      SHARED_TAGS.housingCosts,
      { value: "-3", label: "Food Banks & Crisis Support" },
      { value: "fu", label: "Cost of Living" },
    ],
  },
  {
    label: "Social Interaction",
    options: [
      { value: "cC", label: "Craft Groups" },
      { value: "J4", label: "Recreational Activities" },
      { value: "jU", label: "Social Groups" },
      SHARED_TAGS.parentBabyToddlerGroups,
      SHARED_TAGS.befriending,
      SHARED_TAGS.communityProjects,
      SHARED_TAGS.walkingGroups,
      { value: "jV", label: "Youth Groups & Young Persons Activities" },
    ],
  },
  {
    label: "Veterans",
    options: [
      { value: "5m", label: "Veterans Mental Health Support" },
      { value: "TQ", label: "Veterans Financial Support" },
      { value: "QC", label: "Veterans Community & Social Support" },
    ],
  },
  {
    label: "Weight Loss & Lifestyle",
    options: [
      { value: "XN", label: "Weigh In & support groups" },
      { value: "85", label: "Weight Loss Apps & Tools" },
      { value: "h0", label: "Healthy Eating advice & recipes" },
    ],
  },
];

for (const category of DEMOGRAPHICS_BY_CATEGORY) {
  category.options.sort((a, b) => localeSort(a.label, b.label));
}

const DEMOGRAPHICS_FILTERS = new Set<string>();
for (const category of DEMOGRAPHICS_BY_CATEGORY) {
  for (const demographic of category.options) {
    DEMOGRAPHICS_FILTERS.add(demographic.value);
  }
}

export const useDemographicsServiceFilters = (): [
  options: ReadonlyArray<OptionGroup>,
  match: (service: IService, selectedFilters: Set<string>) => boolean
] => {
  return [
    DEMOGRAPHICS_BY_CATEGORY,
    (service, selectedFilters) => {
      const selectedDemographicsFilters = intersection(
        selectedFilters,
        DEMOGRAPHICS_FILTERS
      );
      const tags = service.tags ?? new Set();
      return Array.from(selectedDemographicsFilters).every((tag) =>
        tags.has(tag)
      );
    },
  ];
};

const DRAFT_FILTERS = ["private", "hidden", "approved"] as const;

export const useDraftServiceFilters = (): [
  options: ReadonlyArray<IOption>,
  match: (service: IService, selectedFilters: Set<string>) => boolean
] => {
  return [
    [
      { value: "private", label: "Private" },
      { value: "hidden", label: "Draft" },
      { value: "approved", label: "Approved" },
    ],
    (service, selectedFilters) => {
      for (const filter of DRAFT_FILTERS) {
        if (selectedFilters.has("hidden") && selectedFilters.has("approved"))
          return false;
        if (selectedFilters.has(filter)) {
          switch (filter) {
            case "private":
              return service.tags?.has("private") === true;
            case "hidden":
              return (
                service.isHidden === true || service.isHidden === undefined
              );
            case "approved":
              return service.isHidden === false;
          }
        }
      }
      return true;
    },
  ];
};

const PRICING_FILTERS = ["free"] as const;

export const usePricingServiceFilters = (): [
  options: ReadonlyArray<IOption>,
  match: (service: IService, selectedFilters: Set<string>) => boolean
] => {
  return [
    [{ value: "free", label: "Free" }],
    (service, selectedFilters) => {
      for (const filter of PRICING_FILTERS) {
        if (selectedFilters.has(filter)) {
          switch (filter) {
            case "free":
              return !service.pricing;
          }
        }
      }
      return true;
    },
  ];
};

export interface OptionGroup {
  label: string;
  options: ReadonlyArray<IOption>;
}

interface TagSelectProps {
  onChange: (value: Set<string>) => void;
  value?: Set<string>;
  optionGroups?: ReadonlyArray<OptionGroup>;
  "aria-labelledby"?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
}

export const TagSelect: FC<TagSelectProps> = ({
  value = new Set(),
  onChange,
  optionGroups = [],
  isMulti = true,
  ...otherProps
}) => {
  const onSelectionChanged = (
    selection: ReadonlyArray<IOption> | IOption | null
  ) => {
    if (selection instanceof Array) {
      onChange(new Set((selection ?? []).map((option) => option.value)));
    } else if (selection === null) {
      onChange(new Set());
    } else {
      onChange(new Set([selection.value]));
    }
  };

  const allOptions: Record<string, string> = {};
  for (const group of optionGroups) {
    for (const option of group.options) {
      const { value, label } = option;
      allOptions[value] = label;
    }
  }
  const selectedOptions: Array<IOption> = [];
  value.forEach((tagId) => {
    selectedOptions.push({
      value: tagId,
      label: allOptions[tagId] ?? `deleted: ${tagId}`,
    });
  });
  return (
    <Select
      options={optionGroups}
      value={selectedOptions}
      onChange={onSelectionChanged}
      menuPlacement="auto"
      isMulti={isMulti}
      isClearable={!isMulti}
      {...otherProps}
    />
  );
};

export default TagSelect;
