import { forwardRef, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "_shared/css/Icons.css";
import { faClock, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { SocialNetwork } from "_shared/models/Service";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";

interface IconProps {
  color: "interactive" | "danger";
  children: ReactNode;
}

//forwardRef is necessary to use within a <Tippy> — https://github.com/atomiks/tippyjs-react#component-children
const Icon = forwardRef<HTMLElement, IconProps>(({ color, children }, ref) => {
  const classes = ["icon"];
  classes.push(`icon--${color}`);
  return (
    <span className={classes.join(" ")} ref={ref}>
      {children}
    </span>
  );
});

export const InfoIcon = forwardRef<HTMLElement>((_props, ref) => (
  <Icon color="interactive" ref={ref}>
    <FontAwesomeIcon icon={faInfoCircle} />
  </Icon>
));

export const DueIcon = forwardRef<HTMLElement>((_props, ref) => (
  <Icon color="danger" ref={ref}>
    <FontAwesomeIcon icon={faClock} />
  </Icon>
));

export const ICONS_BY_NETWORK: Record<SocialNetwork, IconDefinition> = {
  facebook: faFacebook,
  instagram: faInstagram,
  linkedin: faLinkedin,
  twitter: faTwitter,
  youtube: faYoutube,
};
