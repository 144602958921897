import { FC, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Input,
  Select,
  useDebouncedInputProps,
} from "_shared/components/Field";
import { ConfirmButton } from "_shared/components/Button";
import "_shared/css/Milestone.css";
import {
  faCheck,
  faMinusCircle,
  faPlus,
  faUsers,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { faSeedling, faTrashAlt } from "@fortawesome/pro-solid-svg-icons";
import { GOAL_MILESTONE_ID } from "_shared/models/Patient";

const MILESTONES: {
  [k in GOAL_MILESTONE_ID]: {
    icon: IconDefinition;
    label: string;
    isFirst?: boolean;
    isFinal?: boolean;
  };
} = {
  created: {
    icon: faSeedling,
    label: "Set",
    isFirst: true,
  },
  attended: {
    icon: faUsers,
    label: "Attended",
  },
  abandoned: {
    icon: faTrashAlt,
    label: "Abandoned",
    isFinal: true,
  },
  doneAt: {
    icon: faCheck,
    label: "Completed",
    isFinal: true,
  },
};

interface MilestoneProps {
  milestone: GOAL_MILESTONE_ID;
  date: string;
  index: number;
  count: number;
  onChange: (newDate: string | null) => void;
}

export const Milestone: FC<MilestoneProps> = ({
  milestone,
  date,
  index,
  count,
  onChange,
}) => {
  const { icon, label, isFinal, isFirst } = MILESTONES[milestone];
  const inputRef = useRef<HTMLInputElement | null>(null);
  const debouncedInputProps = useDebouncedInputProps(
    date,
    (e) => onChange(e.target.value),
    2500
  );

  // Validate order
  useEffect(() => {
    if (!inputRef.current) return;
    const message =
      isFirst && index !== 0
        ? `'${label}' must come first`
        : isFinal && index !== count - 1
        ? `'${label}' must come last`
        : "";
    inputRef.current.setCustomValidity(message);
  }, [count, index, isFinal, isFirst, label]);

  return (
    <div className="milestone" key={milestone}>
      <div className="milestone__icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <label>
        <small className="milestone__label">{label}</small>
        <Input
          ref={inputRef}
          type="date"
          className="milestone__date"
          isSilent
          required
          autoFocus={!date}
          max={new Date().toLocaleDateString("en-CA")}
          onKeyPress={(e) => {
            if (e.key === "Enter") e.currentTarget.blur();
          }}
          {...debouncedInputProps}
        />
      </label>
      {!isFirst && (
        <div>
          <ConfirmButton
            secondary
            kind="danger"
            onConfirm={() => onChange(null)}
            tooltip={(isConfirming) =>
              isConfirming ? `Really delete '${label}'?` : `Delete '${label}'`
            }
          >
            <FontAwesomeIcon icon={faMinusCircle} />
          </ConfirmButton>
        </div>
      )}
    </div>
  );
};

interface AddMilestoneProps {
  filter: (milestone: GOAL_MILESTONE_ID) => boolean;
  onCreate: (milestone: GOAL_MILESTONE_ID) => void;
}

export const AddMilestone: FC<AddMilestoneProps> = ({ filter, onCreate }) => {
  return (
    <div className="milestone milestone--add">
      <div className="milestone__icon">
        <FontAwesomeIcon icon={faPlus} />
      </div>
      <small className="milestone__label">New</small>
      <Select
        className="milestone__add"
        aria-label="Add milestone"
        onChange={(e) => {
          onCreate(e.target.value as GOAL_MILESTONE_ID);
        }}
        value=""
      >
        <option value="">Add...</option>
        {Object.entries(MILESTONES)
          .filter(([milestone]) => filter(milestone as GOAL_MILESTONE_ID))
          .map(([milestone, { label }]) => {
            return (
              <option key={milestone} value={milestone}>
                {label}
              </option>
            );
          })}
      </Select>
    </div>
  );
};
