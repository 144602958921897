import { Children, ComponentProps, FC, forwardRef, useState } from "react";
import Tippy from "@tippyjs/react";
import "_shared/css/Button.css";

interface ButtonProps extends ComponentProps<"button"> {
  secondary?: boolean;
  kind?: "danger";
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, kind, secondary = false, className, ...otherProps } = props;
  const classes = ["button"];
  if (kind) classes.push(`${classes[0]}--${kind}`);
  if (secondary) classes.push(`${classes[0]}--secondary`);
  if (className) classes.push(className);
  if (Children.count(children) === 1) classes.push(`${classes[0]}--icon`);
  return (
    <button
      type="button"
      className={classes.join(" ")}
      ref={ref}
      {...otherProps}
    >
      {children}
    </button>
  );
});

export default Button;

export const ButtonGroup: FC<ComponentProps<"div">> = ({
  children,
  className,
  ...otherProps
}) => {
  const classes = ["button-group"];
  if (className) classes.push(className);
  return (
    <div className={classes.join(" ")} {...otherProps}>
      {children}
    </div>
  );
};

interface ConfirmButtonProps extends Omit<ButtonProps, "ref"> {
  tooltip: (isConfirming: boolean) => string;
  onConfirm: () => void;
}

export const ConfirmButton: FC<ConfirmButtonProps> = ({
  tooltip,
  onConfirm,
  ...otherProps
}) => {
  const [isConfirming, setIsConfirming] = useState(false);

  const onClick = () => {
    if (isConfirming) {
      onConfirm();
    } else {
      setIsConfirming(true);
    }
  };
  const onBlur = () => setIsConfirming(false);

  return (
    <Tippy content={<span>{tooltip(isConfirming)}</span>} hideOnClick={false}>
      <Button
        onClick={onClick}
        onBlur={onBlur}
        aria-label={tooltip(isConfirming)}
        {...otherProps}
      />
    </Tippy>
  );
};
