import { FC } from "react";
import { Line } from "react-chartjs-2";
import { CHART_COLORS } from "_shared/chart";

interface WellbeingChartProps {
  statements: ReadonlyArray<string>;
  steps: ReadonlyArray<string>;
  responsesByDate: {
    [date: string]: ReadonlyArray<number>;
  };
}

export const WellbeingChart: FC<WellbeingChartProps> = ({
  statements,
  steps,
  responsesByDate,
}) => {
  return (
    <Line
      options={{
        scales: {
          x: {
            type: "time",
            time: {
              minUnit: "week",
              tooltipFormat: "Do MMMM yyyy",
            },
            grid: {
              color: "transparent",
            },
          },
          y: {
            min: 0,
            max: steps.length - 1,
            ticks: {
              precision: 0,
              callback: (value) => steps[parseInt(value as string)],
            },
          },
        },
        plugins: {
          legend: {
            position: "top",
            align: "start",
          },
        },
      }}
      data={{
        datasets: statements.map((statement, i) => {
          const color = CHART_COLORS[i % CHART_COLORS.length];
          return {
            label: statement,
            borderColor: color,
            pointBackgroundColor: color,
            backgroundColor: "transparent",
            data: Object.entries(responsesByDate).map(([date, responses]) => {
              return {
                x: new Date(date),
                y: responses[i],
              };
            }),
          };
        }),
      }}
    />
  );
};
