import { FC, ReactNode, createContext, useContext } from "react";

interface FeatureFlagsContextValue {
  isEducationMode: boolean;
  isMycawEnabled: boolean;
  isServicePublishingOn: boolean;
  isPatientCountUnlimited: boolean;
  isPatientArchiveOn: boolean;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextValue | undefined>(
  undefined
);

export const useFeatureFlags = () => {
  const context = useContext(FeatureFlagsContext);
  if (context === undefined)
    throw new Error(
      `useFeatureFlags could not find a <FeatureFlagsProvider> ancestor higher in the tree.`
    );
  return context;
};

export const useFeatureFlagsOrUndefined = () => {
  return useContext(FeatureFlagsContext);
};

interface FeatureFlagsProviderProps {
  children?: ReactNode;
  groupId: string;
}

export const FeatureFlagsProvider: FC<FeatureFlagsProviderProps> = ({
  children,
  groupId,
}) => {
  return (
    <FeatureFlagsContext.Provider
      value={{
        // Colleges, or other educational institutions
        isEducationMode: new Set([
          "66TufAVCJLzKnbpKLraS", // Eastern Colleges Group
          "VQnq1EU2HeFzBzdzM8q4", // Demo 2
          "Df2PZi36MVFyW89E8LaT", // Callywith College
        ]).has(groupId),
        isMycawEnabled: true,
        isServicePublishingOn: true,
        isPatientCountUnlimited: new Set([
          "2otdIN1MrK4G8dN7qNjd", // St Austell
          "mQAY932TNj63gm5b7P72", // Demo 1
          "VQnq1EU2HeFzBzdzM8q4", // Demo 2
          "7cJm8hIE2P2f6bIftoEH", // Culm Valley
          "k6PEI2IEczt36sHz5cQy", // Coastal Group
          "dq8ZTsjXjfP8KhO6QVrq", // Three Harbours
          "SJWAuXKvlKjM2ZG8A6Dn", // Bodmin Surgeries
          "38Q9r22VJFuMiZNrrI7N", // Arbennek
          "Df2PZi36MVFyW89E8LaT", // Callywith College
        ]).has(groupId),
        isPatientArchiveOn: true,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};
