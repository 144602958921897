import { FirebaseError, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { deleteField, getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { mergeWith } from "lodash";

export const app = initializeApp({
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: "hand-community.firebaseapp.com",
  projectId: "hand-community",
  storageBucket: "hand-community.appspot.com",
  messagingSenderId: "369023364422",
  appId: "1:369023364422:web:89bbf9f91e1fbe20014947",
});

export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, "europe-west2");
export const storage = getStorage(app);

export const isFirebaseError = (e: unknown): e is FirebaseError => {
  if ("code" in (e as FirebaseError)) return true;
  return false;
};

/**
 * Recursively maps types to ones which Firestore understands.
 */
export const convertToFirestore = (
  value: unknown,
  onNull?: (path: string) => void,
  pathComponents: Array<string> = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (value === null) {
    // Notify caller of null fields
    if (onNull) {
      let path =
        pathComponents.length === 0
          ? ""
          : pathComponents.length === 1
          ? pathComponents[0]
          : pathComponents[0] +
            pathComponents
              .slice(1)
              .map((key) => `[${key}]`)
              .join("");
      onNull(path);
    }
    // An edit which sets a field to null => delete that field
    return deleteField();
  }
  if (value instanceof Date) return value;
  if (value instanceof Set) return Array.from(value);
  if (Array.isArray(value)) {
    return value.map((element, i) =>
      convertToFirestore(element, onNull, [...pathComponents, i.toString()])
    );
  }
  if (typeof value === "object") {
    return mergeWith({}, value, (_oldValue, newValue, key) => {
      return convertToFirestore(newValue, onNull, [...pathComponents, key]);
    });
  }
  return value;
};
