import {
  faMobileSignal as falMobileSignal,
  faQrcode as falQrcode,
} from "@fortawesome/pro-light-svg-icons";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { toCanvas } from "qrcode";
import { useUrl } from "_shared/hooks";
import Button from "_shared/components/Button";
import { LocationPreview, useLocationsQuery } from "../Group.hooks";
import SendNotification from "./SendNotification";
import Tab, { TabBar, TabIcon } from "_shared/components/Tab";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import Copyable from "_shared/components/Copyable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownToLine } from "@fortawesome/pro-solid-svg-icons";
import { useDynamicLinks } from "_shared/components/DynamicLinks";

export const Promotion: FC = () => {
  const url = useUrl();
  const { path } = useRouteMatch();
  const { groupId } = useParams<{ groupId: string }>();
  const locations = useLocationsQuery(groupId);
  return (
    <>
      <header className="flex flex-col gap-2 py-8">
        <Wrapper pad>
          <Text variant="h1">Promotion</Text>
        </Wrapper>
        <TabBar>
          <Tab to={`${url}/links`}>
            <TabIcon icon={falQrcode} /> Links
          </Tab>
          <Tab to={`${url}/notifications`}>
            <TabIcon icon={falMobileSignal} /> Notifications
          </Tab>
        </TabBar>
      </header>
      <Wrapper pad className="pb-8">
        <Switch>
          <Route path={path} exact>
            <Redirect to={`${url}/links`} />
          </Route>
          <Route path={`${path}/links`}>
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-1">
                <Text variant="h2">Links</Text>
                <p>Share the links below with your community.</p>
                <p>
                  Each link goes directly to one of your locations on the Help
                  at Hand app.
                </p>
              </div>
              <div>
                {locations?.map((location) => {
                  return (
                    <LocationLinks key={location.id} location={location} />
                  );
                })}
              </div>
            </div>
          </Route>
          <Route path={`${path}/notifications`}>
            <div className="flex flex-col gap-2">
              <Text variant="h2">Notifications</Text>
              <p>
                Send a notification to subscribed app users across all locations
              </p>
              <SendNotification />
            </div>
          </Route>
        </Switch>
      </Wrapper>
    </>
  );
};

interface LocationLinksProps {
  location: LocationPreview;
}

const LocationLinks: FC<LocationLinksProps> = ({ location }) => {
  const { groupId } = useParams<{ groupId: string }>();
  const { buildLink } = useDynamicLinks();
  const [link, setLink] = useState<string>();

  useEffect(() => {
    const generateLink = async () => {
      const link = await buildLink(
        `/groups/${groupId}/locations/${location.id}?utm_source=clinical.hand.community&utm_campaign=${groupId}`
      );
      setLink(link);
    };
    generateLink();
  }, [buildLink, groupId, location.id, setLink]);

  if (!link) return null;

  return (
    <div className="flex justify-between gap-8 rounded-sm py-8 px-2 hover:bg-gray-96">
      <div className="flex flex-col gap-4 self-start">
        <div className="flex flex-col gap-1">
          <Text variant="h3">{location.name}</Text>
          <Text variant="smallLight">{location.address}</Text>
        </div>
        <Copyable
          tooltip={(isCopied) => (isCopied ? "Link copied" : "Copy link")}
        >
          {link}
        </Copyable>
      </div>
      <div className="self-end">
        <QrCode content={link} />
      </div>
    </div>
  );
};

interface QrCodeProps {
  content: string;
}

const QrCode: FC<QrCodeProps> = ({ content }) => {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const ref = useCallback(
    (node) => {
      canvas.current = node;
      if (!node) return;
      toCanvas(node, content, { color: { light: "#0000" }, margin: 0 });
    },
    [content]
  );

  const handleDownload = () => {
    if (!canvas.current) return;
    const a = document.createElement("a");
    a.download = `${content}.png`;
    a.href = canvas.current.toDataURL("image/png");
    a.click();
  };

  return (
    <div className="flex flex-col items-center gap-1">
      <canvas ref={ref} className="max-h-[64px] max-w-[64px] object-contain" />
      <Button secondary onClick={handleDownload}>
        <FontAwesomeIcon icon={faArrowDownToLine} /> Download
      </Button>
    </div>
  );
};
