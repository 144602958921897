import { faChevronLeft, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "_shared/components/Button";
import { DownloadLink } from "_shared/components/DownloadLink";
import Text from "_shared/components/Text";
import Wrapper from "_shared/components/Wrapper";
import { Report } from "_shared/models/_meta";
import { Link } from "react-router-dom";

interface Props {
  report: Report;
}

export function AllPatientsReport(props: Props) {
  const { report } = props;
  const { patientCsvUrl } = report;
  return (
    <Wrapper wide pad>
      <div className="flex flex-col gap-6 py-6">
        <div className="flex flex-col gap-2">
          <Link className="self-start" to=".">
            <Button secondary>
              <FontAwesomeIcon icon={faChevronLeft} /> Reports
            </Button>
          </Link>
          <Text variant="h1">All Patients</Text>
        </div>
        {patientCsvUrl ? (
          <DownloadLink
            filename="All Patients.csv"
            getDataUrl={() => patientCsvUrl}
          >
            <Button>
              <FontAwesomeIcon icon={faFileCsv} />
              Download spreadsheet
            </Button>
          </DownloadLink>
        ) : (
          <>Awaiting Patients data for last month, check again later.</>
        )}
      </div>
    </Wrapper>
  );
}
