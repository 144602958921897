import { FC, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendNotification } from "_shared/api";
import Button from "_shared/components/Button";
import { Field, TextField } from "_shared/components/Field";
import { GroupContext } from "../Group";
import {
  faCheck,
  faPaperPlane,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";

type STATUS = "draft" | "sending" | "sent";

const SendNotification = () => {
  //TODO: get groupId from context
  const { groupId } = useParams<{ groupId: string }>();
  const group = useContext(GroupContext);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [status, setStatus] = useState<STATUS>("draft");

  const send = async () => {
    if (
      !window.confirm(
        `You are about to send this notification to all ${group?.name} app users:\n\n${title}\n${body}`
      )
    )
      return;
    setStatus("sending");
    await sendNotification({ title, body, groupId });
    setStatus("sent");
  };

  return (
    <div>
      <TextField
        label="Title"
        maxLength={40}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={status !== "draft"}
      />
      <TextField
        label="Body"
        maxLength={120}
        value={body}
        onChange={(e) => setBody(e.target.value)}
        disabled={status !== "draft"}
      />
      <Field>
        <Button
          secondary
          onClick={send}
          disabled={!title || !body || status !== "draft"}
        >
          <ButtonMessage status={status} />
        </Button>
      </Field>
    </div>
  );
};
export default SendNotification;

interface ButtonMessageProps {
  status: STATUS;
}

const ButtonMessage: FC<ButtonMessageProps> = ({ status }) => {
  switch (status) {
    case "draft":
      return (
        <>
          <FontAwesomeIcon icon={faPaperPlane} /> Send Notification
        </>
      );
    case "sending":
      return (
        <>
          <FontAwesomeIcon icon={faSpinner} spin /> Sending...
        </>
      );
    case "sent":
      return (
        <>
          <FontAwesomeIcon icon={faCheck} /> Sent
        </>
      );
    default:
      return null;
  }
};
