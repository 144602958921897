import { nanoid } from "nanoid";
import { useEffect, useMemo, useRef, useState } from "react";
import { useRouteMatch } from "react-router-dom";

const trimEnd = (string: string, character: string): string => {
  let output = string;
  while (output.length > 0 && output[output.length - 1] === character) {
    output = output.slice(0, -1);
  }
  return output;
};

//React Router URLs sometimes have a trailling slash: https://github.com/ReactTraining/react-router/issues/4841
export const useUrl = (): string => {
  const match = useRouteMatch();
  return trimEnd(match.url, "/");
};

export const usePrinter = () => {
  const [isPrinting, setIsPrinting] = useState(false);
  const printable = useRef(document.createElement("div"));

  // Trigger print when isPrinting is set to true
  useEffect(() => {
    if (isPrinting) window.print();
  }, [isPrinting]);

  // Reset isPrinting to false when print dialog closes
  useEffect(() => {
    const afterPrint = () => {
      // Show the rest of the app
      document.getElementById("root")!.style.removeProperty("display");
      setIsPrinting(false);
    };
    window.addEventListener("afterprint", afterPrint);
    return () => window.removeEventListener("afterprint", afterPrint);
  }, [setIsPrinting]);

  // Add/remove printable element for portal on mount/unmount
  useEffect(() => {
    const element = printable.current;
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, []);

  const print = () => {
    // Hide the rest of the app
    document.getElementById("root")!.style.display = "none";
    setIsPrinting(true);
  };

  return {
    isPrinting,
    print,
    // Fill this element with components to print and render it in a portal when `isPrinting === true`
    printable: printable.current,
  };
};

export const useId = (): string => {
  return useMemo(() => nanoid(), []);
};

export const useOnUnmount = (onUnmount: () => void) => {
  useEffect(() => {
    if (!onUnmount) return;
    return () => {
      onUnmount();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useDayFormatter = (): Intl.DateTimeFormat => {
  return new Intl.DateTimeFormat("en-GB", {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  });
};

export const useCache = <T>(maxEntries: number) => {
  const cache = useRef<Record<string, T>>({});
  return useMemo(() => {
    return {
      get: (key: string): T | undefined => cache.current[key],
      set: (key: string, value: T) => {
        // Delete and re-insert so that recently edited entries stay at the end
        delete cache.current[key];
        cache.current[key] = value;
        // Keep cache entries below max
        let keyCount = Object.keys(cache.current).length;
        // In modern JS engines, object iteration order matches insertion order
        for (const key in cache.current) {
          if (keyCount <= maxEntries) break;
          delete cache.current[key];
          keyCount--;
        }
      },
    };
  }, [maxEntries]);
};
