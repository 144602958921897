import { createContext, FC, useContext, useMemo } from "react";
import { app } from "_shared/firebase";
import { useCache } from "_shared/hooks";

type Value = { buildLink: (path: string) => Promise<string> } | undefined;

const DynamicLinksContext = createContext<Value>(undefined);

export const useDynamicLinks = () => {
  const context = useContext(DynamicLinksContext);
  if (context === undefined)
    throw new Error(
      `useDynamicLinks could not find a <DynamicLinks> ancestor higher in the tree.`
    );
  return context;
};

export const DynamicLinksProvider: FC = ({ children }) => {
  const cache = useCache<string>(100);
  const value: Value = useMemo(() => {
    return {
      buildLink: async (path: string) => {
        if (cache.get(path)) return cache.get(path);
        const key = app.options.apiKey;
        const response = await fetch(
          `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${key}`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              dynamicLinkInfo: {
                domainUriPrefix: "https://link.hand.community",
                link: new URL(path, "https://app.hand.community").toString(),
                androidInfo: {
                  androidPackageName: "community.hand.help",
                },
                iosInfo: {
                  iosBundleId: "global.patientcards.client",
                },
              },
              suffix: {
                option: "SHORT",
              },
            }),
          }
        );
        const { shortLink } = await response.json();
        cache.set(path, shortLink);
        return shortLink;
      },
    };
  }, [cache]);
  return (
    <DynamicLinksContext.Provider value={value}>
      {children}
    </DynamicLinksContext.Provider>
  );
};
