import {
  ChangeEvent,
  ComponentProps,
  FC,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AddressSearchField } from "_shared/components/AddressSearchInput";
import {
  TextField,
  TextareaField,
  Field,
  Label,
  Select,
  optionsFromMap,
} from "_shared/components/Field";
import TagSelect, {
  useDemographicsServiceFilters,
} from "_shared/components/TagSelect";
import Button from "_shared/components/Button";
import "_shared/css/Service.css";
import Tippy from "@tippyjs/react";
import { ICONS_BY_NETWORK, InfoIcon } from "_shared/components/Icons";
import { useId } from "_shared/hooks";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  IService,
  SocialNetwork,
  SOCIAL_NETWORKS,
} from "_shared/models/Service";
import { Nullable } from "_shared/utils";

interface EditServiceProps {
  service: IService;
  onChange: (
    service: Nullable<IService> & Pick<IService, "lastEdited">
  ) => void;
  onValidityChange: (isValid: boolean) => void;
  onDelete?: () => void;
}

const EditService: FC<EditServiceProps> = ({
  service,
  onChange,
  onDelete,
  onValidityChange,
}) => {
  const ref = useRef<HTMLFormElement | null>(null);
  const tagSelectId = useId();

  // Validate
  useEffect(() => {
    const isValid = ref.current?.checkValidity() === true;
    onValidityChange(isValid);
  });
  // Report isValid === true on unmount
  useEffect(() => {
    return () => onValidityChange(true);
  }, [onValidityChange]);

  const handleChanged = (update: Nullable<Partial<IService>>) => {
    onChange({
      ...update,
      lastEdited: new Date(),
    });
  };

  const {
    name = "",
    description = "",
    link = "",
    number = "",
    contactName = "",
    email = "",
    address = "",
    pricing = "",
    social = {},
    notes = "",
    tags = new Set(),
  } = service;
  return (
    <form ref={ref} className="service">
      <TextField
        label="Name"
        value={name}
        onChange={(e) => handleChanged({ name: e.target.value })}
        autoFocus={!name}
        maxLength={60}
        invalidHelp="Required"
        required
      />
      <Field wide>
        <Label id={tagSelectId} strong>
          Tags
          <Tippy
            content={
              <div className="flex flex-col gap-2">
                <p>
                  Use the "At-home" tag for services which do not require
                  travel.
                </p>
              </div>
            }
          >
            <InfoIcon />
          </Tippy>
        </Label>
        <ServiceTagSelect
          aria-labelledby={tagSelectId}
          value={tags}
          onChange={(value) => handleChanged({ tags: value })}
        />
      </Field>
      <TextareaField
        label="Description"
        rows={6}
        value={description}
        onChange={(e) => handleChanged({ description: e.target.value })}
      />
      <TextareaField
        label="Pricing"
        rows={2}
        placeholder="Free"
        value={pricing}
        onChange={(e) => handleChanged({ pricing: e.target.value })}
      />
      <AddressSearchField
        label="Address"
        value={address}
        onChange={({ address, lat, lng }) =>
          handleChanged({ address, lat, lng })
        }
      />
      <TextField
        label="Link"
        type="url"
        invalidHelp="Enter a valid URL, e.g. https://example.com"
        value={link}
        onChange={(e) => handleChanged({ link: e.target.value })}
      />
      <TextField
        label="Phone Number"
        type="tel"
        pattern="(\d+\s?)+\d"
        maxLength={20}
        invalidHelp="Enter one number, using digits 0-9"
        value={number}
        onChange={(e) => handleChanged({ number: e.target.value })}
      />
      <TextField
        label="Contact Name"
        pattern="\D+"
        maxLength={25}
        invalidHelp="Enter a person's name, e.g. Joe Bloggs"
        value={contactName}
        onChange={(e) => handleChanged({ contactName: e.target.value })}
      />
      <TextField
        label="Email"
        type="email"
        invalidHelp="Enter a valid email, e.g. jbloggs@example.com"
        value={email}
        onChange={(e) => handleChanged({ email: e.target.value })}
      />
      <Field wide>
        <Label strong>Social Links</Label>
        <Social
          value={social}
          onChange={(social) => handleChanged({ social })}
        />
      </Field>
      <TextareaField
        label={
          <>
            Notes & Referral Information
            <Tippy
              content={
                <span>Notes are private and not visible on the app</span>
              }
            >
              <InfoIcon />
            </Tippy>
          </>
        }
        rows={2}
        value={notes}
        onChange={(e) => handleChanged({ notes: e.target.value })}
      />
      {onDelete && (
        <Field>
          <Button secondary kind="danger" onClick={onDelete}>
            <FontAwesomeIcon icon={faMinusCircle} /> Delete service
          </Button>
        </Field>
      )}
    </form>
  );
};
export default EditService;

interface SocialProps {
  onChange: (value: Partial<Record<SocialNetwork, string>>) => void;
  value?: Partial<Record<SocialNetwork, string>>;
}

const Social: FC<SocialProps> = ({ value = {}, onChange }) => {
  const addedNetworks = new Set<SocialNetwork>(
    Object.keys(value).map((network) => network as SocialNetwork)
  );
  const onAdded = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const network = event.target.value as SocialNetwork;
      onChange({
        ...value,
        [network]: "",
      });
    },
    [onChange, value]
  );
  const onDelete = (network: SocialNetwork) => {
    const update = { ...value };
    delete update[network];
    onChange(update);
  };
  const addOptions = SOCIAL_NETWORKS.filter(
    (network) => !addedNetworks.has(network)
  ).map((network) => {
    return (
      <option key={network} value={network}>
        {upperCaseFirst(network)}
      </option>
    );
  });
  return (
    <div className="social">
      {Array.from(addedNetworks)
        .sort()
        .map((network) => {
          return (
            <div key={network} className="social__network">
              <FontAwesomeIcon
                icon={ICONS_BY_NETWORK[network]}
                className="social__icon"
              />
              <TextField
                type="url"
                aria-label={`${network} URL`}
                value={value[network] || ""}
                onChange={(e) =>
                  onChange({ ...value, [network]: e.target.value })
                }
                autoFocus={!value[network]}
                invalidHelp={`Enter a full URL: https://${network}.com/you`}
                required
              />
              <Tippy content={<span>Delete</span>}>
                <Button
                  aria-label={`Delete ${network}`}
                  secondary
                  kind="danger"
                  onClick={() => onDelete(network)}
                >
                  <FontAwesomeIcon icon={faMinusCircle} />
                </Button>
              </Tippy>
            </div>
          );
        })}
      {addOptions.length > 0 && (
        <>
          <Select
            aria-label="Add social link"
            className="social__add"
            value=""
            onChange={onAdded}
          >
            <option>Add...</option>
            {addOptions}
          </Select>
        </>
      )}
    </div>
  );
};

const ServiceTagSelect: FC<
  Omit<ComponentProps<typeof TagSelect>, "optionGroups">
> = (props) => {
  const [demographicsFilterOptionGroups] = useDemographicsServiceFilters();
  return (
    <TagSelect
      optionGroups={[
        {
          label: "Admin",
          options: optionsFromMap({
            private: "Private",
          }),
        },
        ...demographicsFilterOptionGroups,
      ]}
      {...props}
    />
  );
};

const upperCaseFirst = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
