import {
  ChangeEvent,
  createContext,
  FC,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  User as FirebaseUser,
} from "firebase/auth";
import { auth, isFirebaseError } from "_shared/firebase";
import { TextField } from "./Field";
import Button from "./Button";
import "_shared/css/Auth.css";
import Text from "./Text";
import { IS_STAGING } from "_shared/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Tippy from "@tippyjs/react";
import { InfoIcon } from "./Icons";
import Scrollable from "./Scrollable";

interface User extends FirebaseUser {
  readonly isAdmin: boolean;
}

// User will always be defined to components nested under <Auth>
export const UserContext = createContext<User>({} as User);

const Auth: FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined | null>();
  //Monitor user sign-in/out
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdTokenResult();
        setUser({ ...user, isAdmin: Boolean(token.claims.admin) });
      } else {
        setUser(null);
      }
    });
  }, []);
  if (user === undefined) return null;
  if (user === null) return <SignIn />;
  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
export default Auth;

export const getEmailForSignInLink = () =>
  window.localStorage.getItem("emailForSignInLink");
export const setEmailForSignInLink = (email: string) =>
  window.localStorage.setItem("emailForSignInLink", email);
export const deleteEmailForSignInLink = () =>
  window.localStorage.removeItem("emailForSignInLink");

const SignIn = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(IS_STAGING);
  const [isPasswordForgotten, setIsPasswordForgotten] =
    useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    if (!formRef.current) {
      setIsValid(false);
    } else {
      setIsValid(formRef.current.checkValidity());
    }
  }, [email, password, formRef, setIsValid]);

  const signIn = async () => {
    setIsLoading(true);
    if (isPasswordVisible) {
      setErrorMessage("");
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (e) {
        if (isFirebaseError(e) && e.code === "auth/user-not-found") {
          setErrorMessage("We can't find an account with that email address");
        } else if (isFirebaseError(e) && e.code === "auth/wrong-password") {
          setErrorMessage("That password is incorrect");
        } else {
          console.error(e);
          setErrorMessage("Something went wrong, please try again");
        }
      }
    } else {
      try {
        await sendSignInLinkToEmail(auth, email, {
          url: window.location.href.replace("http://", "https://"), //Force HTTPS so that it works on localhost
          handleCodeInApp: true,
        });
        setEmailForSignInLink(email);
        setIsEmailSent(true);
      } catch (e) {
        console.error(e);
        setErrorMessage("Something went wrong, please try again");
      }
    }
    setIsLoading(false);
  };
  const sendPasswordReset = async () => {
    setErrorMessage("");
    try {
      await sendPasswordResetEmail(auth, email);
      setIsEmailSent(true);
    } catch (e) {
      if (isFirebaseError(e) && e.code === "auth/user-not-found") {
        setErrorMessage("We can't find an account with that email");
      } else {
        setErrorMessage("Something went wrong, please try again");
      }
    }
  };
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsEmailSent(false);
  };
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
    setIsEmailSent(false);
  };

  return (
    <Scrollable>
      <div className="login">
        <img
          className="login__logo"
          src={`${process.env.PUBLIC_URL}/assets/logo.svg`}
          alt="Help @ Hand Logo"
        />
        <Text variant="h1" className="login__title">
          {isPasswordForgotten ? "Reset password" : "Sign in"}
        </Text>
        <form
          ref={formRef}
          className="login__form"
          onKeyUp={(e) => {
            if (isValid && e.key === "Enter") {
              if (isPasswordForgotten) {
                sendPasswordReset();
              } else {
                signIn();
              }
            }
          }}
        >
          {errorMessage && <Text variant="smallDanger">{errorMessage}</Text>}
          <TextField
            type="email"
            label="Email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          {isPasswordForgotten ? (
            <>
              {isEmailSent && (
                <Text variant="smallLight">We sent a link to your inbox</Text>
              )}
              <Button
                onClick={sendPasswordReset}
                disabled={!isValid || isEmailSent}
              >
                {isEmailSent ? (
                  <>
                    <FontAwesomeIcon icon={faCheck} /> Email sent
                  </>
                ) : (
                  "Send email"
                )}
              </Button>
              {!isEmailSent && (
                <Button secondary onClick={() => setIsPasswordForgotten(false)}>
                  Cancel
                </Button>
              )}
            </>
          ) : (
            <>
              <div
                className={`login__password ${
                  isPasswordVisible ? "login__password--visible" : ""
                }`}
              >
                <TextField
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onInput={() => setIsPasswordVisible(true)}
                  required={isPasswordVisible}
                />
                <Text variant="smallLight">
                  <Text
                    variant="link"
                    onClick={() => setIsPasswordForgotten(true)}
                  >
                    Forgot password?
                  </Text>
                </Text>
              </div>
              {isEmailSent && (
                <>
                  <div>
                    <Text variant="smallLight">
                      We sent a link to your inbox&nbsp;
                    </Text>
                    <Tippy
                      content={
                        <>
                          To sign in, click the link in the email.
                          <br />
                          It can take a few minutes to arrive.
                        </>
                      }
                    >
                      <InfoIcon />
                    </Tippy>
                  </div>
                </>
              )}
              <Button
                onClick={signIn}
                disabled={!isValid || isLoading || isEmailSent}
              >
                {isEmailSent ? (
                  <>
                    <FontAwesomeIcon icon={faCheck} /> Email sent
                  </>
                ) : isPasswordVisible ? (
                  "Sign in"
                ) : (
                  "Send email"
                )}
              </Button>
              {isPasswordVisible ? (
                <Text variant="smallLight">
                  You can also{" "}
                  <Text variant="link" onClick={togglePasswordVisibility}>
                    use your email
                  </Text>
                </Text>
              ) : (
                <Text variant="smallLight">
                  You can also{" "}
                  <Text variant="link" onClick={togglePasswordVisibility}>
                    use your password
                  </Text>
                </Text>
              )}
            </>
          )}
        </form>
      </div>
    </Scrollable>
  );
};
