import { FC, ReactNode, useContext } from "react";
import { GroupContext } from "../../groups/Group";
import { Field, FieldProps, Label, SearchableSelect } from "./Field";
import { useId } from "_shared/hooks";

interface UserFieldProps extends Omit<FieldProps, "onChange"> {
  onChange: (uid: string | null) => void;
  uid?: string;
  label?: ReactNode;
  placeholder?: string;
}

const UserField: FC<UserFieldProps> = ({
  uid,
  onChange,
  label = "Assignee",
  placeholder = "All users",
  ...fieldProps
}) => {
  const group = useContext(GroupContext);
  const id = useId();
  const userProfiles = group?._userProfiles || {};
  return (
    <Field {...fieldProps}>
      <Label strong id={id}>
        {label}
      </Label>
      <SearchableSelect
        aria-labelledby={id}
        options={Object.entries(userProfiles)
          .map(([uid, profile]) => {
            return { value: uid, label: profile.email };
          })
          .sort((a, b) => a.label.localeCompare(b.label))}
        value={
          uid && uid in userProfiles
            ? { value: uid, label: userProfiles[uid].email }
            : null
        }
        onChange={(option) => onChange(option?.value || null)}
        placeholder={placeholder}
        menuPlacement="auto"
        isClearable
      />
    </Field>
  );
};

export default UserField;
