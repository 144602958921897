import { FC } from "react";
import Text from "_shared/components/Text";
import { Nullable } from "_shared/utils";
import { IPatientEvent } from "_shared/models/Patient";
import { optionsFromArray, RadioField } from "_shared/components/Field";
import Tippy from "@tippyjs/react";
import { InfoIcon } from "_shared/components/Icons";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";

interface EmploymentQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
}

export const EmploymentQuestionnaire: FC<EmploymentQuestionnaireProps> = ({
  event,
  onChange,
}) => {
  const { employment, housing, livesWith } = event;

  return (
    <>
      <hr />
      <Text variant="h2" id="employment-housing">
        Employment/Housing
      </Text>
      <RadioField
        label="Employment status"
        value={employment}
        onChange={(value) => onChange({ employment: value })}
        options={[
          { value: "Employed", label: "Employed" },
          { value: "Retired", label: "Retired" },
          { value: "Student", label: "Student" },
          {
            value: "Unable to work",
            label: (
              <>
                Unable to work
                <Tippy
                  content={
                    <span>
                      Cannot find paid work due to, for instance, a long-term
                      sickness or disability
                    </span>
                  }
                >
                  <InfoIcon />
                </Tippy>
              </>
            ),
          },
          {
            value: "Not looking",
            label: (
              <>
                Not looking
                <Tippy content={<span>e.g. stay-at-home partner</span>}>
                  <InfoIcon />
                </Tippy>
              </>
            ),
          },
          { value: "Unemployed", label: "Unemployed" },
          { value: "Volunteer", label: "Volunteer" },
          { value: "Other", label: "Other" },
        ]}
      />
      <RadioField
        label="Housing"
        value={housing}
        onChange={(value) => onChange({ housing: value })}
        options={optionsFromArray([
          "Lives in own home",
          "Lives in rented accommodation (private housing)",
          "Lives in rented accommodation (housing association)",
          "Lives in a nursing home",
          "Lives in shared accommodation",
          "Lives in sheltered housing",
          "Homeless",
          "Placed in temporary accommodation by local authority",
          "Staying with friends/family as a short term guest",
        ])}
      />
      <RadioField
        label="Living"
        value={livesWith}
        onChange={(value) => onChange({ livesWith: value })}
        options={[
          { value: "Alone", label: "Alone" },
          {
            value: "With dependent",
            label: (
              <>
                With dependent
                <Tippy
                  content={
                    <span>
                      <LocalizedMessage id="patients_events_dependent" />
                    </span>
                  }
                >
                  <InfoIcon />
                </Tippy>
              </>
            ),
          },
          { value: "With family", label: "With family" },
          { value: "With housemates", label: "With housemates" },
          { value: "With spouse/partner", label: "With spouse/partner" },
        ]}
      />
    </>
  );
};
