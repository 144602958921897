import { FC } from "react";
import Text from "_shared/components/Text";
import { Nullable } from "_shared/utils";
import { IPatientEvent } from "_shared/models/Patient";
import {
  Field,
  optionsFromArray,
  RadioField,
  TextareaField,
  TextField,
} from "_shared/components/Field";
import { LocalizedMessage } from "_shared/localization/LocalizedMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSmile, faFaceFrown } from "@fortawesome/pro-light-svg-icons";

interface MycawQuestionnaireProps {
  event: IPatientEvent;
  onChange: (update: Nullable<Partial<IPatientEvent>>) => void;
  firstMycawEvent?: IPatientEvent;
}

export const MycawQuestionnaire: FC<MycawQuestionnaireProps> = ({
  event,
  onChange,
  firstMycawEvent,
}) => {
  const { date, mycaw } = event;

  const showMycawFollowUp = firstMycawEvent
    ? event.date > firstMycawEvent.date
    : false;
  const mycawConcerns = [
    firstMycawEvent?.mycaw?.concern1 ?? "",
    firstMycawEvent?.mycaw?.concern2 ?? "",
  ];

  return (
    <>
      <hr />
      <Text variant="h2" id="mycaw">
        Measure Yourself Concerns and Wellbeing (MYCaW®)
      </Text>
      <Field wide>
        <p>
          MYCaW® (Measure Yourself Concerns and Wellbeing®) Meaningful Measures
          Ltd,{" "}
          <a
            className="text-steelpink"
            href="https://www.meaningfulmeasures.co.uk"
            target="_blank"
            rel="noreferrer"
          >
            www.meaningfulmeasures.co.uk
          </a>
        </p>
      </Field>
      <TextField
        label={<LocalizedMessage id="patients_events_mycaw_date" />}
        readOnly
        value={new Date(date).toLocaleDateString()}
      />
      <RadioField
        label={<LocalizedMessage id="patients_events_mycaw_respondent" />}
        value={mycaw?.respondent}
        onChange={(value) =>
          onChange({ mycaw: { ...mycaw, respondent: value } })
        }
        options={optionsFromArray([
          "Client completing this on my own",
          "Carer completing this about myself",
          "A carer supporting someone to complete this (e.g. family member)",
          "A professional supporting someone to complete this (e.g. nurse or link worker)",
        ])}
      />
      <RadioField
        label={<LocalizedMessage id="patients_events_mycaw_location" />}
        value={mycaw?.location}
        onChange={(value) => onChange({ mycaw: { ...mycaw, location: value } })}
        options={optionsFromArray([
          "During a face-to-face appointment",
          "During a phone/ video consultation",
          "At home, returned by post",
          "Via an online survey",
        ])}
      />
      <Field wide>
        <p>
          Please write down one or two concerns or problems which you would most
          like us to help you with.
        </p>
        <p>
          Please choose a number to show how severe each concern or problem is
          now.
        </p>
        <p>This should be YOUR opinion, no-one else's!</p>
      </Field>
      <TextField
        key="concern1Text"
        label={<LocalizedMessage id="patients_events_mycaw_concern1" />}
        value={showMycawFollowUp ? mycawConcerns[0] : mycaw?.concern1 ?? ""}
        onChange={(e) =>
          onChange({ mycaw: { ...mycaw, concern1: e.target.value } })
        }
        maxLength={140}
        readOnly={showMycawFollowUp}
      />
      <RadioField
        label={<LocalizedMessage id="patients_events_mycaw_concern1Rating" />}
        value={mycaw?.concern1Rating?.toString()}
        onChange={(value) =>
          onChange({ mycaw: { ...mycaw, concern1Rating: Number(value) } })
        }
        options={[
          {
            value: "0",
            label: (
              <>
                <FontAwesomeIcon icon={faFaceSmile} />0 Not bothering me at all
              </>
            ),
          },
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          {
            value: "6",
            label: (
              <>
                <FontAwesomeIcon icon={faFaceFrown} />6 Bothers me greatly
              </>
            ),
          },
        ]}
      />
      <TextField
        key="concern2Text"
        label={<LocalizedMessage id="patients_events_mycaw_concern2" />}
        value={showMycawFollowUp ? mycawConcerns[1] : mycaw?.concern2 ?? ""}
        onChange={(e) =>
          onChange({ mycaw: { ...mycaw, concern2: e.target.value } })
        }
        maxLength={140}
        readOnly={showMycawFollowUp}
      />
      <RadioField
        label={<LocalizedMessage id="patients_events_mycaw_concern2Rating" />}
        value={mycaw?.concern2Rating?.toString()}
        onChange={(value) =>
          onChange({ mycaw: { ...mycaw, concern2Rating: Number(value) } })
        }
        options={[
          {
            value: "0",
            label: (
              <>
                <FontAwesomeIcon icon={faFaceSmile} />0 Not bothering me at all
              </>
            ),
          },
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          {
            value: "6",
            label: (
              <>
                <FontAwesomeIcon icon={faFaceFrown} />6 Bothers me greatly
              </>
            ),
          },
        ]}
      />
      <RadioField
        label={
          <div className="flex flex-col">
            <LocalizedMessage id="patients_events_mycaw_wellbeing" />
            <Text variant="body">
              How would you rate your general feeling of wellbeing now? (How do
              you feel in yourself?)
            </Text>
          </div>
        }
        value={mycaw?.wellbeingRating?.toString()}
        onChange={(value) =>
          onChange({ mycaw: { ...mycaw, wellbeingRating: Number(value) } })
        }
        options={[
          {
            value: "0",
            label: (
              <>
                <FontAwesomeIcon icon={faFaceSmile} />0 As good as it could be
              </>
            ),
          },
          { value: "1", label: "1" },
          { value: "2", label: "2" },
          { value: "3", label: "3" },
          { value: "4", label: "4" },
          { value: "5", label: "5" },
          {
            value: "6",
            label: (
              <>
                <FontAwesomeIcon icon={faFaceFrown} />6 As bad as it could be
              </>
            ),
          },
        ]}
      />
      {showMycawFollowUp ? (
        <>
          <TextareaField
            key="otherThingsText"
            label={
              <div className="flex flex-col">
                <LocalizedMessage id="patients_events_mycaw_otherThings" />
                <Text variant="body">
                  The support that you have received here may not be the only
                  thing affecting your concern or problem. If there is anything
                  else which you think is important, such as changes which you
                  have made yourself, or other things happening in your life,
                  please write it here.
                </Text>
              </div>
            }
            value={mycaw?.otherThings ?? ""}
            onChange={(e) =>
              onChange({ mycaw: { ...mycaw, otherThings: e.target.value } })
            }
          />
          <TextareaField
            key="mostImportantText"
            label={
              <div className="flex flex-col">
                <LocalizedMessage id="patients_events_mycaw_mostImportant" />
                <Text variant="body">
                  Reflecting on your time with us what were the most important
                  aspects for you?
                </Text>
              </div>
            }
            value={mycaw?.mostImportant ?? ""}
            onChange={(e) =>
              onChange({ mycaw: { ...mycaw, mostImportant: e.target.value } })
            }
          />
        </>
      ) : null}
      <Field wide>
        <p>
          <strong>Thank you for completing this form</strong>
        </p>
      </Field>
    </>
  );
};
