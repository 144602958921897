import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  RowData,
  TableOptions,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useRef } from "react";
import { Pagination } from "./Pagination";
import Text from "./Text";

export function Table<D extends RowData>({
  columns,
  data,
}: Pick<TableOptions<D>, "columns" | "data">) {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const table = useReactTable({
    initialState: {
      pagination: {
        pageSize: 100,
      },
    },
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  const { pagination } = table.getState();
  const { rows } = table.getRowModel();
  const visibleColumns = table.getVisibleFlatColumns();
  const { nextPage, previousPage } = table;
  const next = useCallback(() => {
    scrollRef.current?.scrollIntoView();
    nextPage();
  }, [nextPage]);
  const previous = useCallback(() => {
    scrollRef.current?.scrollIntoView();
    previousPage();
  }, [previousPage]);

  return (
    <div className="flex flex-1 flex-col">
      <div ref={scrollRef} />
      <div className="flex-1">
        <table
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${visibleColumns.length}, minmax(0, 1fr))`,
          }}
        >
          <thead className="contents">
            <tr className="contents">
              {table.getLeafHeaders().map((header) => {
                return (
                  <th
                    key={header.id}
                    className="sticky top-0 border-b border-pink bg-white py-2 text-left"
                  >
                    <Text variant="smallLight">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </Text>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="contents">
            {rows.map((row) => {
              const cells = row.getVisibleCells();
              return (
                <tr key={row.id} className="group/row contents">
                  {cells.map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className="py-2 group-hover/row:bg-gray-96"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="sticky bottom-0 flex justify-center border-t border-pink bg-white pt-2 pb-8">
        <Pagination
          canNext={table.getCanNextPage()}
          canPrevious={table.getCanPreviousPage()}
          onNext={next}
          onPrevious={previous}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          totalItems={table.getCoreRowModel().rows.length}
        />
      </div>
    </div>
  );
}
