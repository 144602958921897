import { ComponentProps, FC } from "react";
// eslint-disable-next-line no-restricted-imports
import { IntlProvider } from "react-intl";
// eslint-disable-next-line no-restricted-imports
import { EDUCATION_STRINGS, STRINGS } from "_shared/localization/strings";
import { useFeatureFlagsOrUndefined } from "_shared/FeatureFlagsContext";

interface Props extends ComponentProps<typeof IntlProvider> {}

export const LocalizationProvider: FC<Props> = (props) => {
  const { isEducationMode } = useFeatureFlagsOrUndefined() ?? {};
  return (
    <IntlProvider
      messages={isEducationMode ? EDUCATION_STRINGS : STRINGS}
      {...props}
    />
  );
};
